import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { socket, SocketContext } from './context/socket';

import './Component/assets/css/bootstrap.min.css';
import './Component/assets/css/icons.min.css';
import './Component/assets/css/admin.css';
import './Component/assets/css/app.min.css';
import './Component/assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import './Component/assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
import './Component/assets/libs/datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css';
import './Component/assets/libs/datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css';





import { Provider } from 'react-redux';
import store from './Redux/store';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <SocketContext.Provider value={socket}>
     
        <App />
      
    </SocketContext.Provider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
