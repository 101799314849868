export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESSFULLY = 'USER_LOGIN_SUCCESSFULLY';
export const USER_LIST = 'USER_LIST';
export const PROJECT_LIST = 'PROJECT_LIST';
export const PROJECT_DETAIL = 'PROJECT_DETAIL';
export const APP_VERSION_LIST = 'APP_VERSION_LIST';
export const DASHBOARD_DATA = 'DASHBOARD_DATA';
export const DEALER_LIST = 'DEALER_LIST';
export const DEALER_DETAILS = '  DEALER_DETAILS';
export const DEALER_STOCKE_AGE = '  DEALER_STOCKE_AGE';
export const DMS_PROVIDER = '  DMS_PROVIDER';
export const VEHICLES_LIST = '  VEHICLES_LIST';
export const DEALER_VEHICLES = '  DEALER_VEHICLES';
export const DEALER_VEHICLES_DATA = '  DEALER_VEHICLES_DATA';
export const TRANPORT_VEHICLES_LIST = '  TRANPORT_VEHICLES_LIST';
export const UPDATE_COLUMN_VALUE = '  UPDATE_COLUMN_VALUE';
export const UPDATE_PRICE_VALUE = 'UPDATE_PRICE_VALUE';
export const ACCOUNT_LIST_DATA = 'ACCOUNT_LIST_DATA';
export const COMPANY_LIST = 'COMPANY_LIST';
export const TRANSPORT_LIST = 'TRANSPORT_LIST';
export const UPDATE_COLUMN_BID_VALUE = 'UPDATE_COLUMN_BID_VALUE';
export const BUYING_DROP_LIST = 'BUYING_DROP_LIST';
export const BUYING_PAGINATION = 'BUYING_PAGINATION';
export const ARCHIVE_LIST='ARCHIVE_LIST';
export const ACCOUNT_MANAGER_LIST='ACCOUNT_MANAGER_LIST'

export const DEALER_LIST_EXPORT='DEALER_LIST_EXPORT'
export const DEALER_VEHICLES_LIST='DEALER_VEHICLES_LIST'
export const DEALER_LIVE_BIDS='DEALER_LIVE_BIDS'
export const LIVE_BIDS='LIVE_BIDS'

export const EXPIRE_BIDS='EXPIRE_BIDS'

export const ACCPTED_OFFER='ACCPTED_OFFER'

export const SELLER_ENDED_AUCTION='SELLER_ENDED_AUCTION'

export const SELLER_ACCPTED_OFFER='SELLER_ACCPTED_OFFER'
export const MANAGE_ADMIN='MANAGE_ADMIN'
export const PURCHASE_VEHICLES='PURCHASE_VEHICLES'
export const LIVE_AUCTION_DETAILS='LIVE_AUCTION_DETAILS'

export const END_AUCTION_DETAILS='END_AUCTION_DETAILS'

