import {
  ACCOUNT_MANAGER_LIST,
  DEALER_DETAILS,
  DEALER_LIST,
  DEALER_LIST_EXPORT,
  DEALER_STOCKE_AGE,
  DEALER_VEHICLES,
  DMS_PROVIDER,
} from '../Constant';

const initialValues = {
  dealer_list: [],
  dealer_details: '',
  dms_provider: [],
  dealer_stocke_age: [],
  vehicle_list: [],
  account_manager_list:[],
  dealer_list_export:[]
};
const dealer_reducer = (state = initialValues, action) => {
  switch (action.type) {
    case DEALER_LIST:
      return {
        ...state,
        dealer_list: action.payload,
      };
    case DEALER_DETAILS:
      return {
        ...state,
        dealer_details: action.payload,
      };
    case DMS_PROVIDER:
      return {
        ...state,
        dms_provider: action.payload,
      };
    case DEALER_STOCKE_AGE:
      return {
        ...state,
        dealer_stocke_age: action.payload,
      };
    case DEALER_VEHICLES:
      return {
        ...state,
        vehicle_list: action.payload,
      };
      case ACCOUNT_MANAGER_LIST:
        return({
          ...state,
          account_manager_list:action.payload
        })
        case DEALER_LIST_EXPORT:
          return({
            ...state,
            dealer_list_export:action.payload
          })
        

    default:
      return state;
  }
};

export default dealer_reducer;
