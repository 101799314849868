import React, { useEffect, useState } from 'react';
import Master from '../Include/Master';
import { useDispatch, useSelector } from 'react-redux';
import { LiveBidsAuction } from '../../Redux/Action/LiveBidsAuction';
import { useCallback } from 'react';
import moment from 'moment';
import Countdown from './Countdown';
import Pagination from '../Include/Pagination';
import '../Buying/style.css';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
const retail_list = [
  {
    id: 0,
    retail: '0-10000',
    retail_data: '£0 - £10,000',
    checked: false,
  },
  {
    id: 1,
    retail: '10000-15000',
    retail_data: '£10,000 - £15,000',
    checked: false,
  },
  {
    id: 2,
    retail: '15000-20000',
    retail_data: '£15,000 - £20,000',
    checked: false,
  },
  {
    id: 3,
    retail: '20000-25000',
    retail_data: '£20,000 - £25,000',
    checked: false,
  },
  {
    id: 4,
    retail: '25000-30000',
    retail_data: '£25,000 - £30,000',
    checked: false,
  },
  {
    id: 5,
    retail: '30000-40000',
    retail_data: '£30,000 - £40,000',
    checked: false,
  },
  {
    id: 6,
    retail: '40000-50000',
    retail_data: '£40,000 - £50,000',
    checked: false,
  },
  {
    id: 7,
    retail: '50000-5000000000',
    retail_data: 'Over £50,000',
    checked: false,
  },
];





export default function Live_Bids() {
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const [ShowModal, Setmodal]=useState("pop-modal-hide");
  const [pagno,setPagno]=useState(1)
  const [search,setSearch]=useState("")
  const [retail, setretail_data] = useState([]);
  const [retail_filter, setRetail_filter] = useState([]);
  const [name, setName] = useState('');
  useEffect(()=>{
    dispatch(LiveBidsAuction({
      pagno:pagno,
      search:name,
      retail_filter:retail_filter
    }))

  },[])

  const liveBidsreducer=useSelector((state)=>state.liveBidsreducer.livebidslist);

const pagiantiondata=(pagno)=>{
 
  setPagno(pagno)

  dispatch(LiveBidsAuction({
    pagno:pagno,
    search:name,
    retail_filter:retail_filter
  }))
 

}


const filter = (e) => {
  const keyword = e;

  setName(keyword);

  localStorage.setItem('search',keyword)

  dispatch(LiveBidsAuction({
    pagno:pagno,
    search:keyword,
    retail_filter:retail_filter
  }))
};

const keyPress=(e)=>{
if (e.key === "Enter") {
  filter(name);
}
}




const show_and_hide=()=>{
  Setmodal(ShowModal=="pop-modal-hide"?"pop-modal-show":"pop-modal-hide")
}
const hide=()=>{
  Setmodal("pop-modal-hide")
}



if (retail?.length == 0 && retail_list?.length > 0) {
  setretail_data(retail_list);
}




const handleChangefilterChecked =useCallback((value, status) => {
  setretail_data((prevRetail) =>
    prevRetail.map((data) => {
      if (data.retail === value) {

        return { ...data, checked: data.checked==true?false:true };
       
      }
      return data;
    })
  );
},[retail]);


const filter_vehicles=()=>{
var datafilter=[];

  
    retail.map((data) => {
      if (data.checked == true) {
        datafilter.push({retail:data.retail})
      }
      
    })

    
    setRetail_filter(datafilter)

  dispatch(LiveBidsAuction({
    pagno:pagno,
    search:localStorage.getItem('search'),
    retail_filter:datafilter
  }))


}


  return (
    <Master>
      {/* start page title */}
      <div className='row'    onClick={() => {  hide() }}>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-end'>
              <div>
                <span className='small_text'>YoTrader </span>
                <h4 className='mb-sm-0 font-size-28'>
                  Live Bids <span className='blue_text'>{liveBidsreducer?.totallivebids}</span>
                </h4>
              </div>           
            </div>

            <div className='page-title-right'>
              {/* App Search */}
              <div className='app-search d-none d-lg-block '>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='position-relative w-100'>
                  <input
                      className='form-control'
                      placeholder='Search'
                      type='search'
                      value={name}
                      
                     
                    

                        onChange={(e)=>{
                          setName(e.target.value)
                        }}
                      onKeyDown={keyPress}
                    />
                    <span className='bx bx-search'></span>
                  </div>                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end page title  */}

      <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <h4 className='title_text'>Live Bids</h4>
                  <span>
                    <div className="btn bg_import export-button-m">
                    <a>Export as CSV</a>
                    </div> 
                    </span>
                </div>

                <div className='table-responsive dealer-table-c table-scroll'  >
                  <table
                    id=''
                    className='table dt-responsive vehicles_table nowrap w-100'

                    
                  >
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>VRM</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Mileage</th>
                        <th>Selling Dealer</th>
                        <th>Stock Type</th>
                        <th>Auction Started</th>
                        <th>Auction Ends</th>
                     

                        <th className='sort-arrow-flex'>
                          <div className='th-label-drop'   onClick={() => {  show_and_hide() }}>Retail Price</div>{' '}
                           <div className='arrow-drop-icon'>

                           <div  className='filterdropth1 modal-filter-n1'>
                           <svg class="modal-arrow-down top1" focusable="false"  onClick={()=>{
                                show_and_hide()}}  width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.7125 0.2925L5.5925 4.1725L9.4725 0.2925C9.8625 -0.0975 10.4925 -0.0975 10.8825 0.2925C11.2725 0.6825 11.2725 1.3125 10.8825 1.7025L6.2925 6.2925C5.9025 6.6825 5.2725 6.6825 4.8825 6.2925L0.2925 1.7025C-0.0975 1.3125 -0.0975 0.6825 0.2925 0.2925C0.6825 -0.0875 1.3225 -0.0975 1.7125 0.2925V0.2925Z" fill="#A3AED0"/>
                              </svg>
                           </div>

                           <div className={`${ShowModal} left80`}>
                             <div >

                               <div className='checkbox-list-part' >
                             
                              {retail?.map((item, i) => {
                                return (
                                  <div class="form-check align-check-part"   onClick={() =>
                                       handleChangefilterChecked(item?.retail, item?.checked ? true : false)
                                       }>

                                    <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                                    <label class="form-check-label" for="flexCheckDefault">
                                      {item?.retail_data}
                                    </label>
                                  </div>
                                )
                              })}
                      
                               </div>
                               <div className='filter-appply-btn-part'>
                                 <button className='btn reset-apply-btn' >Reset</button>
                                 <button className='btn reset-apply-btn'  onClick={()=>{
                                  filter_vehicles()
                                 }}>Apply</button>
                               </div>
                             </div>

                           </div>
                           </div>
                         </th>
                        <th>Buy it Now</th>
                        <th>Reserve Price</th>
                        <th>No. Bids</th>
                        <th>TCBG Bid</th>
                        <th>G3 Bid</th>
                        <th>Highest Bid</th>
                        <th>Highest Bidder</th>
                        <th>Time Remaining</th>
                        <th>Reserve Met</th>
                      </tr>
                    </thead>

                    <tbody className='td_color sticky-header-c tbody-height-fix table-scroll scroll-bottom-none'  onClick={() => {  hide() }}>

                    {

                      liveBidsreducer?.Livebidslist?.length==0?<tr className='placholder-text-height'>
                      <td>
                      <p className='placeholdet-text-table'>No data found</p>
                      </td>
                     </tr>:
                      liveBidsreducer?.Livebidslist?.map(function(live_object,i){
                        return( <tr className='cursor-pointer' key={i}  onClick={()=>{
                         

                         const encryptedData = CryptoJS.AES.encrypt(live_object._id, 'your-secret-key').toString();



                          navigate(`/admin/live-bid-details/${encodeURIComponent(encryptedData)}`)
                        }}>
                          <td>
                          <img
                            src={live_object?.newvaluationdetails?.images[2]}
                            height='40'
                            alt=''
                          />
                        </td>
                        <td>{live_object.newvaluationdetails.vrm}</td>
                        <td>{live_object.newvaluationdetails.make}</td>
                        <td>{live_object.newvaluationdetails.model}</td>
                        <td>{live_object.newvaluationdetails.mileage}</td>
                      
                        <td>{live_object.user_detail.company_name}<img src={require('../assets/images/info.svg').default} className='table-img-info' alt=''/></td>
                        <td>0</td>
                        <td>
                        <div className="flex-div">
                          <span> { moment(live_object.createdAt).format('DD-MM-YYYY H:mm')}</span>
                        </div>
                       </td>


                        <td>{ moment(live_object.expire_off).format('DD-MM-YYYY H:mm')}</td>
                        <td>{   new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format(live_object.vehicle_id.price).replace(/\.00$/, '') }</td>
                        <td>{   new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format(live_object.newvaluationdetails.price_now).replace(/\.00$/, '') }</td>
                   


                        <td>  {  live_object.newvaluationdetails.price_reserve==undefined?"---": new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format(live_object.newvaluationdetails?.price_reserve).replace(/\.00$/, '') }</td>
                     
                        <td>{live_object.yotraderbids.length}</td>
                        <td>{
                          (() => {
                                const filteredDataTcgb = (live_object?.yotraderbids || []).filter(function(object) {
                                  return object.bidsfrom === "G3"?live_object.yotrader_value:"---"
                                });
                      
                          return filteredDataTcgb.length==0?"---":  new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format(filteredDataTcgb.length>0?   filteredDataTcgb[0].yotrader_value:0).replace(/\.00$/, '') 
                        
                        
                          })()
                                                  
                        }</td>
                        <td>{
                          (() => {
                                const filteredData = (live_object?.yotraderbids || []).filter(function(object) {
                                  return object.bidsfrom === "TCGB"?live_object.yotrader_value:"---"
                                });
                        
                               return  filteredData.length==0?"---":  new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format(   filteredData.length>0? filteredData[0].yotrader_value:0).replace(/\.00$/, '') 
                        
                        
                        
                          })()
                                                  
                        }<img src={require('../assets/images/info.svg').default} className='table-img-info' alt=''/></td>
                       
                    
                        <td><div className="green-text-td">{live_object?.yotraderbids.length==0?"---": new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format((live_object?.yotraderbids.length==0)?0:Math.max(...live_object?.yotraderbids.map(e => e.yotrader_value))).replace(/\.00$/, '')}</div></td> 
                        <td>{

                          live_object?.yotraderbids.length==0?"---":


                              (() => {
                                const data = live_object?.yotraderbids?.reduce((p, c) => p?.yotrader_value > c?.yotrader_value ? p : c);
                                if (data) {
                                  if (data.bidsfrom === "TCGB") {
                                    return 'TCGB';
                                  } else if (data.bidsfrom === "G3") {
                                    return 'G3';
                                  } else if (data.dealersbids && data.dealersbids.company_name) {
                                    return data.dealersbids.company_name;
                                  }
                                }
                                return 'Default Value'; // Provide a default value or handle other cases
                              })()
                            }</td> 
                      
                       <td>{<Countdown endDate={live_object?.newvaluationdetails?.offer_expire}  />}</td>
                       <td>{((Math.max(parseInt( live_object?.yotraderbids.map(o => o.yotrader_value))) ==0)?0 : Math.max(parseInt( live_object?.yotraderbids.map(o => o.yotrader_value))))>=live_object?.newvaluationdetails?.price_reserve?<span className='biding-end-text-green'>Yes </span>:(live_object?.newvaluationdetails?.price_reserve !=undefined && <span className='listing-red-sm-text'>No</span>)}</td>

                              
                      </tr>)
                      })
                    
                
               }
                    
                    </tbody>
                  </table>
                </div>


                <div className='pagination-wrapper custom-pagination-b'>
                    <Pagination  totalpage={liveBidsreducer?.total_page}  totallivebids={liveBidsreducer?.totallivebids}  pagiantiondata={pagiantiondata} />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}
