import { Action } from 'history';
import {  ACCPTED_OFFER, PURCHASE_VEHICLES, SELLER_ACCPTED_OFFER } from '../Constant';

const initialValues = {
    acceptedoffer: [],
    seller_acceptedoffer: [],
    purchasevehicles:[]
};

const Acceptedofferreducer = (state = initialValues, action) => {
  switch (action.type) {
    case ACCPTED_OFFER:
      return {
        ...state,
        acceptedoffer: action.payload,
      };
      case SELLER_ACCPTED_OFFER:
        return {
          ...state,
          seller_acceptedoffer: action.payload,
        };

        case PURCHASE_VEHICLES:
            return {
              ...state,
              purchasevehicles: action.payload,
            };
        
      
    default:
      return state;
  }
};
export default Acceptedofferreducer;
