import React, { useContext, useEffect, useState, useRef } from 'react';
import Master from '../Include/Master';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {

  GetBuyingDropdownData,
  Get_Buying_Pagination,
  Get_Buying_Pagination4,
  hpipdfbuyingsection,
  Sendbulkmail,
  SendPurchesMail,
  updatebidvalue_data,
  updatevehicleinfo,
  upfatecsvfile,
  vehicles_list,
  YotraderBidvalue_data,
} from '../../Redux/Action/VehilceAction';

import Form from 'react-bootstrap/Form';

/* multiple select dropdown*/

import { makeStyles } from '@mui/styles';


import Button from '@mui/material/Button';
import {
  ConditionReport_data_admin,
  getConditionReport,
 
} from '../../Redux/Action/DealerAction';
import Modal from 'react-bootstrap/Modal';
import { Button as SimpleBtn } from 'react-bootstrap';
/* material modal*/
import InputGroup from 'react-bootstrap/InputGroup';
import { toast } from 'react-toastify';
import API from '../../Service/Api';
import axios from 'axios';
import MoonLoader from 'react-spinners/MoonLoader';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import './style.css';
import { SocketContext } from '../../context/socket';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { ContrastOutlined } from '@mui/icons-material';



const Stocking = [
  { checked: true },
  { stocking: '0', checked: true },
  { stocking: '1', checked: true },
  { stocking: '2', checked: true },
  { stocking: '3', checked: true },
  { stocking: '4', checked: true },
  { stocking: '5', checked: true },
  { stocking: '6', checked: true },
  { stocking: '7', checked: true },
  { stocking: '8', checked: true },
  { stocking: '9', checked: true },
];

const stocking_list = [
  { checked: true },
  { id: 0, stocking: '0-10', stocking_data: '0-10', checked: true },
  { id: 0, stocking: '10-20', stocking_data: '10-20', checked: true },
  { id: 0, stocking: '20-30', stocking_data: '20-30', checked: true },
  { id: 0, stocking: '30-40', stocking_data: '30-40', checked: true },
  { id: 0, stocking: '40-60', stocking_data: '40-60', checked: true },
  { id: 0, stocking: '60-90', stocking_data: '60-90', checked: true },
  { id: 0, stocking: '90-1000000', stocking_data: '90+', checked: true },
];

const mileage_list = [
  { id: 0, milage: '0-9999', milage_data: '0-9,999', checked: true },
  {
    id: 1,
    milage: '10000-19999',
    milage_data: '10,000-19,999',
    checked: true,
  },
  {
    id: 2,
    milage: '20000-39999',
    milage_data: '20,000-39,999',
    checked: true,
  },
  {
    id: 3,
    milage: '40000-59999',
    milage_data: '40,000-59,999',
    checked: true,
  },
  {
    id: 4,
    milage: '60000-79999',
    milage_data: '60,000-79,999',
    checked: true,
  },
  { id: 5, milage: '80000-800000000', milage_data: '80,000+', checked: true },
];

const year_list = [
  { id: 0, checked: true, year_select: 'Deselect', year: 'Select All' },
  { id: 1, checked: true, year_select: '2022', year: '2022' },
  { id: 2, checked: true, year_select: '2021', year: '2021' },
  { id: 3, checked: true, year_select: '2020', year: '2020' },
  { id: 4, checked: true, year_select: '2019', year: '2019' },
  { id: 5, checked: true, year_select: '2018', year: '2018' },
  { id: 6, checked: true, year_select: '2017', year: '2017' },
  { id: 7, checked: true, year_select: '2016', year: '2016' },
  { id: 8, checked: true, year_select: '2015', year: '2015' },
  { id: 9, checked: true, year_select: '2014', year: '2014 and older' }
];

const body_style = [
  { id: 0, checked: true, body_style_obj: 'Blank', body_style: '' },
  { id: 1, checked: true, body_style_obj: 'City-Car', body_style: 'City-Car' },
  { id: 2, checked: true, body_style_obj: 'Convertible', body_style: 'Convertible' },
  { id: 3, checked: true, body_style_obj: 'Coupe', body_style: 'Coupe' },
  { id: 4, checked: true, body_style_obj: 'Cross Over', body_style: 'Cross Over' },
  { id: 4, checked: true, body_style_obj: 'Estate', body_style: 'Estate' },
  { id: 4, checked: true, body_style_obj: 'Hatchback', body_style: 'Hatchback' },
  { id: 4, checked: true, body_style_obj: 'Minibus', body_style: 'Minibus' },
  { id: 4, checked: true, body_style_obj: 'MPV', body_style: 'MPV' },
  { id: 4, checked: true, body_style_obj: 'Pickup', body_style: 'Pickup' },
  { id: 4, checked: true, body_style_obj: 'Saloon', body_style: 'Saloon' },
  { id: 4, checked: true, body_style_obj: 'Sports', body_style: 'Sports' },
  { id: 4, checked: true, body_style_obj: 'SUV', body_style: 'SUV' },
];
const county_list = [
  { id: 0, checked: true },
  { id: 1, checked: true, county_select: 'AL St. Albans, Hertfordshire', county: 'AL' },
  { id: 2, checked: true, county_select: 'CM Chelmsford, Essex', county: 'CM' },
  { id: 3, checked: true, county_select: 'CO Colchester, Essex', county: 'CO' },
  { id: 4, checked: true, county_select: 'EN Enfield, Middlesex', county: 'EN' },
  { id: 5, checked: true, county_select: 'IG Ilford and Barking, Essex', county: 'IG' },
  { id: 6, checked: true, county_select: 'IP Ipswich, Suffolk', county: 'IP' },
  { id: 7, checked: true, county_select: 'MK Milton Keynes, Buckinghamshire', county: 'MK' },
  { id: 8, checked: true, county_select: 'NR Norwich, Norfolk', county: 'NR' },
  { id: 9, checked: true, county_select: 'PE Peterborough, Cambridgeshire', county: 'PE' },
  { id: 10, checked: true, county_select: 'RM Romford, Essex', county: 'RM' },
  { id: 11, checked: true, county_select: 'SG Stevenage, Hertfordshire', county: 'SG' },
  { id: 12, checked: true, county_select: 'S Southend-on-Sea, Essex', county: 'S' },
  { id: 120, checked: true, county_select: 'SS Southend-on-Sea, Essex', county: 'SS' },
  { id: 13, checked: true, county_select: 'WD Watford, Hertfordshire', county: 'WD' },
  { id: 14, checked: true, county_select: 'CB Cambridge, Cambridgeshire', county: 'CB' },
  { id: 15, checked: true, county_select: 'LU Luton, Bedfordshire', county: 'LU' },
  { id: 16, checked: true, county_select: 'B Birmingham, West Midlands', county: 'B' },
  { id: 17, checked: true, county_select: 'CV Coventry, West Midlands', county: 'CV' },
  { id: 18, checked: true, county_select: 'DE Derby, Derbyshire', county: 'DE' },
  { id: 19, checked: true, county_select: 'DY Dudley, West Midlands', county: 'DY' },
  { id: 20, checked: true, county_select: 'LE Leicester, Leicestershire', county: 'LE' },
  { id: 21, checked: true, county_select: 'NG Nottingham, Nottinghamshire', county: 'NG' },
  { id: 22, checked: true, county_select: 'NN Northampton, Northamptonshire', county: 'NN' },
  { id: 23, checked: true, county_select: 'ST Stoke-on-Trent, Staffordshire', county: 'ST' },
  { id: 24, checked: true, county_select: 'WS Walsall, West Midlands', county: 'WS' },
  { id: 25, checked: true, county_select: 'WV Wolverhampton, West Midlands', county: 'WV' },
  { id: 26, checked: true, county_select: 'BD Bradford, West Yorkshire', county: 'BD' },
  { id: 27, checked: true, county_select: 'DH Durham, County Durham', county: 'DH' },
  { id: 28, checked: true, county_select: 'DL Darlington, North Yorkshire', county: 'DL' },
  { id: 29, checked: true, county_select: 'DN Doncaster, South Yorkshire', county: 'DN' },
  { id: 30, checked: true, county_select: 'HD Huddersfield, West Yorkshire', county: 'HD' },
  { id: 31, checked: true, county_select: 'HG Harrogate, North Yorkshire', county: 'HG' },
  { id: 32, checked: true, county_select: 'HU Hull, North Humberside', county: 'HU' },
  { id: 33, checked: true, county_select: 'HX Halifax, West Yorkshire', county: 'HX' },
  { id: 34, checked: true, county_select: 'LN Lincoln, Lincolnshire', county: 'LN' },
  { id: 35, checked: true, county_select: 'LS Leeds, West Yorkshire', county: 'LS' },
  { id: 36, checked: true, county_select: 'NE Newcastle, Tyne and Wear', county: 'NE' },
  { id: 37, checked: true, county_select: 'S Sheffield, South Yorkshire', county: 'S' },
  { id: 38, checked: true, county_select: 'SR Sunderland, Tyne and Wear', county: 'SR' },
  { id: 39, checked: true, county_select: 'TS Teesside, Cleveland', county: 'TS' },
  { id: 40, checked: true, county_select: 'WF Wakefield, West Yorkshire', county: 'WF' },
  { id: 41, checked: true, county_select: 'YO York, North Yorkshire', county: 'YO' },
  { id: 42, checked: true, county_select: 'BB Blackburn, Lancashire', county: 'BB' },
  { id: 43, checked: true, county_select: 'BL Blackpool, Lancashire', county: 'BL' },
  { id: 44, checked: true, county_select: 'CA Carlisle, Cumbria', county: 'CA' },
  { id: 45, checked: true, county_select: 'CW Crewe, Cheshire', county: 'CW' },
  { id: 46, checked: true, county_select: 'FY Blackpool, Lancashire', county: 'FY' },
  { id: 47, checked: true, county_select: 'L Liverpool, Merseyside', county: 'L' },
  { id: 48, checked: true, county_select: 'LA Lancaster, Lancashire', county: 'LA' },
  { id: 49, checked: true, county_select: 'M Manchester, Lancashire', county: 'M' },
  { id: 50, checked: true, county_select: 'OL Oldham, Lancashire', county: 'OL' },
  { id: 51, checked: true, county_select: 'PR Preston, Lancashire', county: 'PR' },
  { id: 52, checked: true, county_select: 'SK Stockport, Cheshire', county: 'SK' },
  { id: 53, checked: true, county_select: 'SY Shrewsbury, Shropshire', county: 'SY' },
  { id: 54, checked: true, county_select: 'TF Telford, Shropshire', county: 'TF' },
  { id: 55, checked: true, county_select: 'WA Warrington, Cheshire', county: 'WA' },
  { id: 56, checked: true, county_select: 'WN Wigan, Lancashire', county: 'WN' },
  { id: 57, checked: true, county_select: 'CH Chester, Cheshire', county: 'CH' },
  { id: 58, checked: true, county_select: 'E East London', county: 'E' },
  { id: 59, checked: true, county_select: 'EC City of London', county: 'EC' },
  { id: 60, checked: true, county_select: 'N North London', county: 'N' },
  { id: 61, checked: true, county_select: 'NW North West London"', county: 'NW' },
  { id: 62, checked: true, county_select: 'SE South East London', county: 'SE' },
  { id: 63, checked: true, county_select: 'SW South West London', county: 'SW' },
  { id: 64, checked: true, county_select: 'W West End, London', county: 'W' },
  { id: 65, checked: true, county_select: 'WC West Central London', county: 'WC' },
  { id: 66, checked: true, county_select: 'GU Guildford, Surrey', county: 'GU' },
  { id: 67, checked: true, county_select: 'HA Harrow, Middlesex', county: 'HA' },
  { id: 68, checked: true, county_select: 'HP Hemel Hempstead, Hertfordshire', county: 'HP' },
  { id: 69, checked: true, county_select: 'OX Oxford, Oxfordshire', county: 'OX' },
  { id: 70, checked: true, county_select: 'PO Portsmouth, Hampshire', county: 'PO' },
  { id: 71, checked: true, county_select: 'RG Reading, Berkshire', county: 'RG' },
  { id: 72, checked: true, county_select: 'SL Slough, Buckinghamshire', county: 'SL' },
  { id: 73, checked: true, county_select: 'SN Swindon, Wiltshire', county: 'SN' },
  { id: 74, checked: true, county_select: 'SO Southampton, Hampshire', county: 'SO' },
  { id: 75, checked: true, county_select: 'SP Salisbury, Wiltshire', county: 'SP' },
  { id: 76, checked: true, county_select: 'UB Southall and Uxbridge, Middlesex', county: 'UB' },
  { id: 77, checked: true, county_select: 'BN Brighton, East Sussex', county: 'BN' },
  { id: 78, checked: true, county_select: 'BR Bromley, Kent', county: 'BR' },
  { id: 79, checked: true, county_select: 'CR Croydon, Surrey', county: 'CR' },
  { id: 80, checked: true, county_select: 'CT Canterbury, Kent', county: 'CT' },
  { id: 81, checked: true, county_select: 'DA Dartford, Kent', county: 'DA' },
  { id: 82, checked: true, county_select: 'KT Kingston-upon-Thames, Surrey', county: 'KT' },
  { id: 83, checked: true, county_select: 'ME Medway, Kent', county: 'ME' },
  { id: 84, checked: true, county_select: 'RH Redhill, Surrey', county: 'RH' },
  { id: 85, checked: true, county_select: 'SM Sutton, Surrey', county: 'SM' },
  { id: 86, checked: true, county_select: 'TN Tonbridge, Kent', county: 'TN' },
  { id: 87, checked: true, county_select: 'TW Twickenham, Middlesex', county: 'TW' },
  { id: 88, checked: true, county_select: 'BA Bath, Avon', county: 'BA' },
  { id: 89, checked: true, county_select: 'BS Bristol, Avon', county: 'BS' },
  { id: 90, checked: true, county_select: 'GL Gloucester, Gloucestershire', county: 'GL' },
  { id: 91, checked: true, county_select: 'HR Hereford, Herefordshire', county: 'HR' },
  { id: 92, checked: true, county_select: 'WR Worcester, Worcestershire', county: 'WR' },
  { id: 93, checked: true, county_select: 'TR Truro, Cornwall', county: 'TR' },
  { id: 94, checked: true, county_select: 'TQ Torquay, Devon', county: 'TQ' },
  { id: 95, checked: true, county_select: 'TD Galashiels, Selkirkshire', county: 'TD' },
  { id: 96, checked: true, county_select: 'TA Taunton, Somerset', county: 'TA' },
  { id: 97, checked: true, county_select: 'SA Swansea, West Glamorgan', county: 'SA' },
  { id: 98, checked: true, county_select: 'PL Plymouth, Devon', county: 'PL' },
  { id: 99, checked: true, county_select: 'PH Perth, Perthshire', county: 'PH' },
  { id: 100, checked: true, county_select: 'PA Paisley, Renfrewshire', county: 'PA' },
  { id: 101, checked: true, county_select: 'NP Newport, Gwent', county: 'NP' },
  { id: 102, checked: true, county_select: 'ML Motherwell, Lanarkshire', county: 'ML' },
  { id: 103, checked: true, county_select: 'LL Llandudno, Clwyd', county: 'LL' },
  { id: 104, checked: true, county_select: 'LD Llandrindod Wells', county: 'LD' },
  { id: 105, checked: true, county_select: 'KY Kirkcaldy, Fife', county: 'KY' },
  { id: 106, checked: true, county_select: 'KA Kilmarnock and Ayr, Ayrshire', county: 'KA' },
  { id: 107, checked: true, county_select: 'IV Inverness', county: 'IV' },
  { id: 108, checked: true, county_select: 'G Glasgow, Lanarkshire', county: 'G' },
  { id: 109, checked: true, county_select: 'FK Falkirk, Stirlingshire', county: 'FK' },
  { id: 110, checked: true, county_select: 'EX Exeter, Devon', county: 'EX' },
  { id: 111, checked: true, county_select: 'EH Edinburgh, Midlothian', county: 'EH' },
  { id: 112, checked: true, county_select: 'DT Dorchester, Dorset', county: 'DT' },
  { id: 113, checked: true, county_select: 'DG Dumfries and Galloway', county: 'DG' },
  { id: 114, checked: true, county_select: 'DD Dundee, Angus', county: 'DD' },
  { id: 115, checked: true, county_select: 'CF Cardiff, South Glamorgan', county: 'CF' },
  { id: 116, checked: true, county_select: 'BT Belfast', county: 'BT' },
  { id: 117, checked: true, county_select: 'BH Bournemouth, Dorset', county: 'BH' },
  { id: 118, checked: true, county_select: 'AB Aberdeen', county: 'AB' },
  { id: 119, checked: true, county_select: 'IM Isle of Man', county: 'IM' },
];


const distance_from_retail = [
  {
    id: 0,
    distance_from_retail: 0,
    distance_from_retail_data: 'Blank',
    checked: true,
  },
  {
    id: 0,
    distance_from_retail: '1-1000',
    distance_from_retail_data: '1-1,000',
    checked: true,
  },
  {
    id: 1,
    distance_from_retail: '1000-3000',
    distance_from_retail_data: '1,000-3,000',
    checked: true,
  },
  {
    id: 2,
    distance_from_retail: '3000-5000',
    distance_from_retail_data: '3,000-5,000',
    checked: true,
  },
  {
    id: 3,
    distance_from_retail: '5000-7000',
    distance_from_retail_data: '5,000-7,000',
    checked: true,
  },
  {
    id: 4,
    distance_from_retail: '7000-10000',
    distance_from_retail_data: '7,000-10,000',
    checked: true,
  },
  {
    id: 5,
    distance_from_retail: '10000-10000000000',
    distance_from_retail_data: '10,000+',
    checked: true,
  },
];

/* end material modal*/
export default function Buying() {
  var oneData;
  var MilageData;
  var retailData;
  const [imagedata, setimage] = useState('');
  const [show, setShow] = useState(false);
  const [checked_data, setCheckedData] = useState([]);
  const [year, setYear] = useState([]);
  const [handleDiv, sethandleDiv] = useState(false);
  const [filterIndicator, setfilterIndicator] = useState(false);
  const [firstFilterRetain, setFirstFilterReatin] = useState();
  const [wrappedRefType, setWrappedRef] = useState();
  const socketContext = useContext(SocketContext);
  const [selected, setSelected] = useState([]);
  const [dealerDrop, setDealerDrop] = useState([]);
  const [mileage, setmileage_data] = useState([]);
  const [stockingData, setstockingData] = useState([]);
  const [body_style_data, setbody_style] = useState([]);
  const [country_code, setcounty_list] = useState([]);
  const [make_dealer, setMake_Dealer] = useState([]);
  const [model_dealer, setModel_Dealer] = useState([]);
  const [loadIndicator, setLoadIndicator] = useState(false);

  const [yotraderbidtval, Settyotraderbidtval] = useState(0);
  const [retail, setretail_data] = useState([]);
  const [make, setMake] = useState([]);
  const [model, setModel] = useState([]);
  const [stockingAge, setStockingAge] = useState([]);
  const [retailprice_data, setretailprice_data] = useState([]);
  const [changeId, setChangeId] = useState('');
  const [textChange, setTextChange] = useState('');
  const [typeOf, setType] = useState('');
  const [paginationChange, setPaginationChange] = useState('');
  const [progress, setProgress] = useState(0);
  const [sort_data_value, Setsort] = useState(1);
  //  const [oneData, setOneData] = useState('');
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  const [showSimple, setShowSimple] = useState(false);
  const [Hpipdflink, setHpipdflink] = useState("");

  const handleSimpleClose = () => setShowSimple(false);

  let [color, setColor] = useState('#42a033');
  let [loading, setLoading] = useState(true);


  const tableCustomStyles = {
    cells: {
      style: {
        fontSize: '12px',
        paddingLeft: '0 8px',
        fontWeight:600,
      },
    },
  }




  const { vehicle_list_data, buying_drop, dealer_listexport, pagiantion_data } =
    useSelector((state) => ({
      dealer_listexport: state.vehiclereducer.vehicle_list_data,
      buying_drop: state.vehiclereducer.buying_drop,
      vehicle_list_data: state.vehiclereducer.buying_page.vehicle_list_data,
      pagiantion_data: state.vehiclereducer.buying_page.pagination,
    }));

  const classes = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: 600,
    },
    stocking: {
      margin: theme.spacing(1),
      width: 600,
    },
    indeterminateColor: {
      color: '#f50057',
    },
    selectAllText: {
      fontWeight: 500,
    },
    selectedAll: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  }));

  
  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    variant: 'menu',
  };

  const [yotaderbid, Setyotraderbidva] = useState('');
  useEffect(() => {
    dispatch(Get_Buying_Pagination(1, [], searchInput, sort_data_value));
    dispatch(YotraderBidvalue_data()).then(function (data) {
      Setyotraderbidva(data?.yotrader_value);
    });
    setPaginationChange(true);
  }, []);

  useEffect(() => {
    // dispatch(Get_dealer_list_pagination());
  }, []);

  const dealer_list_data = useSelector(
    (state) => state.dealerreducer.dealer_list?.dealer_data
  );
  console.log(dealer_list_data, 'dealer_list_data');

  useEffect(() => {
    dispatch(YotraderBidvalue_data()).then(function (data) {
      formik.setFieldValue('bid_value', data?.yotrader_value, false);

     
    });
  }, []);




  //vehicle list

  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(vehicles_list());
    dispatch(GetBuyingDropdownData());
  }, []);


  useEffect(() => {
    socketContext.on('update-ajay-test-socket', (event) => {
      setProgress(event);
      console.log(event, 'testing client catch backend emit');
    });
  }, [socketContext]);

  const filter_single_vehicle_data = useSelector(
    (state) => state?.vehiclereducer?.filter_single_vehicle
  );

  //end vehicle list


  const vehicle_list_yotrader = useSelector(
    (state) => state?.vehiclereducer?.vehicle_list?.youtrader
  );


  vehicle_list_data?.forEach(function (element) {
  
    element.input_dfr = null;
  });



 
  const handleClose = () => setShow(false);


  const [vehicle, vehicleset] = useState([]);
  const [filterVehicle, setFiltervehicle] = useState();
  console.log(filterVehicle, 'oneDtaa');

  //modal yo trader
  const [show_yotrader, setShowYotrader] = useState(false);
  const [show_export, setshow_export] = useState(false);

  const handleyotraderClose_yo = () => {
    formik.setFieldValue(
      'bid_value',
      vehicle_list_yotrader?.yotrader_value,
      false
    );
    setShowYotrader(false);
  };

  const handleExportClose = () => {
    setshow_export(false);
  };

  const handleyotraderShow = () => {
    dispatch(YotraderBidvalue_data()).then(function (data) {
      formik.setFieldValue('bid_value', data?.yotrader_value, false);
    });
    setShowYotrader(true);
  };

  useEffect(() => {
  
    if (
      filter_single_vehicle_data?._id != undefined &&
      paginationChange == false
    ) {
      setfilterIndicator(true)
      console.log('pagination change');
      const updatedFilterArr = vehicle?.map((obj, i) =>
        obj._id.toString() == filter_single_vehicle_data?._id?.toString()
          ? filter_single_vehicle_data
          : obj
      );
      vehicleset(updatedFilterArr);
      setLoadIndicator(false)
    } else {
      vehicleset(vehicle_list_data);
      setLoadIndicator(false)
    }
  }, [vehicle_list_data, filter_single_vehicle_data]);

  const formik = useFormik({
    initialValues: {
      bid_value: '',
    },
    validationSchema: Yup.object({
      bid_value: Yup.string()
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(1, 'Must be exactly 1 digits')
        .max(2, 'Must be exactly 2 digits')
        .required('Bid value is requied'),
    }),
    onSubmit: (values) => {
      socketContext.emit('ajay-test-socket', values);
      setShowYotrader(false);
    },
  });
  const [arrdata, setArr] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  //end

  var arr = [];

  const searchWithFilter = (e) => {
   // setSearchInput(e);

    dispatch(Get_Buying_Pagination(1, arrdata, e, sort_data_value));
  };

  const handleChangefilterChecked = (value, type) => {
    //    setLoadIndicator(true)
    var event;
    event = value;
    console.log(event, "event0000000")
    const checkType = arrdata?.find((o) => o.type == type);

    if (checkType == undefined || (value == "selectall" || value == "deselect")) {
     
      if (value == "selectall" || value == "deselect") {
        const arr = [];
      
        switch (type) {

          case 'mileage':
            mileage?.forEach(function (data) {
              if (value == "selectall")
                data.checked = true;

              if (value == "deselect")
                data.checked = false;

              setArr([...arrdata, { type: type, data: [data.milage] }]);
            });
            break;

          case 'stocking_age_range':
            stockingAge?.forEach(function (data, i) {
              if (value == "selectall") {
                data.checked = true;
                arr.push()
              }
              if (value == "deselect") {
                data.checked = false;

                if (i >= 1)
                  arr.push(data.stocking)
              }
            });
             if (arrdata?.findIndex((item) => item?.type == "stocking_age_range") >= 0) {
            arrdata?.splice(arrdata?.findIndex((item) => item?.type == "stocking_age_range"), 1);
             }
            setArr([...arrdata, { type: type, data: arr }]);
            break;

          case 'stocking_age':
            stockingData?.forEach(function (data, i) {
              if (value == "selectall") {
                data.checked = true;
                arr.push()
              }
              if (value == "deselect") {
                data.checked = false;

                if (i >= 1)
                  arr.push(data.stocking)
              }
            });
              if (arrdata?.findIndex((item) => item?.type == "stocking_age") >= 0) {
            arrdata?.splice(arrdata?.findIndex((item) => item?.type == "stocking_age"), 1);
              }
            setArr([...arrdata, { type: type, data: arr }]);
            break;

          case 'price':
            retail?.forEach(function (data) {
              if (value == "selectall")
                data.checked = true;

              if (value == "deselect")
                data.checked = false;
            });
            break;

          case 'distance_from_retail':
            retailprice_data?.forEach(function (data) {
              if (value == "selectall")
                data.checked = true;

              if (value == "deselect")
                data.checked = false;

              setArr([...arrdata, { type: type, data: [data.distance_from_retail] }]);
            });
            break;

          case 'year':
            year?.forEach(function (data, i) {
              if (value == "selectall") {
                data.checked = true;
                arr.push()
              }
              if (value == "deselect") {
                data.checked = false;

                if (i >= 1)
                  arr.push(data.year_select)
              }
            });
            if (arrdata?.findIndex((item) => item?.type == "year") >= 0) {
              arrdata?.splice(arrdata?.findIndex((item) => item?.type == "year"), 1);
            }
            setArr([...arrdata, { type: type, data: arr }]);
            break;


          case 'body_style':
            body_style_data?.forEach(function (data) {
              if (value == "selectall")
                data.checked = true;

              if (value == "deselect")
                data.checked = false;

              setArr([...arrdata, { type: type, data: [data.body_style_obj] }]);
            });


            break;

          case 'county':
            country_code?.forEach(function (data, i) {
              if (value == "selectall") {
                data.checked = true;
                arr.push()
              }
              if (value == "deselect") {
                data.checked = false;

                if (i >= 1)
                  arr.push(data.county)
              }
            });
            if (arrdata?.findIndex((item) => item?.type == "county") >= 0) {
              arrdata?.splice(arrdata?.findIndex((item) => item?.type == "county"), 1);
            }
            setArr([...arrdata, { type: type, data: arr }]);
            break;

          case 'make':
            make_dealer?.forEach(function (data, i) {
              if (value == "selectall") {
                data.checked = true;
                arr.push()
              }
              if (value == "deselect") {
                data.checked = false;

                if (i >= 1)
                  arr.push(data.make)
              }
            });
            if (arrdata?.findIndex((item) => item?.type == "make") >= 0) {
              arrdata?.splice(arrdata?.findIndex((item) => item?.type == "make"), 1);
            }

            setArr([...arrdata, { type: type, data: arr }]);
            break;

          case 'model':
            model_dealer?.forEach(function (data, i) {
              if (value == "selectall") {
                data.checked = true;
                arr.push()
              }
              if (value == "deselect") {
                data.checked = false;

                if (i >= 1)
                  arr.push(data.model)
              }
            });
            if (arrdata?.findIndex((item) => item?.type == "model") >= 0) {
              arrdata?.splice(arrdata?.findIndex((item) => item?.type == "model"), 1);
            }
            setArr([...arrdata, { type: type, data: arr }]);
            break;


          default:
        }
      } else {

        setArr([...arrdata, { type: type, data: [event] }]);
      }

    } else if (checkType?.type != undefined) {
      console.log("omg--2")
      arrdata?.forEach(function (data) {
        if (data?.type == type) {
          if (!data?.data?.includes(event)) {
            data?.data?.push(event);
          } else {
            data?.data?.splice(data?.data?.indexOf(event), 1);
          }
        }
      });
    }


    if (!['selectall', 'deselect'].includes(value)) {
      switch (type) {
        case 'mileage':
          mileage?.forEach(function (data) {
            if (event == data?.milage)
              data.checked = data?.checked == true ? false : true;
          });
          setmileage_data([]);
          setmileage_data(mileage);
          break;

        case 'stocking_age_range':
          stockingAge?.forEach(function (data) {
            if (event == data?.stocking)
              data.checked = data?.checked == true ? false : true;
          });
          setStockingAge([]);
          setStockingAge(stockingAge);
          break;
        case 'stocking_age':
          stockingData?.forEach(function (data) {
            if (event == data?.stocking)
              data.checked = data?.checked == true ? false : true;
          });
          setstockingData([]);
          setstockingData(stockingData);
          break;

        case 'price':
          retail?.forEach(function (data) {
            if (event == data?.retail)
              data.checked = data?.checked == true ? false : true;
          });
          setretail_data([]);
          setretail_data(retail);
          break;

        case 'distance_from_retail':
          retailprice_data?.forEach(function (data) {
            if (event == data?.distance_from_retail)
              data.checked = data?.checked == true ? false : true;
          });
          setretailprice_data([]);
          setretailprice_data(retailprice_data);
          break;

        case 'year':
          year?.forEach(function (data) {
            console.log(event, data?.year_select, 'yearse');
            if (event == data?.year_select)
              data.checked = data?.checked == true ? false : true;
          });
          setYear([]);
          setYear(year);
          break;


        case 'body_style':
          body_style_data?.forEach(function (data) {
            console.log(event, data?.body_style, 'yearse');
            if (event == data?.body_style)
              data.checked = data?.checked == true ? false : true;
          });
          setbody_style([]);
          setbody_style(body_style_data);
          break;

        case 'county':
          country_code?.forEach(function (data) {
            console.log(event, data?.county, 'yearse');
            if (event == data?.county)
              data.checked = data?.checked == true ? false : true;
          });
          setcounty_list([]);
          setcounty_list(country_code);
          break;

        case 'make':
          const modelArr = [];
          make_dealer?.forEach(function (data) {
            console.log(event, data?.make, data.checked, 'yearse0009');

            if (event == data?.make) {
              let dummy = data.checked == true ? false : modelArr?.push(data?.allModel)
              data.checked = data?.checked == true ? false : true;
            }
            if (data.checked == true && event != data?.make) {
              if (data?.allModel != undefined)
                modelArr?.push(data?.allModel)
            }
          });
          let result = modelArr.reduce((r, e) => (r.push(...e), r), [])
          console.log(result, modelArr, "model_dealer-")
          setModel_Dealer([]);
          setModel_Dealer(result);
          setMake_Dealer([]);
          setMake_Dealer(make_dealer);
          break;

        case 'model':
          model_dealer?.forEach(function (data) {
            console.log(event, data?.make, 'yearse');
            if (event == data?.model)
              data.checked = data?.checked == true ? false : true;
          });
          setModel_Dealer([]);
          setModel_Dealer(model_dealer);
          break;




        default:
      }
    }
  };

  console.log(arrdata, '[...arrdata,event]...');
  console.log(make_dealer, '00000000');


  var dealer_data_list = [];
  for (let i = 0; i < dealer_list_data?.length; i++) {
    if (selected?.length == 0 || selected?.length < dealer_list_data?.length) {
      dealer_data_list?.push({
        _id: dealer_list_data[i]?._id,
        company_name: dealer_list_data[i]?.company_name,
        checked: true,
      });
    }
  }


  console.log(
    buying_drop?.buyingDealer,
    dealerDrop,
    'buying_drop?.buyingDealer'
  );
  useEffect(() => {
    // setDealerDrop(buying_drop?.buyingDealer);
  }, [buying_drop]);

  //end dealername filter
  const handleyotraderClose = () => {
    //setShowYotrader(false);
    formik.setFieldValue('bid_value', '', false);
  };
  //Make filter start
  const uniqueMakeDataArr = [],
    uniqueModelDataArr = [];
  const uniqueMakeData = [
    ...new Set(vehicle_list_data?.map((item) => item.make)),
  ];
  const uniqueModelData = [
    ...new Set(vehicle_list_data?.map((item) => item.model)),
  ];

  for (let i = 0; i < uniqueMakeData?.length; i++) {
    uniqueMakeDataArr?.push({
      make: uniqueMakeData[i],
      checked: true,
    });
  }
  for (let i = 0; i < uniqueModelData?.length; i++) {
    uniqueModelDataArr?.push({
      model: uniqueModelData[i],
      checked: true,
    });
  }

  if (make?.length == 0 && uniqueMakeDataArr?.length > 0) {
    setMake(uniqueMakeDataArr);
  }
  if (model?.length == 0 && uniqueModelDataArr?.length > 0) {
    setModel(uniqueModelDataArr);
  }

  console.log(uniqueModelData, vehicle_list_data, 'vehicle_list_data5555');

  //end

  console.log(dealer_list_data, 'dealer_data_list222');
  //mileage filter
  var cheked_array_value = [];
  var cheked_retail_array_value = [];

  //end paginaiton

  if (mileage?.length == 0 && mileage_list?.length > 0) {
    setmileage_data(mileage_list);
  }

  if (stockingData?.length == 0 && stocking_list?.length > 0) {
    setstockingData(stocking_list);
  }

  if (stockingAge?.length == 0 && Stocking?.length > 0) {
    setStockingAge(Stocking);
  }

  if (retailprice_data?.length == 0 && distance_from_retail?.length > 0) {
    setretailprice_data(distance_from_retail);
  }
  //console.log(year, year_list, 'yyyyyyyyyyyyyyyyyyyyyy');
  if (year?.length == 0 && year_list?.length > 0) {
    console.log(year, 'yyyyyyyyyyyyyyyyyyyyyy');
    setYear(year_list);
  }

  if (body_style_data?.length == 0 && body_style?.length > 0) {
    // console.log(year, 'yyyyyyyyyyyyyyyyyyyyyy');
    setbody_style(body_style);
  }

  if (country_code?.length == 0 && buying_drop?.buyingCounty?.length > 0) {
    if (!buying_drop?.buyingCounty?.includes({ county: "ajay", checked: true })) {
      buying_drop?.buyingCounty?.unshift({ county: "ajay", checked: true })
    }
    setcounty_list(buying_drop?.buyingCounty);
  }


  if (make_dealer?.length == 0 && buying_drop?.buyingMake?.length > 0) {
    if (!buying_drop?.buyingMake?.includes({ checked: true })) {
      buying_drop?.buyingMake?.unshift({ checked: true })
    }


    setMake_Dealer(buying_drop?.buyingMake);
  }

  if (model_dealer?.length == 0 && buying_drop?.buyingModel?.length > 0) {
    if (!buying_drop?.buyingModel?.includes({ model: "ajay", checked: true })) {
      buying_drop?.buyingModel?.unshift({ model: "ajay", checked: true })
    }

    setModel_Dealer(buying_drop?.buyingModel);
  }

 
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          sethandleDiv(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  //mileage filter

  //retail price filter

  const retail_list = [
    {
      id: 0,
      retail: '0-10000',
      retail_data: '£0 - £10,000',
      checked: true,
    },
    {
      id: 1,
      retail: '10000-15000',
      retail_data: '£10,000 - £15,000',
      checked: true,
    },
    {
      id: 2,
      retail: '15000-20000',
      retail_data: '£15,000 - £20,000',
      checked: true,
    },
    {
      id: 3,
      retail: '20000-25000',
      retail_data: '£20,000 - £25,000',
      checked: true,
    },
    {
      id: 4,
      retail: '25000-30000',
      retail_data: '£25,000 - £30,000',
      checked: true,
    },
    {
      id: 5,
      retail: '30000-40000',
      retail_data: '£30,000 - £40,000',
      checked: true,
    },
    {
      id: 6,
      retail: '40000-50000',
      retail_data: '£40,000 - £50,000',
      checked: true,
    },
    {
      id: 7,
      retail: '50000-5000000000',
      retail_data: 'Over £50,000',
      checked: true,
    },
  ];

  var Gross_price_change_list = [
    { id: 0, Gross_price_change: '1 - 250' },
    { id: 1, Gross_price_change: '250-500' },
    { id: 2, Gross_price_change: '500-1000' },
    { id: 3, Gross_price_change: '1000-1500' },
    { id: 4, Gross_price_change: '2500+' },
  ];

  if (retail?.length == 0 && retail_list?.length > 0) {
    setretail_data(retail_list);
  }


  //end retail price filter

  //end vehicle filter

  const locations_change = () => { };

  //update csv file
  // upfatecsvfile
  const [csv_import, Setcsvimport] = useState(false);
  const onImageChange_dta = (e) => {

    var image_files = e.target.files[0];

    var image_data = new FormData();
    image_data.append('uploadfile', image_files);


    Setcsvimport(true)
    dispatch(upfatecsvfile(image_data)).then(function (data) {
      Setcsvimport(false)
    });
    // dispatch(vehicles_list());
  };
  //update csv file

  //report condition
  //calculation
  const [machenical, setmachenical] = useState('');
  const [id, setid] = useState('');
  const [bodywork, setbodywork] = useState('');
  const [wheel_efurbishment, setwheel_efurbishment] = useState('');

  const [service_history, setservice_history] = useState('');
  const [interior_condition, setinterior_condition] = useState('');

  const [keys, setkeys] = useState('');
  const [price, setprice_report] = useState('');
  //end calculation
  const [show_report, setShowreport] = useState(false);
  const [show_condtion_report, setCondtion_report] = useState(false);
  const handleClose_condition = () => setShowreport(false);
  const handleClose_report_condition = () => setCondtion_report(false);

  const view_condition_report = (id, price_view) => {
    dispatch(getConditionReport(id)).then(function (data) {
      setmachenical(data.mechanical);
      setbodywork(data.bodywork);
      // setwheel_efurbishment(data.wheel_efurbishment);
      setwheel_efurbishment(data.wheel_efurbishment);
      setservice_history(data.service_history);
      setinterior_condition(data.interior_condition);
      setkeys(data.keys);
 

      setyotradebid(
        parseInt(price_view) -
        (parseInt(
          data.bodywork != 'No Price' && data.bodywork != ''
            ? data.bodywork
            : 0
        ) +
          parseInt(
            wheel_efurbishment != '' && wheel_efurbishment != 'No Price'
              ? wheel_efurbishment
              : 0
          ) +
          parseInt(
            data.interior_condition != 'No Price' &&
              data.interior_condition != 'No Damage' &&
              data.interior_condition != ''
              ? data.interior_condition
              : 0
          ) +
          parseInt(
            data.keys != 'No Price' && data.keys != '' ? data.keys : 0
          ))
      );

      setShowreport(true);
    });
  };

  //end report condition

  //bulkmail
  const Sendbulkmaildealer = () => {
    dispatch(Sendbulkmail());
  };
  //endbulmail
  //set_data
  const set_number_of_key = (e, id) => {
    dispatch(
      updatevehicleinfo({
        id: id,
        number_of_key: e.target.value,
      })
    ).then(function () {
      // dispatch(vehicles_list());
    });
  };
  const set_service_history = (e, id) => {
    dispatch(
      updatevehicleinfo({
        id: id,
        service_history: e.target.value,
      })
    ).then(function () {
      // dispatch(vehicles_list());
    });
  };
  const set_v5_present = (e, id) => {
    dispatch(
      updatevehicleinfo({
        id: id,
        v5_present: e.target.value,
      })
    ).then(function () {
      //  dispatch(vehicles_list());
    });
  };

  const updateEnter = (e, type) => {
    console.log(e.keyCode, e.key, changeId, textChange, 'e.keyCode');
    if (e.key === 'Enter') {
      switch (type) {
        case 'mileage':
          dispatch(
            updatevehicleinfo({
              id: changeId,
              mileage: textChange,
            })
          );
          break;

        case 'outside_bid':
          dispatch(
            updatevehicleinfo({
              id: changeId,
              out_side_bid: textChange,
            })
          );
          break;

        case 'bought_price':
          dispatch(
            updatevehicleinfo({
              id: changeId,
              bought_price: textChange,
            })
          );
          break;

        case 'notes':
          dispatch(
            updatevehicleinfo({
              id: changeId,
              notes: textChange,
            })
          );
          break;
      }

      setChangeId('');
      setTextChange('');
    }
  };

  const set_input_datta = (e, id, type) => {
    setChangeId(id);
    setTextChange(e.target.value);
    setType(type);
  };

  console.log(vehicle, 'e-ajay-----');
  const set_auto_manual = (e, id) => {
    dispatch(
      updatevehicleinfo({
        id: id,
        auto_manual: e.target.value,
      })
    ).then(function () {
      //  dispatch(vehicles_list());
    });
  };

  const set_client_manager = (e, id) => {
    dispatch(
      updatevehicleinfo({
        id: id,
        client_manager: e.target.value,
      })
    ).then(function () {
      // dispatch(vehicles_list());
    });
  };

  const send_mail_purches = (id) => {
    dispatch(SendPurchesMail({ id: id })).then(function () { });

    // dispatch( Get_Buying_Pagination(1,arrdata, searchInput));
  };
  const conditionalRowStyles = [
    {
      when: (row) => row.purches_offer == 1,
      style: {
        backgroundColor: '#4FD011',
        color: 'white',
        display:"none",
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      when: (row) => row.call_us == 'Call Us',
      style: {
        backgroundColor: '#D8BD5E ',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      when: (row) => row.client_manager == 'Bid Declined',
      style: {
        backgroundColor: '#2B3674 ',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
   
  ];

  //endset data

  //HPI CHECK PDF START
  const hpiCheckPdf = async () => {

  };


  const handleSimpleShow = () => {
    dispatch(hpipdfbuyingsection()).then(function(data){

      console.log(data,"hpidata")
      if(data !=null){
        setHpipdflink(data);
        setLoadIndicator(false)
        setShowSimple(true)
      }
     
    ///  setHpipdflink()
    })
     setLoadIndicator(true)
  };
  //END
  //show view report condtion data
  const [Bidtrader_temp, setyotradebid_temp] = useState('');
  const [vehiclevrm, setvehiclevrm] = useState('');

  const update_report_condition = (id, vrm, price_data) => {
    setid(id);

    setvehiclevrm(vrm);

    dispatch(getConditionReport(id)).then(function (data) {
      setmachenical(data.mechanical);
      setbodywork(data.bodywork);
      setwheel_efurbishment(data.wheel_efurbishment);
      setservice_history(data.service_history);
      setinterior_condition(data.interior_condition);
      setkeys(data.keys);
      setprice_report(
        parseInt(price_data) -
        (parseInt(
          data.bodywork != 'No Price' && data.bodywork != ''
            ? data.bodywork
            : 0
        ) +
          parseInt(
            wheel_efurbishment != '' && wheel_efurbishment != 'No Price'
              ? wheel_efurbishment
              : 0
          ) +
          parseInt(
            data.interior_condition != 'No Price' &&
              data.interior_condition != 'No Damage' &&
              data.interior_condition != ''
              ? data.interior_condition
              : 0
          ) +
          parseInt(
            data.keys != 'No Price' && data.keys != '' ? data.keys : 0
          ))
      );
      setyotradebid_temp(parseInt(price_data));

      setyotradebid(
        parseInt(price_data) -
        (parseInt(
          data.bodywork != 'No Price' && data.bodywork != ''
            ? data.bodywork
            : 0
        ) +
          parseInt(
            wheel_efurbishment != '' && wheel_efurbishment != 'No Price'
              ? wheel_efurbishment
              : 0
          ) +
          parseInt(
            data.interior_condition != 'No Price' &&
              data.interior_condition != 'No Damage' &&
              data.interior_condition != ''
              ? data.interior_condition
              : 0
          ) +
          parseInt(
            data.keys != 'No Price' && data.keys != '' ? data.keys : 0
          ))
      );
      setCondtion_report(true);
    });
  };
  const [Bidtrader, setyotradebid] = useState('');




  //end view condition report data

  //vrm filter

  const [name, setName] = useState('');

  const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = vehicle_list_data.filter((vehicle) => {
        return vehicle.vrm.toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      vehicleset(results);
    } else {
      vehicleset(vehicle_list_data);
      // If the text field is empty, show all users
    }

    setName(keyword);
  };
  //end vrm filter

  const [showconfirmation, setShowconfirmation] = useState(false);
  const handleCloseconfi = () => setShowconfirmation(false);

  const handleShow_condition = () => {
    setCondtion_report(true);

    setShowconfirmation(false);
  };

  const open_confirm_modal = (yo_trader_bid, id, vrm) => {
    setyotradebid(yo_trader_bid);
    setyotradebid_temp(yo_trader_bid);
    setid(id);
    setvehiclevrm(vrm);
    setShowconfirmation(true);
  
  };
  const sort_data = () => {

    Setsort(sort_data_value == 1 ? -1 : 1);

    dispatch(
      Get_Buying_Pagination(1, arrdata, searchInput, sort_data_value == 1 ? -1 : 1)
    );

  }

  console.log(sort_data_value, "sort_data_value-")

  const handlePageClick = async (page_no) => {

    let filteraccount;
    setLoadIndicator(true)
    console.log(page_no?.selected, 'page_no');
    setPaginationChange(true);
    dispatch(
      Get_Buying_Pagination(page_no?.selected + 1, arrdata, searchInput, sort_data_value)
    );
  };

  console.log(vehicle, 'vehicle----------------');

  const handleApply = () => {
    setLoadIndicator(true)
    dispatch(Get_Buying_Pagination(1, arrdata, searchInput, sort_data_value));
    sethandleDiv(false)
  }

  const handleReset = (type) => {
    setLoadIndicator(true)
    arrdata?.splice(arrdata?.findIndex((item) => item?.type == type), 1)

    switch (type) {
      case 'mileage':
        mileage?.forEach(function (data) {
          data.checked = true;
        });
        setmileage_data([]);
        setmileage_data(mileage);
        break;

      case 'stocking_age_range':
        stockingAge?.forEach(function (data) {
          data.checked = true;
        });
        setStockingAge([]);
        setStockingAge(stockingAge);
        break;
      case 'stocking_age':
        stockingData?.forEach(function (data) {
          data.checked = true;
        });
        setstockingData([]);
        setstockingData(stockingData);
        break;

      case 'price':
        retail?.forEach(function (data) {
          data.checked = true;
        });
        setretail_data([]);
        setretail_data(retail);
        break;

      case 'distance_from_retail':
        retailprice_data?.forEach(function (data) {
          data.checked = true;
        });
        setretailprice_data([]);
        setretailprice_data(retailprice_data);
        break;

      case 'year':
        year?.forEach(function (data) {
          data.checked = true;
        });
        setYear([]);
        setYear(year);
        break;


      case 'body_style':
        body_style_data?.forEach(function (data) {
          data.checked = true;
        });
        setbody_style([]);
        setbody_style(body_style_data);
        break;

      case 'county':
        country_code?.forEach(function (data) {
          data.checked = true;
        });
        setcounty_list([]);
        setcounty_list(country_code);
        break;

      case 'make':
        make_dealer?.forEach(function (data) {
          data.checked = true;
        });
        setMake_Dealer([]);
        setMake_Dealer(make_dealer);
        break;

      case 'model':
        model_dealer?.forEach(function (data) {
          data.checked = true;
        });
        setModel_Dealer([]);
        setModel_Dealer(model_dealer);
        break;

      default:
    }
    dispatch(Get_Buying_Pagination(1, arrdata, searchInput, sort_data_value));
    sethandleDiv(false)
  }



  const columns = [
    {
      id: 1,
      name: (
        <div className='dearler-th-drop-c'>
          <div className='filterdropth'>Dealer ID</div>
         
        </div>
      ),
      selector: (row) => <b>{row?.dealer_id?.dealer_id} </b>,

      sortable: false,
      reorder: true,
    },

    {
      id: 1,
      name: (
        <div className='dearler-th-drop-c'>
          <div className='filterdropth'>DMS Provider</div>
         
        </div>
      ),
      selector: (row) => <b>{row?.dealer_id?.dms_provider==null?"---":row?.dealer_id?.dms_provider.name} </b>,

      sortable: false,
      reorder: true,
    },





    {
      id: 1,
      name: (
        <div className='dearler-th-drop-c'>
          <div className='filterdropth'>Dealer Name</div>
          
        </div>
      ),
      selector: (row) => <b>{row?.dealer_id?.company_name==""?"---":row?.dealer_id?.company_name} </b>,

      sortable: false,
      reorder: true,
    },
    {
      id: 2,
      name: 'Contact Name',
      selector: (row) => <b>{row?.dealer_id?.name==""?"---":row?.dealer_id?.name}</b>,
      sortable: false,

      reorder: true,
    },

    {
      id: 4,
      name: 'Mobile Phone',
      selector: (row) => <b>{row?.dealer_id?.main_phone_number==(""||undefined)?"---":row?.dealer_id?.main_phone_number}</b>,
      sortable: false,
      reorder: true,
    },
    {
      id: 3,
      name: 'Main Phone',
      selector: (row) => <b>{(row?.dealer_id?.phone_number==""||row?.dealer_id?.phone_number==undefined)?"---":row?.dealer_id?.phone_number}    </b>,
      sortable: false,
      reorder: true,
    },

    {
      id: 4,
      name: (
        <div className='dearler-th-drop-c '>
          <div onClick={() => {
            sethandleDiv({ "county": handleDiv.county == undefined ? true : !handleDiv.county })
            setWrappedRef("county");
          }} className='filterdropth modal-filter-n'>
            <div>County</div>
            {/* <svg onClick={() => sethandleDiv({ "county": handleDiv.county == undefined ? true : !handleDiv.county })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg> */}
            <svg onClick={() => sethandleDiv({ "county": handleDiv.county == undefined ? true : !handleDiv.county })} class="modal-arrow-down"  width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.7125 0.2925L5.5925 4.1725L9.4725 0.2925C9.8625 -0.0975 10.4925 -0.0975 10.8825 0.2925C11.2725 0.6825 11.2725 1.3125 10.8825 1.7025L6.2925 6.2925C5.9025 6.6825 5.2725 6.6825 4.8825 6.2925L0.2925 1.7025C-0.0975 1.3125 -0.0975 0.6825 0.2925 0.2925C0.6825 -0.0875 1.3225 -0.0975 1.7125 0.2925V0.2925Z" fill="#A3AED0"/>
          </svg>

          </div>

          <div ref={wrappedRefType == "county" ? wrapperRef : null} className={(handleDiv.county == undefined ? false : handleDiv.county) ? 'pop-modal-show' : 'pop-modal-hide'}>
            <div className='select-all-check'>
              <div onClick={() =>
                handleChangefilterChecked(country_code[0]?.checked ? 'deselect' : 'selectall', "county")
              } class="form-check align-check-part">
                <input class="form-check-input" checked={country_code[0]?.checked ? true : false} type="checkbox" value="" />
                <label class="form-check-label" for="">
                  {country_code[0]?.checked ? "Deselect" : "Select All"}
                </label>
              </div>
            </div>
            <div className='checkbox-list-part' >


              {country_code?.map((item, i) => {
                return (
                  i >= 1 && item?.county != "" && item?.county != null && item?.county != "ajay" && <div onClick={() =>
                    handleChangefilterChecked(item?.county, 'county')
                  } class="form-check align-check-part">

                    <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                    <label class="form-check-label" for="flexCheckDefault">
                      {item?.county}
                    </label>
                  </div>
                )
              })}
            </div>
            <div className='filter-appply-btn-part'>
              <button className='btn reset-apply-btn' onClick={() => handleReset("county")}>Reset</button>
              <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
            </div>
          </div>

        </div>
      ),
      selector: (row) => row?.county,
      sortable: false,
      reorder: true,
    },
    {
      id: 5,
      name: 'Main Image',
      selector: (row) => (
        <b>
        
          <a href={row.DeepLink} target='_blank'>
            <img src={row?.image?.length>0?row?.image[0]:"---"} height='40' alt='' />
          </a>
        </b>
      ),
      sortable: false,
      reorder: true,
    },
    {
      id: 6,
      name: 'Registration (VRM)',
      selector: (row) => <b>{row?.vrm}</b>,
      sortable: false,
      reorder: true,
    },
    {
      id: 7,
      name: (
        <div className='dearler-th-drop-c'>

          <div onClick={() => {
            sethandleDiv({ "mileage": handleDiv.mileage == undefined ? true : !handleDiv.mileage })
            setWrappedRef("mileage");
          }} className='filterdropth modal-filter-n'>
            <div>Mileage</div>
            {/* <svg onClick={() => sethandleDiv({ "mileage": handleDiv.mileage == undefined ? true : !handleDiv.mileage })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg> */}
           
            <svg onClick={() => sethandleDiv({ "mileage": handleDiv.mileage == undefined ? true : !handleDiv.mileage })} class="modal-arrow-down"  width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.7125 0.2925L5.5925 4.1725L9.4725 0.2925C9.8625 -0.0975 10.4925 -0.0975 10.8825 0.2925C11.2725 0.6825 11.2725 1.3125 10.8825 1.7025L6.2925 6.2925C5.9025 6.6825 5.2725 6.6825 4.8825 6.2925L0.2925 1.7025C-0.0975 1.3125 -0.0975 0.6825 0.2925 0.2925C0.6825 -0.0875 1.3225 -0.0975 1.7125 0.2925V0.2925Z" fill="#A3AED0"/>
          </svg>
          
          </div>

          <div className={(handleDiv.mileage == undefined ? false : handleDiv.mileage) ? 'pop-modal-show' : 'pop-modal-hide'}>
            <div ref={wrappedRefType == "mileage" ? wrapperRef : null}>

              <div className='checkbox-list-part' >
                {mileage?.map((item, i) => {
                  return (
                    <div onClick={() =>
                      handleChangefilterChecked(item?.milage, 'mileage')
                    } class="form-check align-check-part">

                      <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                      <label class="form-check-label" for="flexCheckDefault">
                        {item?.milage_data}
                      </label>
                    </div>
                  )
                })}
              </div>
              <div className='filter-appply-btn-part'>
                <button className='btn reset-apply-btn' onClick={() => handleReset("mileage")}>Reset</button>
                <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
              </div>
            </div>

          </div>
        </div>
      ),
      selector: (row) => (
        <input
          type='text'
          name='mileage'
          className='outside-bid border-input-none'
          onChange={(e) => set_input_datta(e, row._id, 'mileage')}
          onKeyDown={(e) => updateEnter(e, 'mileage')}
          value={
            changeId == row?._id && typeOf == 'mileage'
              ? textChange
              : row.mileage != '' &&
                row.mileage != null &&
                row.mileage != 'null'
                ? row.mileage
                : ''
          }
        />
      ),
      sortable: false,
      reorder: false,
    },
    {
      id: 8,
      name: (
        <div className='dearler-th-drop-c '>
          <div onClick={() => {
            sethandleDiv({ "make": handleDiv.make == undefined ? true : !handleDiv.make })
            setWrappedRef("make");
          }} className='filterdropth modal-filter-n'>
            <div>Make</div>
            {/* <svg onClick={() => sethandleDiv({ "make": handleDiv.make == undefined ? true : !handleDiv.make })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg> */}

            <svg onClick={() => sethandleDiv({ "make": handleDiv.make == undefined ? true : !handleDiv.make })} class="modal-arrow-down"  width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.7125 0.2925L5.5925 4.1725L9.4725 0.2925C9.8625 -0.0975 10.4925 -0.0975 10.8825 0.2925C11.2725 0.6825 11.2725 1.3125 10.8825 1.7025L6.2925 6.2925C5.9025 6.6825 5.2725 6.6825 4.8825 6.2925L0.2925 1.7025C-0.0975 1.3125 -0.0975 0.6825 0.2925 0.2925C0.6825 -0.0875 1.3225 -0.0975 1.7125 0.2925V0.2925Z" fill="#A3AED0"/>
          </svg>
          
          </div>

          <div ref={wrappedRefType == "make" ? wrapperRef : null} className={(handleDiv.make == undefined ? false : handleDiv.make) ? 'pop-modal-show' : 'pop-modal-hide'}>
            <div className='select-all-check'>
              <div onClick={() =>
                handleChangefilterChecked(make_dealer[0]?.checked ? 'deselect' : 'selectall', "make")
              } class="form-check align-check-part">
                <input class="form-check-input" checked={make_dealer[0]?.checked ? true : false} type="checkbox" value="" />
                <label class="form-check-label" for="">
                  {make_dealer[0]?.checked ? "Deselect" : "Select All"}
                </label>
              </div>
            </div>
            <div className='checkbox-list-part' >


              {make_dealer?.map((item, i) => {
                return (
                  i >= 1 && <div onClick={() =>
                    handleChangefilterChecked(item?.make, 'make')
                  } class="form-check align-check-part">

                    <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                    <label class="form-check-label" for="flexCheckDefault">
                      {item?.make}
                    </label>
                  </div>
                )
              })}
            </div>
            <div className='filter-appply-btn-part'>
              <button className='btn reset-apply-btn' onClick={() => handleReset("make")}>Reset</button>
              <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
            </div>
          </div>
        </div>
      ),
      selector: (row) => row?.make,
      sortable: false,
      reorder: false,
    },
    {
      id: 9,
      name: (
        <div className='dearler-th-drop-c '>
          <div onClick={() => {
            sethandleDiv({ "model": handleDiv.model == undefined ? true : !handleDiv.model })
            setWrappedRef("model");
          }} className='filterdropth modal-filter-n'>
            <div>Model</div>
            {/* <svg onClick={() => sethandleDiv({ "model": handleDiv.model == undefined ? true : !handleDiv.model })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg> */}
            <svg onClick={() => sethandleDiv({ "model": handleDiv.model == undefined ? true : !handleDiv.model })} class="modal-arrow-down"  width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.7125 0.2925L5.5925 4.1725L9.4725 0.2925C9.8625 -0.0975 10.4925 -0.0975 10.8825 0.2925C11.2725 0.6825 11.2725 1.3125 10.8825 1.7025L6.2925 6.2925C5.9025 6.6825 5.2725 6.6825 4.8825 6.2925L0.2925 1.7025C-0.0975 1.3125 -0.0975 0.6825 0.2925 0.2925C0.6825 -0.0875 1.3225 -0.0975 1.7125 0.2925V0.2925Z" fill="#A3AED0"/>
          </svg>
          </div>

          <div ref={wrappedRefType == "model" ? wrapperRef : null} className={(handleDiv.model == undefined ? false : handleDiv.model) ? 'pop-modal-show' : 'pop-modal-hide'}>
            <div className='select-all-check'>
              <div onClick={() =>
                handleChangefilterChecked(model_dealer[0]?.checked ? 'deselect' : 'selectall', "model")
              } class="form-check align-check-part">
                <input class="form-check-input" checked={model_dealer[0]?.checked ? true : false} type="checkbox" value="" />
                <label class="form-check-label" for="">
                  {model_dealer[0]?.checked ? "Deselect" : "Select All"}
                </label>
              </div>
            </div>
            <div className='checkbox-list-part' >


              {model_dealer?.map((item, i) => {
                return (
                  (i >= 1 && item?.model != "ajay") && <div onClick={() =>
                    handleChangefilterChecked(item?.model, 'model')
                  } class="form-check align-check-part">

                    <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                    <label class="form-check-label" for="flexCheckDefault">
                      {item?.model}
                    </label>
                  </div>
                )
              })}
            </div>
            <div className='filter-appply-btn-part'>
              <button className='btn reset-apply-btn' onClick={() => handleReset("model")}>Reset</button>
              <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
            </div>
          </div>
        </div>
      ),
      selector: (row) => row?.model,
      sortable: false,
      reorder: false,
    },
    {
      id: 9,
      name: (<>

        <div className='dearler-th-drop-c '>
          <div onClick={() => {
            sethandleDiv({ "year": handleDiv.year == undefined ? true : !handleDiv.year })
            setWrappedRef("year");
          }} className='filterdropth modal-filter-n'>
            <div>Year</div>
            {/* <svg onClick={() => sethandleDiv({ "year": handleDiv.year == undefined ? true : !handleDiv.year })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg> */}
            <svg onClick={() => sethandleDiv({ "year": handleDiv.year == undefined ? true : !handleDiv.year })} class="modal-arrow-down"  width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.7125 0.2925L5.5925 4.1725L9.4725 0.2925C9.8625 -0.0975 10.4925 -0.0975 10.8825 0.2925C11.2725 0.6825 11.2725 1.3125 10.8825 1.7025L6.2925 6.2925C5.9025 6.6825 5.2725 6.6825 4.8825 6.2925L0.2925 1.7025C-0.0975 1.3125 -0.0975 0.6825 0.2925 0.2925C0.6825 -0.0875 1.3225 -0.0975 1.7125 0.2925V0.2925Z" fill="#A3AED0"/>
          </svg>
          
          </div>

          <div ref={wrappedRefType == "year" ? wrapperRef : null} className={(handleDiv.year == undefined ? false : handleDiv.year) ? 'pop-modal-show' : 'pop-modal-hide'}>
            <div className='select-all-check'>
              <div onClick={() =>
                handleChangefilterChecked(year[0]?.checked ? 'deselect' : 'selectall', "year")
              } class="form-check align-check-part">
                <input class="form-check-input" checked={year[0]?.checked ? true : false} type="checkbox" value="" />
                <label class="form-check-label" for="">
                  {year[0]?.checked ? "Deselect" : "Select All"}
                </label>
              </div>
            </div>
            <div className='checkbox-list-part' >


              {year?.map((item, i) => {
                return (
                  i >= 1 && <div onClick={() =>
                    handleChangefilterChecked(item?.year_select, 'year')
                  } class="form-check align-check-part">

                    <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                    <label class="form-check-label" for="flexCheckDefault">
                      {item?.year}
                    </label>
                  </div>
                )
              })}
            </div>
            <div className='filter-appply-btn-part'>
              <button className='btn reset-apply-btn' onClick={() => handleReset("year")}>Reset</button>
              <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
            </div>
          </div>

        </div>
      </>

      ),
      selector: (row) => row?.year,
      sortable: false,
      reorder: false,
    },
   
    {
      id: 11,
      name: (
        <div className='dearler-th-drop-c '>
          <div onClick={() => {
            // sethandleDiv({ "price": handleDiv.price == undefined ? true : !handleDiv.price })
            // setWrappedRef("price");
          }} className='filterdropth modal-filter-n'>
            <div>Stocking Age</div>
            {/* <svg  class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg> */}
            <svg class="modal-arrow-down"  width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.7125 0.2925L5.5925 4.1725L9.4725 0.2925C9.8625 -0.0975 10.4925 -0.0975 10.8825 0.2925C11.2725 0.6825 11.2725 1.3125 10.8825 1.7025L6.2925 6.2925C5.9025 6.6825 5.2725 6.6825 4.8825 6.2925L0.2925 1.7025C-0.0975 1.3125 -0.0975 0.6825 0.2925 0.2925C0.6825 -0.0875 1.3225 -0.0975 1.7125 0.2925V0.2925Z" fill="#A3AED0"/>
          </svg>
          
          </div>

          {/* <div ref={wrappedRefType == "price" ? wrapperRef : null} className={(handleDiv.price == undefined ? false : handleDiv.price) ? 'pop-modal-show' : 'pop-modal-hide'}>
        
            <div className='checkbox-list-part' >


              {retail?.map((item, i) => {
                return (
                  <div onClick={() =>
                    handleChangefilterChecked(item?.retail, 'price')
                  } class="form-check align-check-part">

                    <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                    <label class="form-check-label" for="flexCheckDefault">
                      {item?.retail_data}
                    </label>
                  </div>
                )
              })}
            </div>
            <div className='filter-appply-btn-part'>
              <button className='btn reset-apply-btn' onClick={() => handleReset("price")}>Reset</button>
              <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
            </div>
          </div> */}

        </div>
      ),
      selector: (row) =>
      row?.stocking_age,
      sortable: false,
      reorder: true,
    },
    

    {
      id: 11,
      name: (
        <div className='dearler-th-drop-c '>
          <div onClick={() => {
            sethandleDiv({ "price": handleDiv.price == undefined ? true : !handleDiv.price })
            setWrappedRef("price");
          }} className='filterdropth modal-filter-n'>
            <div>Retail Price</div>
            {/* <svg onClick={() => sethandleDiv({ "price": handleDiv.price == undefined ? true : !handleDiv.price })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg> */}
            
            <svg onClick={() => sethandleDiv({ "price": handleDiv.price == undefined ? true : !handleDiv.price })} class="modal-arrow-down"  width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.7125 0.2925L5.5925 4.1725L9.4725 0.2925C9.8625 -0.0975 10.4925 -0.0975 10.8825 0.2925C11.2725 0.6825 11.2725 1.3125 10.8825 1.7025L6.2925 6.2925C5.9025 6.6825 5.2725 6.6825 4.8825 6.2925L0.2925 1.7025C-0.0975 1.3125 -0.0975 0.6825 0.2925 0.2925C0.6825 -0.0875 1.3225 -0.0975 1.7125 0.2925V0.2925Z" fill="#A3AED0"/>
          </svg>
          
          </div>

          <div ref={wrappedRefType == "price" ? wrapperRef : null} className={(handleDiv.price == undefined ? false : handleDiv.price) ? 'pop-modal-show' : 'pop-modal-hide'}>
        
            <div className='checkbox-list-part' >
              {retail?.map((item, i) => {
                return (
                  <div onClick={() =>
                    handleChangefilterChecked(item?.retail, 'price')
                  } class="form-check align-check-part">

                    <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                    <label class="form-check-label" for="flexCheckDefault">
                      {item?.retail_data}
                    </label>
                  </div>
                )
              })}
            </div>
            <div className='filter-appply-btn-part'>
              <button className='btn reset-apply-btn' onClick={() => handleReset("price")}>Reset</button>
              <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
            </div>
          </div>

        </div>
      ),
      selector: (row) =>
        new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP',
        })
          .format(row?.price)
          .replace(/\.00$/, ''),
      sortable: false,
      reorder: true,
    },
    
  ];
  //end vehicle filter

  const filteredArr = [];

  vehicle?.map((item, i) => {
    return filteredArr.push([item?.vrm, item?.mileage]);
  });
  // console.log(filteredArr, 'filteredArr');



  const view_condition_report_view_hide_show = (row) => {
    if (row.report_condition == 1) {
      return (
        <button
          className='btn pending_genr'
          onClick={() =>
            view_condition_report(
              row._id,

              Yotraderbidcalculate(row, yotaderbid)
            )
          }
        >
          View{' '}
        </button>
      );
    }
  };

  function Yotraderbidcalculate(data, yo_trader_bid) {

    
    if (yo_trader_bid != undefined) {
      var temp_cap =
        data.cap_live >= data.cap_monthly ? data.cap_monthly : data.cap_live;
      var youtrader_bid = parseFloat(
        (parseFloat(temp_cap) *
          parseFloat(
            data.confirmed_yotraderbid != null
              ? data.confirmed_yotraderbid
              : yo_trader_bid
          )) /
        100
      );
      var retail_price =
        (parseFloat(data.price) - parseFloat(youtrader_bid) >= 250 &&
          parseFloat(temp_cap) - parseFloat(youtrader_bid) >= 800) == true
          ? youtrader_bid
          : '';

      return retail_price;
    } else {
      return 0;
    }
  }

  

  const keyPress=(e)=>{
    if (e.key === "Enter") {
      searchWithFilter(searchInput);
    }
   }

  return (
    <Master>

          {/* start page title */}
          <div className='row'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-end'>
              <div>
                <span className='small_text'>YoTrader </span>
                <h4 className='mb-sm-0 font-size-28'>
                All Vehicles <span className='blue_text'>{pagiantion_data?.total_data}</span>
                </h4>
              </div>           
            </div>
         

            <div className='page-title-right'>
              {/* App Search */}
              <form className='app-search d-none d-lg-block '>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='position-relative w-100'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Search'
                    />
                    <span className='bx bx-search'></span>
                  </div>                 
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end page title  */} 

      <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <div className='d-flex'>
                    <h4 className='title_text'>All Vehicles</h4>
                    <div className='select_new'>
                      <span>
                        <select className='form-select' name='' id=''>
                          <option value=''>Saved Filters</option>
                        </select>
                      </span>
                      <span>
                        <select className='form-select' name='' id=''>
                          <option value=''>Save Selection</option>
                        </select>
                      </span>
                    </div>
                  </div>

                  <span>

                    {Math.round(progress) == 0 || Math.round(progress) == 100 ?
                      <button
                        className='btn bg_trderbid '
                        onClick={handleyotraderShow}
                      >
                        YoTrader Bid
                      </button>
                      : <button
                        className='btn bg_trderbid '
                      // onClick={handleyotraderShow}
                      >
                        YoTrader Bid
                      </button>
                    }
                    {pagiantion_data?.total_data<=5000?
                      <div
                      //data={filteredArr}setshow_export
                      onClick={() => {
                        
                        dispatch(

                          Get_Buying_Pagination(
                            1,
                            arrdata,
                            searchInput,
                            sort_data_value,
                            'export',

                          )
                        );
                        setshow_export(true);
                      }}
                      className='btn bg_import export-button-m'
                    >
                      Export as CSV
                    </div>:<div
                      //data={filteredArr}setshow_export
                      onClick={() => {
                      
                        toast.warning('Selected results must be under 5000.')
                      }}
                      className='btn bg_import export-button-m'
                    >
                      Export as CSV
                    </div>
                    
                    
                    
                    }

                 

                    <input
                      type='file'
                      className='btn hide-upload-input '
                      onChange={onImageChange_dta}
                    />
                    <button className='btn bg_import upload-csv-m '>
                      Import CSV
                    </button>
                  </span>
                </div>

                <div className='table-responsive new_table_react buying_table nowrap w-100  no-footer  table-scroll'>
                  {console.log(vehicle, 'kkkkkkkkkkkkk')}

                  {
                    Math.round(progress) > 0 && Math.round(progress) != 100 ?
                      (<div>
                        <div className='progress-container'>
                          <div className='progress-heading'>
                            Applying Yotrader Bid
                          </div>
                          <ProgressBar
                            now={Math.round(progress)}
                            label={`${Math.round(progress)}%`}
                          />
                        </div>
                      </div>) : ""
                  }

                  <DataTable className='table-scroll'
                    columns={columns}
                    data={
                      vehicle?.length > 0
                        ? vehicle
                        : [
                          {
                           
                            'Dealer Name': '',
                            'Contact Name': '',
                            'Main Phone': '',
                            'Mobile Phone': '',
                            Location: '',
                            'Main Image': '',
                            'Registration (VRM)': '',
                            Milage: '',
                            Make: '',
                            Model: '',
                            'Stocking Age': '',
                            'Retail Price': '',
                            'CAP Live': '',
                            'CAP Monthly': '',
                            'Outside Bid': '',
                            'YoTrader Bid': '',
                            'Distance From Retail': '',
                            'Date of Underwrite': '',
                            'Condition Report': '',
                            'Re Conditioning': '',
                            'Price Adjused': '',
                            'Bought Price': '',
                            'V5 Present?': '',
                            'Service History': '',
                            'Number of Keys': '',
                            'Manual / Auto': '',
                            'HPI Check': '',
                            Share: '',
                            'Purchase Email': '',
                            'No Answer': '',
                            Notes: '',
                          },
                        ]
                    }
                    conditionalRowStyles={conditionalRowStyles}
                    customStyles={tableCustomStyles}
                  />
                  <div className='pagination-wrapper mt-3'>
                    <ReactPaginate 
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      pageCount={pagiantion_data?.total_page}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination justify-content-center'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      activeClassName={'active'}
                    />
                  </div>
                  {vehicle?.length > 0
                    ? ' '
                    : firstFilterRetain == undefined && (

                      <div className='loader-center-d'>
                        <img
                          src={require('../assets/images/loader.gif')}
                          height='60'
                        />
                      </div>
                    )}
                  {csv_import && (

                    <div className='loader-center-d'>
                      <img
                        src={require('../assets/images/loader.gif')}
                        height='60'
                      />
                    </div>
                  )}
                  {loadIndicator && (

                    <div className='loader-center-d'>
                      <img
                        src={require('../assets/images/loader.gif')}
                        height='60'
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size='lg'
        show={show}
        onHide={handleClose}
        className='expand-img-c'
      >
        <Modal.Header closeButton className='close-expand-btn'></Modal.Header>
        <Modal.Body>
          <img src={imagedata} alt='' />
        </Modal.Body>
      </Modal>

      <Modal
        className='bg-small-popup'
        size='sm'
        show={show_yotrader}
        onHide={handleyotraderClose_yo}
      >
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className='small-title-popup'>
              Set Bid Behind CAP
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='input-group-c'>
              <InputGroup className='mb-3'>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  placeholder='0.00'
                  aria-label='Username'
                  name='bid_value'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bid_value}
                />
              </InputGroup>
            </div>
            {formik.errors.bid_value ? (
              <p style={{ color: 'red' }}>{formik.errors.bid_value}</p>
            ) : null}
          </Modal.Body>
          <Modal.Footer className='small-footer-popup'>
            <Button className='clear-btn-small' onClick={handleyotraderClose}>
              Clear
            </Button>
            <Button className='set-btn-small' type='submit'>
              Set
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        className='bg-small-popup'
        size='sm'
        show={show_export}
        onHide={handleExportClose}
      >
        <Modal.Body>
        
          <span>Are you sure you want to export csv?</span>
        
        
        
       </Modal.Body>
        <Modal.Footer className='small-footer-popup'>
          <Button className='clear-btn-small' onClick={handleExportClose}>
            No
          </Button>
          <a
            href= {process.env.REACT_APP_IMAGE_URL+'/csfile/file.csv'}
            className='set-btn-small'
          >
            Yes
          </a>
        </Modal.Footer>
      </Modal>

   

      <Modal
        show={showconfirmation}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <div className='modal-body'>
          <div className='confir_ion p-3'>
            <div className='confirmation_center text-center'>

           
              <h2>Are you sure?</h2>
              <p>Are you sure you want to accept the offer of </p>
              <h1>
                {new Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                })
                  .format(parseInt(Bidtrader))
                  .replace(/\.00$/, '')}{' '}
              </h1>
              <p>for</p>
              <div className='btnside'>
                <button className='btn btn-progress'>{vehiclevrm}</button>
              </div>
            </div>
            <div className='btn_set '>
              <button
                className='btn clear_btn'
                onClick={() => handleCloseconfi()}
                data-dismiss='modal'
              >
                Cancel{' '}
              </button>
              <button
                className='btn conf_btn'
                data-toggle='modal'
                data-target='#details_confirm'
                onClick={() => handleShow_condition()}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>

        {/* <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showSimple} onHide={handleSimpleClose}>
        <Modal.Header closeButton>
          <Modal.Title>HPI Check</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to create a HPI Check?</Modal.Body>
        <Modal.Footer>
          <SimpleBtn variant='secondary' onClick={handleSimpleClose}>
            Cancel
          </SimpleBtn>
          <SimpleBtn variant='primary' onClick={handleSimpleClose}>
            <a
              //put the path of your pdf file
              href={Hpipdflink}
            //reactstrap classes. add green button
            >
              Create
            </a>
          </SimpleBtn>
        </Modal.Footer>
      </Modal>
    </Master>
  );
}
