import React from 'react'
import Master from '../Include/Master'
import { NavLink, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { purchaseVehiclesAction } from '../../Redux/Action/AcceptedOfferAction';
import moment from 'moment';

export default function Purchases() {
    const dealer_vehicle_id=useParams();
    const dispatch=useDispatch();
    useEffect(()=>{
        dispatch(purchaseVehiclesAction({
            dealer_id:dealer_vehicle_id.id
        }))
    },[])

    const purchasevehicles=useSelector((state)=>state.Acceptedofferreducer.purchasevehicles);
  


  return (
    <Master>
          {/* start page title */}
          <div className='row'>
          <div className='col-12'>
            <div className='page-title-head d-sm-flex align-items-center justify-content-between'>
              <div className='page-title-head-left d-sm-flex align-items-center justify-content-between'>
                <NavLink to='/admin/dealers'>
                  {' '}
                  <img
                    src={require('../assets/images/Expand_left.svg').default}
                    alt=''
                    className='back_btn'
                  />
                </NavLink>
                <h4 className='mb-sm-0 font-size-34 blue-color fw-700'>
                {purchasevehicles?.dealerDetails?.company_name}
                </h4>
              </div>

              <div className='page-title-right'>
                <p className='mb-0 blue-color font-size-14 fw-700'>
                  Joined: {moment(purchasevehicles?.dealerDetails?.createdAt).format('DD/MM/Y')}
                </p>
              </div>
            </div>
          </div>
        </div>
      {/* end page title  */}
      <div className='row'>
            <div className='col-xl-12'>
            <div className='tabs-btn-right'>
              <ul>
                {/* <li>
                  <NavLink to={`/admin/dealer_detail/`+acceptedoffer?.dealerDetails?._id} className="white-anchor-right">Overview</NavLink>
                </li> */}
                <li>
                  <NavLink to={'/admin/dealer_vehicles/'+ dealer_vehicle_id.id}  className="white-anchor-right">Vehicles</NavLink>
                </li>
                <li>
                  <NavLink to={'/admin/dealer_live_bids/'+ dealer_vehicle_id?.id} className="white-anchor-right">Live Auctions</NavLink>
                </li>
                <li>
                <NavLink to={'/admin/dealer_expire_bids/'+ dealer_vehicle_id?.id} className="white-anchor-right">Ended Auctions</NavLink>                
                </li>
                
                <li>
                <NavLink to={'/admin/offer_accepted/'+ dealer_vehicle_id?.id} className="white-anchor-right">Sold Vehicles</NavLink>
                </li>
                <li>
                <NavLink to={'/admin/purchases/'+dealer_vehicle_id?.id} className="blue-anchor-right">Purchases</NavLink>
                </li>  
                <li>
                <NavLink to={'/admin/invoice/'+dealer_vehicle_id?.id} className="white-anchor-right">Invoices</NavLink>
                </li>               
              </ul>
            </div>
             
            </div>
          </div>
          <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <h4 className='title_text'>Purchases Vehicles { purchasevehicles?.purchasevehicles?.length}</h4>
             
                </div>

                <div className='table-responsive dealer-table-c table-scroll'>
                  <table
                    id=''
                    className='table dt-responsive vehicles_table nowrap w-100'
                  >
                    <thead>
                      <tr>
                       
                        <th>Main Image</th>
                        <th>Registration</th>
                        <th>Mileage</th>
                        <th>Make</th>
                        <th>Model </th>
                        <th>Stock Age</th>
                        <th>Retail Price</th>
                        <th>Winning Bid</th>
                        <th>Selling Dealer</th>
                        <th>Purchase Date</th>
                        <th>Purchase fee paid</th>
                       
                      </tr>
                    </thead>
                    <tbody className='td_color sticky-header-c table-scroll'>
                 
                    {
                        purchasevehicles?.purchasevehicles?.map(function(purchase_object,i){
                            return(   <tr >
                                <td>
                          <img
                            src={purchase_object?.newvaluationdetails?.images[2]}
                            height='40'
                            alt=''
                          />
                        </td>
                        <td>
                          {purchase_object?.newvaluationdetails?.vrm}
                        </td>
                        <td>
                          {purchase_object?.newvaluationdetails?.mileage}
                        </td>
                        <td>
                          {purchase_object?.newvaluationdetails?.make}
                        </td>
                        <td>
                          {purchase_object?.newvaluationdetails?.model}
                        </td>
                        <td>1</td>
                        <td> {   new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format(purchase_object?.saleprice).replace(/\.00$/, '') }</td>
                        <td> {   new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format(purchase_object?.vehicle_id?.price).replace(/\.00$/, '') }</td>
                        <td>
                          {purchase_object?.user_detail?.company_name}
                        </td>
                        <td>
                          
                          { moment(purchase_object?.createdAt).format('DD-MM-YYYY H:mm')}
                        </td>
                        <td>   <td>{purchase_object.delivered_buyer==0?"No":"Yes"}</td></td>
                         </tr>)
                        })
                       
                        
                     
                    }
                    </tbody>
                  
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Master>
  )
}
