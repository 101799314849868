import React from 'react'


const Terms = () =>{
    return (
        <>
        <div className='terms-page'>
            <div className='container'>
                <h1>TERMS AND CONDITIONS</h1>
                <h2>YOTRADER LTD TERMS OF WEBSITE USE</h2>

                <div className='content-group'>
                    <p>
                    Thank you for visiting yotrader.co.uk. These terms and conditions ("Terms", "Agreement") are an agreement between yotrader LTD ("yotrader LTD", "us", "we" or "our") and you ("User", "you" or "your"). This Agreement sets forth the general terms and conditions of your use of the yotrader.co.uk website and any of its products or services (collectively, "Website" or "Services").</p>
                </div>               
                <div className='content-group'>
                    <h4>About yotrader.co.uk</h4>
                    <p>
                    yotrader Ltd is an online vehicle trading platform for professional vehicle retailers only based in the UK, offering a vehicle underwriting, trading buying platform. yotrader Ltd do not own or have possession of any vehicles advertised on or sold through our site. yotrader.co.uk is not involved in any transactions between sellers and buyers. The contract of automotive vehicles sale(s) is directly between the seller and buyer.</p>
                    <p>We are registered in England and Wales under company number 13475574 and our registered address is 7 / 8 New Street, Alfreton, Derbyshire, DE55 7BP.</p>
                    <p>During the use of our website, we may offer guidance in regards to pricing, listing, trading and buying other related guidance may also be offered. However, such advice is informational only, and it is your choice to use it or not. While yotrader.co.uk takes its legal responsibilities very seriously, yotrader has no control over and does not guarantee the existence, quality, safety or legality of vehicles advertised or sold. In the event, yotrader Ltd is notified of any illegal activity, We will take all appropriate steps to alert the proper authorities.</p>
                </div>
                <div className='content-group'>
                    <h4>Accounts and membership</h4>
                    <p>You must be at least 16 years of age to create an account. If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may monitor and review new accounts before you may sign in and use our Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorised uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or Content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.</p>
                </div>
                <div className='content-group'>
                    <h4>Fees</h4>
                    <p>
              
                    The website is free to use and create an account. All cars bought and sold are subject to a broker fee of £100 or 1% whichever is more.
                    </p>
              
                </div>
                <div className='content-group'>
                    <h4>Using yotrader</h4>
                    <p>When using our Website or services, you will not:</p>
                    <p>Advertise or upload Content which inappropriate or deemed offensive; Breach any laws or our policies and procedures; Sell stolen goods or vehicles; Use our services if you are not able to form legally binding contracts; Post false, inaccurate, misleading, defamatory, or libellous content; Transfer your yotrader account and user ID to another party without our consent; Distribute viruses or any other technologies that may harm yotrader, or the interests or property of yotrader users; Use any robot, spider, scraper or other automated means to access our services for any purpose; Harvest or otherwise collect information about users, such as email addresses, without their consent; or circumvent any technical measures we use to provide the services.</p>
                </div>
                <div className='content-group'>
                    <h4>User content</h4>
                    <p>We do not own any vehicle, information or material ("Content") that you submit on the Website in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We monitor and review Content on the Website submitted by you. You grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable.</p>
                
                </div>
                <div className='content-group'>
                    <h4>Billing and payments</h4>
                    <p>You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. If, in our judgement, your transaction constitutes a high-risk transaction, we will require up front payment. We reserve the right to change fees/pricing at any time.</p>
                
                </div>
                <div className='content-group'>
                    <h4>Accuracy of information</h4>
                    <p>Occasionally there may be information on the Website that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, availability, promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Website or on any related Service is inaccurate at any time without prior notice. We undertake no obligation to update, amend or clarify information on the Website including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Website should be taken to indicate that all information on the Website or on any related Service has been modified or updated.</p>
                
                </div>
                <div className='content-group'>
                    <h4>Third-part services</h4>
                    <p>If you decide to enable, access or use third-party services, be advised that your access and use of such other services are governed solely by the terms and conditions of such other services, and we do not endorse, are not responsible or liable for, and make no representations as to any aspect of such other services, including, without limitation, their Content or the manner in which they handle data (including your data) or any interaction between you and the provider of such other services. You irrevocably waive any claim against yotrader LTD with respect to such other services. yotrader LTD is not liable for any damage or loss caused or alleged to be caused by or in connection with your enablement, access or use of any such other services, or your reliance on the privacy practices, data security processes or other policies of such other services. You may be required to register for or log into such other services on their respective websites. By enabling any other services, you are expressly permitting yotrader LTD to disclose your data as necessary to facilitate the use or enablement of such other service.</p>
                
                </div>
                <div className='content-group'>
                    <h4>Prohibited uses</h4>
                    <p>In addition to other terms as set forth in the Agreement, you are prohibited from using the Website or its Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</p>
                
                </div>
                <div className='content-group'>
                    <h4>Intellectual property rights</h4>
                    <p>This Agreement does not transfer to you any intellectual property owned by yotrader LTD or third-parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with yotrader LTD. All trademarks, service marks, graphics and logos used in connection with our Website or Services, are trademarks or registered trademarks of yotrader LTD or yotrader LTD licensors. Other trademarks, service marks, graphics and logos used in connection with our Website or Services may be the trademarks of other third-parties. Your use of our Website and Services grants you no right or license to reproduce or otherwise use any yotrader LTD or third-party trademarks.</p>
                
                </div>
                <div className='content-group'>
                    <h4>Indemnification</h4>
                    <p>You agree to indemnify and hold yotrader LTD and its affiliates, directors, officers, employees, and agents harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Website or Services or any willful misconduct on your part.</p>
                
                </div>
                <div className='content-group'>
                    <h4>Severability</h4>
                    <p>All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.</p>
                
                </div>
                <div className='content-group'>
                    <h4>Changes and amendments</h4>
                    <p>We reserve the right to modify this Agreement or its policies relating to the Website or Services at any time, effective upon posting of an updated version of this Agreement on the Website. When we do, we will revise the updated date at the bottom of this page. Continued use of the Website after any such changes shall constitute your consent to such changes.</p>
                
                </div>
                <div className='content-group'>
                    <h4>Acceptance of these terms</h4>
                    <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorised to use or access the Website and its Services.</p>
                
                </div>
            </div>
        </div>
        
        </>
    )
}

export default Terms