import { array } from 'yup';
import {
  DEALER_VEHICLES_DATA,
  TRANPORT_VEHICLES_LIST,
  UPDATE_COLUMN_VALUE,
  UPDATE_PRICE_VALUE,
  VEHICLES_LIST,
  UPDATE_COLUMN_BID_VALUE,
  BUYING_DROP_LIST,
  BUYING_PAGINATION,
} from '../Constant';

var initialValues = {
  vehicle_list: [],
  vehicle_list_data: [],
  transport_vehicle_list: [],
  filter_single_vehicle: {},
  buying_drop: {},
  buying_page: [],
};
const VehiclesReducer = (state = initialValues, action) => {
  switch (action.type) {
    case VEHICLES_LIST:
      return {
        ...state,
        vehicle_list: action.payload,
      };
    case DEALER_VEHICLES_DATA:
      return {
        ...state,
        vehicle_list_data: action.payload,
      };
    case TRANPORT_VEHICLES_LIST:
      return {
        ...state,
        transport_vehicle_list: action.payload,
      };

    case UPDATE_COLUMN_VALUE:

      console.log(
       action.payload.data.purches_offer,
      
        'payloadDatastate.vehicle_list'
      );

      const modifiedArr = state.buying_page.vehicle_list_data.map((obj, i) =>
        obj._id.toString() == action.payload.data._id.toString() 
          ? {
              ...action.payload.data,
              Price_Adjusted:
                state?.buying_page?.vehicle_list_data[i]?.Price_Adjusted,
              dealer_id: state?.buying_page?.vehicle_list_data[i]?.dealer_id,
              report_condition:
                state?.buying_page?.vehicle_list_data[i]?.report_condition,
            }
          : obj
      );
      //Filter array
      const filterArr = [];
      state.buying_page.vehicle_list_data.map(
        (obj, i) =>
          obj._id.toString() == action.payload.data._id.toString()  &&
          filterArr?.push({
            ...action.payload.data,
            Price_Adjusted:
              state?.buying_page?.vehicle_list_data[i]?.Price_Adjusted,
            dealer_id: state?.buying_page?.vehicle_list_data[i]?.dealer_id,
            report_condition:
              state?.buying_page?.vehicle_list_data[i]?.report_condition,
          })
      );

      state.buying_page.vehicle_list_data = modifiedArr;
      const finalArr = state.buying_page;
      console.log(state, 'state --098');
      return {
        ...state,
        buying_page: finalArr,
        filter_single_vehicle: filterArr[0],
      };

    case UPDATE_COLUMN_BID_VALUE:
      console.log(action.payload, 'UPDATE_COLUMN_BID_VALUE');

      const modifiedArr2 = state.buying_page.vehicle_list_data.map((obj, i) =>
        obj._id.toString() == action.payload.data._id.toString()
          ? {
              ...action.payload.data,
              Price_Adjusted:
                state?.buying_page?.vehicle_list_data[i]?.Price_Adjusted,
              dealer_id: state?.buying_page?.vehicle_list_data[i]?.dealer_id,
              report_condition:
                state?.buying_page?.vehicle_list_data[i]?.report_condition,
            }
          : obj
      );
      //Filter array
      const filterArr2 = [];
      state.buying_page.vehicle_list_data.map(
        (obj, i) =>
          obj._id.toString() == action.payload.data._id.toString() &&
          filterArr2?.push({
            ...action.payload.data,
            Price_Adjusted:
              state?.buying_page?.vehicle_list_data[i]?.Price_Adjusted,
            dealer_id: state?.buying_page?.vehicle_list_data[i]?.dealer_id,
            report_condition:
              state?.buying_page?.vehicle_list_data[i]?.report_condition,
          })
      );

      state.buying_page.vehicle_list_data = modifiedArr2;
      const finalArr2 = state.buying_page;

      return {
        ...state,
        buying_page: finalArr2,
        filter_single_vehicle: filterArr[0],
      };

    case UPDATE_PRICE_VALUE:
      console.log(action.payload.data, 'payloadData');

      const modifiedArr1 = state.buying_page.vehicle_list_data.map((obj, i) =>
        obj._id.toString() == action.payload.data._id.toString()
          ? {
              ...action.payload.data,
              dealer_id: state?.buying_page?.vehicle_list_data[i]?.dealer_id,
            }
          : obj
      );

      state.buying_page.vehicle_list_data = modifiedArr1;
      const finalArr1 = state.buying_page;

      return {
        ...state,
        vehicle_list: finalArr1,
      };

    case BUYING_DROP_LIST:
      console.log(action.payload, 'BUYING_DROP_LIST');
      return {
        ...state,
        buying_drop: action.payload,
      };
    case BUYING_PAGINATION:
      console.log(action.payload, 'BUYING_PAGINATION');
      return {
        ...state,
        buying_page: action.payload,
      };

    default:
      return state;
  }
};

export default VehiclesReducer;
