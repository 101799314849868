import { toast } from 'react-toastify';
import API from '../../Service/Api';
import { TRANSPORT_LIST } from '../Constant';

//admin
export const transport_listData = () => async (dispatch) => {
  try {
    var transportdata = await API.get('/Getvehicellist');

    if (transportdata.data.status == 200) {
      dispatch({
        type: TRANSPORT_LIST,
        payload: transportdata.data.data,
      });
    } else {
      toast.warning(transportdata.data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const updatetransportdetail = (tranportdata) => async (dispatch) => {
  try {
    var tranport_data = await API.post('/Updatetransportdetail', tranportdata);
    console.log(tranport_data);
    if (tranport_data.data.status == 200) {
      //toast.success(tranport_data.data.message);
      // dispatch({
      //   type: TRANSPORT_LIST,
      //   payload: transportdata.data.data,
      // });
    } else {
      toast.warning(tranport_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
