import React from 'react'
import Master from '../Include/Master'

export default function Invoice() {
  return (
   <Master>
    
   </Master>
  )
}
