import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Getvehicellist } from '../../Redux/Action/VehilceAction';
import Master from '../Include/Master';
import { TRANSPORT_LIST } from '../../Redux/Constant';
import { Document, Page } from 'react-pdf';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import MoonLoader from 'react-spinners/MoonLoader';
import {
  transport_listData,
  updatetransportdetail,
} from '../../Redux/Action/TransportAction';
import DataTable from 'react-data-table-component';
import { company_listdata } from '../../Redux/Action/CompanyAction';
import { Get_dealer_list } from '../../Redux/Action/DealerAction';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Moment from 'react-moment';

export default function Transports() {
  const dispatch = useDispatch();
  const [stateTransport, setStateTransport] = useState([]);
  const transport_listData2 = useSelector(
    (state) => state.transport.Transport_list
  );

  useEffect(() => {
    dispatch(transport_listData());
  }, []);

  const vehicle_list_data = useSelector(
    (state) => state.transport.Transport_list
  );
  useEffect(() => {
    dispatch(company_listdata());
  }, []);

  const company_list_data = useSelector((state) => state.company.company_list);

  useEffect(() => {
    dispatch(Get_dealer_list());
  }, []);

  const dealer_list_data = useSelector(
    (state) => state.dealerreducer.dealer_list
  );

  const set_mileage_datta = (e, id) => {
    let formData = new FormData();
    formData.append('mileage', e.target.value);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };
  const set_bought_datta = (e, id) => {
    let formData = new FormData();
    formData.append('bought_price', e.target.value);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };

  const [v5show, setShowv5] = useState(false);

  const handleClosev5 = () => setShowv5(false);
  const [imagname, Setimagname] = useState('');
  const handleShowv5 = (imagname) => {
    Setimagname(imagname);
    setShowv5(true);
  };

  const onchangfilev5 = (e, id) => {
    let formData = new FormData();
    formData.append('v5', e.target.files[0]);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };
  const onchangfileservicehistory = (e, id) => {
    let formData = new FormData();
    formData.append('service_history', e.target.files[0]);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };
  const onchangfilepurches = (e, id) => {
    let formData = new FormData();
    formData.append('purches_invoice', e.target.files[0]);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };
  const set_grade_datta = (e, id) => {
    let formData = new FormData();
    formData.append('grade', e.target.value);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };
  const set_area1_datta = (e, id) => {
    let formData = new FormData();
    formData.append('area1', e.target.value);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };
  const set_area2_datta = (e, id) => {
    let formData = new FormData();
    formData.append('area2', e.target.value);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };
  const set_buynow_datta = (e, id) => {
    let formData = new FormData();
    formData.append('buy_now_price', e.target.value);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };
  const set_sold_datta = (e, id) => {
    let formData = new FormData();
    formData.append('sold_price', e.target.value);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };
  const set_companyname_datta = (e, id) => {
    let formData = new FormData();
    formData.append('transport_company', e.target.value);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };
  const set_collection_date_datta = (e, id) => {
    let formData = new FormData();
    formData.append('collection_date', e.target.value);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };
  const set_delivery_date_datta = (e, id) => {
    let formData = new FormData();
    formData.append('delivery_date', e.target.value);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };
  const set_delivery_status_datta = (e, id) => {
    let formData = new FormData();
    formData.append('delivery_status', e.target.value);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };
  const set_delivery_notes_datta = (e, id) => {
    let formData = new FormData();
    formData.append('delivery_notes', e.target.value);
    formData.append('id', id);
    dispatch(updatetransportdetail(formData));
    dispatch(transport_listData());
  };

  console.log(vehicle_list_data, 'transportData--');

  var top100Films = [];
  {
    dealer_list_data?.map(function (purchasing_dealer) {
      top100Films.push({
        label: purchasing_dealer.company_name,
      });
    });
  }
  const override: CSSProperties = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };
  let [color, setColor] = useState('#42a033');
  let [loading, setLoading] = useState(true);
  const columns = [
    {
      id: 1,
      name: 'Date Bought',
      selector: (row) => (
        <b>{<Moment format='DD/MM/YYYY'>{row.createdAt}</Moment>} </b>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 2,
      name: 'Selling Dealer',
      selector: (row) => <b>{row?.purches_dealer?.company_name} </b>,

      sortable: false,
      reorder: true,
    },
    {
      id: 3,
      name: 'Location',
      selector: (row) => <b>{row?.purches_dealer?.town_city} </b>,

      sortable: false,
      reorder: true,
    },
    {
      id: 4,
      name: 'Postcode',
      selector: (row) => <b>{row?.purches_dealer?.post_code} </b>,

      sortable: false,
      reorder: true,
    },
    {
      id: 5,
      name: 'image',
      selector: (row) => (
        <b>
          {' '}
          <img
            src={process.env.REACT_APP_IMAGE_URL + '/' + row?.vehicle_id?.image}
            height='40'
            alt=''
          />{' '}
        </b>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 6,
      name: 'Make',
      selector: (row) => <b>{row?.vehicle_id?.make} </b>,

      sortable: false,
      reorder: true,
    },
    {
      id: 7,
      name: 'Model',
      selector: (row) => <b>{row?.vehicle_id?.model} </b>,

      sortable: false,
      reorder: true,
    },
    {
      id: 8,
      name: 'Grade',
      selector: (row) => (
        <b>
          {' '}
          <select
            name=''
            className='form-select border-none'
            id=''
            onChange={(e) => set_grade_datta(e, row._id)}
            value={
              row.grade != '' && row.grade != null && row.grade != 'null'
                ? row.grade
                : ''
            }
          >
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
          </select>{' '}
        </b>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 7,
      name: 'Registration (VRM)',
      selector: (row) => <b>{row?.vehicle_id?.vrm} </b>,

      sortable: false,
      reorder: true,
    },
    {
      id: 8,
      name: 'Mileage',
      selector: (row) => (
        <div className='input-group wbg_table'>
          <div className='input-group-prepend'>
            {/* <span className='input-group-text btn-transparent px-0' id=''>
              £
            </span> */}
          </div>
          <input
            type='text'
            className='form-control px-0 py-0 btn-transparent'
            placeholder='-----'
            aria-label='Username'
            aria-describedby='basic-addon1'
            name='mileage'
            // value={row?.mileage}
            onChange={(e) => set_mileage_datta(e, row._id)}
            value={
              row.mileage != '' && row.mileage != null && row.mileage != 'null'
                ? row.mileage
                : ''
            }
          />
        </div>
      ),
      sortable: false,
      reorder: true,
    },
    {
      id: 9,
      name: 'Bought Price',
      selector: (row) => (
        <div className='input-group wbg_table'>
          <div className='input-group-prepend'>
            <span className='input-group-text btn-transparent px-0' id=''>
              £
            </span>
          </div>
          <input
            type='text'
            className='form-control px-0 py-0 btn-transparent'
            placeholder='-----'
            aria-label='Username'
            aria-describedby='basic-addon1'
            name='boughtprice'
            // value={row?.mileage}
            onChange={(e) => set_bought_datta(e, row._id)}
            value={
              row.bought_price != '' &&
              row.bought_price != null &&
              row.bought_price != 'null'
                ? row.bought_price
                : ''
            }
          />
        </div>
      ),
      sortable: false,
      reorder: true,
    },
    {
      id: 10,
      name: 'V5',
      selector: (row) => (
        <b>
          {' '}
          {row.v5_present != null && row.v5_present != '' ? (
            <button
              className='btn pending_genr'
              onClick={() => handleShowv5(row.v5_present)}
            >
              View
            </button>
          ) : (
            <input
              type='file'
              classNameName='file-upload-v5'
              onChange={(e) => onchangfilev5(e, row._id)}
            />
          )}{' '}
        </b>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 11,
      name: 'Service History',
      selector: (row) => (
        <b>
          {' '}
          {row.service_history != null && row.service_history != '' ? (
            <button
              className='btn pending_genr'
              onClick={() => handleShowv5(row.service_history)}
            >
              View
            </button>
          ) : (
            <input
              type='file'
              classNameName='file-upload-v5'
              onChange={(e) => onchangfileservicehistory(e, row._id)}
            />
          )}{' '}
        </b>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 12,
      name: ' Purchase Invoice',
      selector: (row) => (
        <b>
          {' '}
          {row.purches_invoice != null && row.purches_invoice != '' ? (
            <button
              className='btn pending_genr'
              onClick={() => handleShowv5(row.purches_invoice)}
            >
              View
            </button>
          ) : (
            <input
              type='file'
              classNameName='file-upload-v5'
              onChange={(e) => onchangfilepurches(e, row._id)}
            />
          )}{' '}
        </b>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 13,
      name: ' Area(1)',
      selector: (row) => (
        <select
          name=''
          className='form-select'
          id=''
          onChange={(e) => set_area1_datta(e, row._id)}
          value={
            row.area1 != '' && row.area1 != null && row.area1 != 'null'
              ? row.area1
              : ''
          }
        >
          <option value='1'>1</option>
          <option value='2'>2</option>
          <option value='3'>3</option>
          <option value='4'>4</option>
          <option value='5'>5</option>
          <option value='6'>6</option>
        </select>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 13,
      name: ' Area(2)',
      selector: (row) => (
        <select
          name=''
          className='form-select'
          id=''
          onChange={(e) => set_area2_datta(e, row._id)}
          value={
            row.area2 != '' && row.area2 != null && row.area2 != 'null'
              ? row.area2
              : ''
          }
        >
          <option value='1'>1</option>
          <option value='2'>2</option>
          <option value='3'>3</option>
          <option value='4'>4</option>
          <option value='5'>5</option>
          <option value='6'>6</option>
        </select>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 14,
      name: 'Buy Now Price',
      selector: (row) => (
        <div className='input-group wbg_table'>
          <div className='input-group-prepend'>
            <span className='input-group-text btn-transparent px-0' id=''>
              £
            </span>
          </div>
          <input
            type='text'
            className='form-control px-0 py-0 btn-transparent'
            placeholder='-----'
            aria-label='Username'
            aria-describedby='basic-addon1'
            onChange={(e) => set_buynow_datta(e, row._id)}
            value={
              row.buy_now_price != '' &&
              row.buy_now_price != null &&
              row.buy_now_price != 'null'
                ? row.buy_now_price
                : ''
            }
          />
        </div>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 15,
      name: 'CAP Live',
      selector: (row) => <b>{Number(row?.cap_live).toFixed(2)} </b>,

      sortable: false,
      reorder: true,
    },
    {
      id: 16,
      name: 'CAP Clean',
      selector: (row) => <b>{Number(row?.cap_clean).toFixed(2)} </b>,

      sortable: false,
      reorder: true,
    },
    {
      id: 17,
      name: 'Purchasing Dealer',
      selector: (row) => (
        // <select name='' className='form-select' id=''>
        //   <option value='Collected'>select</option>
        //   {dealer_list_data.map(function (purchasing_dealer) {
        //     return (
        //       <option value={purchasing_dealer._id}>
        //         {purchasing_dealer.company_name}
        //       </option>
        //     );
        //   })}
        // </select>

        <Autocomplete
          className='input-c-auto-trans'
          disablePortal
          id='combo-box-demo'
          options={top100Films}
          sx={{ width: 300, height: 20 }}
          renderInput={(params) => (
            <TextField {...params} label='Please select' />
          )}
        />
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 18,
      name: 'Sold Price',
      selector: (row) => (
        <div className='input-group wbg_table'>
          <div className='input-group-prepend'>
            <span className='input-group-text btn-transparent px-0' id=''>
              £
            </span>
          </div>
          <input
            type='text'
            className='form-control px-0 py-0 btn-transparent'
            //placeholder='-----'
            // aria-label='Username'
            //aria-describedby='basic-addon1'
            onChange={(e) => set_sold_datta(e, row._id)}
            value={
              row.sold_price != '' &&
              row.sold_price != null &&
              row.sold_price != 'null'
                ? row.sold_price
                : ''
            }
          />
        </div>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 19,
      name: 'Sales Invoice Sent',
      selector: (row) => <b>28/08/2022 </b>,

      sortable: false,
      reorder: true,
    },
    {
      id: 20,
      name: 'Transport Company',
      selector: (row) => (
        <select
          name=''
          className='form-select'
          id=''
          onChange={(e) => set_companyname_datta(e, row._id)}
          value={
            row?.transport_company?._id != '' &&
            row?.transport_company?._id != null &&
            row?.transport_company?._id != 'null'
              ? row?.transport_company?._id
              : ''
          }
        >
          <option value=''>select</option>
          {company_list_data.map(function (company_obj) {
            return (
              <option value={company_obj._id}>
                {company_obj.company_name}
              </option>
            );
          })}
        </select>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 20,
      name: 'Collection Date',
      selector: (row) => (
        <b>
          <input
            type='date'
            onChange={(e) => set_collection_date_datta(e, row._id)}
            value={
              row.collection_date != '' &&
              row.collection_date != null &&
              row.collection_date != 'null'
                ? row.collection_date
                : ''
            }
          />{' '}
        </b>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 21,
      name: 'Delivery Date',
      selector: (row) => (
        <b>
          {' '}
          <input
            type='date'
            onChange={(e) => set_delivery_date_datta(e, row._id)}
            value={
              row.delivery_date != '' &&
              row.delivery_date != null &&
              row.delivery_date != 'null'
                ? row.delivery_date
                : ''
            }
          />{' '}
        </b>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 22,
      name: 'Delivery status',
      selector: (row) => (
        <select
          name=''
          className='form-select'
          id=''
          onChange={(e) => set_delivery_status_datta(e, row._id)}
          value={
            row.delivery_status != '' &&
            row.delivery_status != null &&
            row.delivery_status != 'null'
              ? row.delivery_status
              : ''
          }
        >
          <option value='' selected>
            Select
          </option>
          <option value='Collection Arranged'>Collection Arranged</option>
          <option value='Collected'>Collected</option>
          <option value='Delivery Arranged'>Delivery Arranged</option>
          <option value='Delivery Arranged'>Delivery Arranged</option>
        </select>
      ),

      sortable: false,
      reorder: true,
    },
    {
      id: 22,
      name: 'Delivery Notes',
      selector: (row) => (
        <span className='inline_text '>
          <input
            type='text'
            className='input_new'
            placeholder='Type here'
            value={
              row.delivery_notes != '' &&
              row.delivery_notes != null &&
              row.delivery_notes != 'null'
                ? row.delivery_notes
                : ''
            }
            onChange={(e) => set_delivery_notes_datta(e, row._id)}
          />
        </span>
      ),

      sortable: false,
      reorder: true,
    },
  ];

  return (
    <Master>
      {/* <!-- start page title --> */}
      <div className='row'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <div>
              <span className='small_text'>YoTrader </span>
              <h4 className='mb-sm-0 font-size-28'>
                Transport{' '}
                <span className='blue_text'>{vehicle_list_data.length}</span>
              </h4>
            </div>

            <div className='page-title-right'>
              {/* <!-- App Search--> */}
              <form className='app-search d-none d-lg-block '>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='position-relative w-100'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Search'
                    />
                    <span className='bx bx-search'></span>
                  </div>
                  <img
                    src={require('../assets/images/avatar.svg').default}
                    className='avata_search'
                    alt=''
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end page title --> */}

      <div className='row flex-colum flex-md  navbar-expend'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  {/* <h4 className='title_text'>Transport</h4> */}
                </div>

                <DataTable
                  columns={columns}
                  data={
                    vehicle_list_data?.length > 0
                      ? vehicle_list_data
                      : [
                          {
                            'Date Bought': '',
                            'Selling Dealer': '',
                            Location: '',
                            Postcode: '',
                            image: '',
                            Make: '',
                            Model: '',
                            Grade: '',
                            'Registration (VRM)': '',
                            Mileage: '',
                            'Bought Price': '',
                            V5: '',
                            'Service History': '',
                            ' Purchase Invoice': '',
                            ' Area(1)': '',
                            ' Area(2)': '',
                            'Buy Now Price': '',
                            'CAP Live': '',
                            'CAP Clean': '',
                            'Purchasing Dealer': '',
                            'Sold Price': '',
                            'Sales Invoice Sent': '',
                            'Transport Company': '',
                            'Collection Date': '',
                            'Delivery Date': '',
                            'Delivery status': '',
                            'Delivery Notes': '',
                          },
                        ]
                  }
                  pagination
                />
                {vehicle_list_data.length > 0 ? (
                  ''
                ) : (
                  <div className='sweet-loading'>
                    <MoonLoader
                      color={color}
                      loading={loading}
                      cssOverride={override}
                      // size={150}
                      aria-label='Loading Spinner'
                      data-testid='loader'
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={v5show} onHide={handleClosev5}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={process.env.REACT_APP_IMAGE_URL + '/transport/' + imagname}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClosev5}>
            Close
          </Button>
          <Button variant='primary' onClick={handleClosev5}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Master>
  );
}
