import { combineReducers } from 'redux';
import Account from './Reducer/Accountreducer';

// import superadminReducer from './Reducer/superAdminReducer';
import userSigninReducer from './Reducer/Authreducer';
import Company from './Reducer/Company';
import Transport from './Reducer/Transport';
import dealer_reducer from './Reducer/Dealerreducer';
import VehiclesReducer from './Reducer/VehiclesReducer';

import Archivereducer from './Reducer/Archivereducer';
import DealerVehiclesReducer from './Reducer/DealerVehiclesReducer';
import LiveBidsreducer from './Reducer/LiveBidsreducer';
import Expiredreducer from './Reducer/Expiredreducer';
import Acceptedofferreducer from './Reducer/Acceptedofferreducer';
const reducer = combineReducers({

  userSigninReducer: userSigninReducer,
  dealerreducer: dealer_reducer,
  vehiclereducer: VehiclesReducer,
  Accountreducer: Account,
  company: Company,
  transport: Transport,
  archive:Archivereducer,
  dealervehicleslist:DealerVehiclesReducer,
  liveBidsreducer:LiveBidsreducer,
  expiredreducer:Expiredreducer,
  Acceptedofferreducer:Acceptedofferreducer
});
export default reducer;
