import { END_AUCTION_DETAILS, EXPIRE_BIDS, SELLER_ENDED_AUCTION } from "../Constant";


const initialValues={
    expierbidslist:[],
    endedauctions:[],
    endauctiondetails:""
}

const Expiredreducer=(state=initialValues,action)=>{
    switch(action.type){
        case EXPIRE_BIDS:
        return({
            ...state,
            expierbidslist:action.payload

        })

        case SELLER_ENDED_AUCTION:
            return({
                ...state,
                endedauctions:action.payload
    
            })

            case END_AUCTION_DETAILS:
                return({
                    ...state,
                    endauctiondetails:action.payload
        
                })
            
        
      
     default:
     return state;

}

}

export default Expiredreducer