import { DEALER_LIVE_BIDS, DEALER_VEHICLES_LIST } from "../Constant"


var initialValues={
dealer_vehicles_list:[],
dealer_live_bids:[],
}

const DealerVehiclesReducer=(state=initialValues,action)=>{
    switch(action.type){
            case DEALER_VEHICLES_LIST:
            return({
                ...state,
                dealer_vehicles_list:action.payload

            })
            case DEALER_LIVE_BIDS:
                return({
                    ...state,
                    dealer_live_bids:action.payload
    
                })
         default:
         return state;

    }
}
export default DealerVehiclesReducer