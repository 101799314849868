import moment from 'moment';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom'

import Master from '../Include/Master'
import { sellerAcceptedoffersAction } from '../../Redux/Action/AcceptedOfferAction';

export default function OfferAccepted() {

    const dealer_vehicle_id=useParams();

    
    const dispatch=useDispatch();
    useEffect(()=>{
        dispatch(sellerAcceptedoffersAction({
            dealer_id:dealer_vehicle_id.id
        }))
    },[])



    const acceptedoffer=useSelector((state)=>state.Acceptedofferreducer.seller_acceptedoffer);




  return (
    <Master>
          {/* start page title */}
          <div className='row'>
          <div className='col-12'>
            <div className='page-title-head d-sm-flex align-items-center justify-content-between'>
              <div className='page-title-head-left d-sm-flex align-items-center justify-content-between'>
                <NavLink to='/admin/dealers'>
                  {' '}
                  <img
                    src={require('../assets/images/Expand_left.svg').default}
                    alt=''
                    className='back_btn'
                  />
                </NavLink>
                <h4 className='mb-sm-0 font-size-34 blue-color fw-700'>
                  {acceptedoffer?.dealerDetails?.company_name}
                </h4>
              </div>

              <div className='page-title-right'>
                <p className='mb-0 blue-color font-size-14 fw-700'>
                  Joined: {moment(acceptedoffer?.dealerDetails?.createdAt).format('DD/MM/Y')}
                </p>
              </div>
            </div>
          </div>
        </div>
      {/* end page title  */}

         <div className='row'>
            <div className='col-xl-12'>
            <div className='tabs-btn-right'>
              <ul>
                <li>
                  <NavLink to={`/admin/dealer_detail/`+acceptedoffer?.dealerDetails?._id} className="white-anchor-right">Overview</NavLink>
                </li>
                <li>
                  <NavLink to={'/admin/dealer_vehicles/'+ dealer_vehicle_id.id}  className="white-anchor-right">Vehicles</NavLink>
                </li>
                <li>
                  <NavLink to={'/admin/dealer_live_bids/'+ dealer_vehicle_id?.id} className="white-anchor-right">Live Auctions</NavLink>
                </li>
                <li>
                <NavLink to={'/admin/dealer_expire_bids/'+ dealer_vehicle_id?.id} className="white-anchor-right">Ended Auctions</NavLink>                
                </li>
                
                <li>
                <NavLink to={'/admin/offer_accepted/'+ dealer_vehicle_id?.id} className="blue-anchor-right">Sold Vehicles</NavLink>
                </li>
                <li>
                <NavLink to={'/admin/purchases/'+dealer_vehicle_id?.id} className="white-anchor-right">Purchases</NavLink>
                </li>  
                <li>
                <NavLink to={'/admin/invoice/'+dealer_vehicle_id?.id} className="white-anchor-right">Invoices</NavLink>
                </li>               
              </ul>
            </div>
             
            </div>
          </div>

      <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <h4 className='title_text'>Sold Vehicles { acceptedoffer?.Acceptedoffers?.length}</h4>
             
                </div>

                <div className='table-responsive dealer-table-c table-scroll'>
                  <table
                    id=''
                    className='table dt-responsive vehicles_table nowrap w-100'
                  >
                    <thead>
                      <tr>
                       
                        <th>Main Image</th>
                        <th>Registration</th>
                        <th>Mileage</th>
                        <th>Make</th>
                        <th>Model </th>
                        <th>Stock Age</th>
                        <th>Retail Price</th>
                        <th>Winning Bid</th>
                        <th>Winning Bidder</th>
                        <th>Sold</th>
                        <th>Selling fee paid</th>
                       
                      </tr>
                    </thead>
                    <tbody className='td_color sticky-header-c table-scroll'>
                
                    {

                      acceptedoffer?.Acceptedoffers?.length==0?<tr className='placholder-text-height'>
                      <td>
                      <p className='placeholdet-text-table'>No data found</p>
                      </td>
                     </tr>:



                      acceptedoffer?.Acceptedoffers?.map(function(acceptedoffer,i){
                        return(
                          <tr key={i}>
                          <td>
                          <img
                            src={acceptedoffer.images[2]}
                            height='40'
                            alt=''
                          />
                        </td>
                        <td>{acceptedoffer.vrm}</td>
                        <td>{   new Intl.NumberFormat('en-GB').format(acceptedoffer.mileage).replace(/\.00$/, '') }</td>
                        <td>{acceptedoffer.make}</td>
                        <td>{acceptedoffer.model}</td>
                        <td>2</td>

                
                        
                        <td>{   new Intl.NumberFormat('en-GB',{   style: 'currency',  currency: 'GBP',}).format(acceptedoffer?.vehicle_id?.price).replace(/\.00$/, '') }</td>
                        <td>{   new Intl.NumberFormat('en-GB',{   style: 'currency',  currency: 'GBP',}).format(acceptedoffer?.buyvehicles?.saleprice).replace(/\.00$/, '') }</td>

                       
                        <td>{acceptedoffer.buyvehicles.buyperdetails.company_name}</td>
                       

                      

                        <td>{acceptedoffer.buyvehicles.delivered_saler==0?"No":"Yes"}</td>
                        <td>{acceptedoffer.buyvehicles.delivered_buyer==0?"No":"Yes"}</td>
                  

                          </tr>
                        )
                      })
                    }
               
                    </tbody>
                  
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  )
}
