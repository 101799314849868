import React, { useEffect, useState } from 'react';
import Master from '../Include/Master';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import { AddAdminAuction, GetAdminlistAuction, UpdatepermissionAuction } from '../../Redux/Action/ManageAdminAuction';
export default function Manage_admin() {
  const [show, setShow] = useState(false);
  const [password_text, Setpassword] = useState('password');
  const [iduser, Setiduser] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState('');
      const dispatch=useDispatch();
      const [preview, setPreview] = useState("")
      const [password, setpassword] = useState("")

    
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      role: 0,
      permission: 0,
      profileimage: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email().required('Email is required'),
      password: Yup.string().required('Password is required'),
      role: Yup.string().required('Role is required'),
      permission: Yup.string().required('Permission is required'),
      profileimage: Yup.string().required('File is required'),
    }),
    onSubmit: (values) => {
     
    let formdata=new FormData();
    formdata.append('name',values.name)
    formdata.append('email',values.email)
    formdata.append('password',values.password)
    formdata.append('role',values.role)
    formdata.append('permission',values.permission)
    formdata.append('profileimage',values.profileimage)
    
    formdata.append('iduser',iduser)
    setPreview("")

      dispatch(AddAdminAuction(formdata)).then(function(){
        dispatch(GetAdminlistAuction())
        setShow(false)
      })
      
    },
  });

  useEffect(()=>{
    dispatch(GetAdminlistAuction())
  },[])
  const adminlist=useSelector((state)=>state.Accountreducer.adminlist);

  const filter = (e) => {
    const keyword = e;
  
    setName(keyword);
    dispatch(GetAdminlistAuction({
      search:keyword,
      
    }))
  };
  
  const keyPress=(e)=>{
  if (e.key === "Enter") {
    filter(name);
  }
  }


console.log(formik.errors)

  return (
    <Master>
      {/* <!-- start page title --> */}
      <div className='row'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <div>
              <span className='small_text'>YoTrader</span>
              <h4 className='mb-sm-0 font-size-28'>
                Manage Admin<span className='blue_text'></span>
              </h4>
            </div>

            <div className='page-title-right'>
              {/* <!-- App Search--> */}
              <div className='app-search d-none d-lg-block '>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='position-relative w-100'>
                  <input
                      className='form-control'
                      placeholder='Search'
                      type='search'
                      value={name}
                        onChange={(e)=>{
                          setName(e.target.value)
                        }}
                      onKeyDown={keyPress}
                    />
                    <span className='bx bx-search'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end page title --> */}

      <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <h4 className='title_text blue-color'>Administrators</h4>
                  <span>
                    <button className='btn  cre_new' onClick={()=>{

                    handleShow() ;
                    formik.setFieldValue('name',"")
                        formik.setFieldValue('email',"")
                        formik.setFieldValue('password',"")
                        formik.setFieldValue('role',0)
                        formik.setFieldValue('permission',0)
                        formik.setFieldValue('profileimage',"")
                      setpassword("")}}>Create New</button>
                  </span>
                </div> 
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title className='popu-heading-style'>Manage Admin</Modal.Title>
                  </Modal.Header>
                  <Form   onSubmit={formik.handleSubmit}>
                  <Modal.Body>
                  
                  <div class="form-group mb-3"  style={{ display:password }}>
                  <div className='upload-img-profile-popup'>
                  <div className='border-dashed-part'>
                  <img src={preview !=""?preview: require('../assets/images/User-cicrle-placeholder.png')}  alt='' />
                  </div>
                  <input type="file" onChange={(e)=>{
                  


                    var objectUrl = URL.createObjectURL(e.target.files[0])

                      setPreview(objectUrl)

                    formik.setFieldValue('profileimage',e.target.files[0])
                  }} 

               
                            name='profileimage'
                           


                  />                  
                 
                  
                  <input type="file" />                  
                  <div className='img-upload-text'>Image Upload</div>
                  </div>

                  {formik.errors.profileimage ? (
                          <p className='error'>{formik.errors.profileimage}</p>
                        ) : null}
                 
                  </div>

                  

                  <div class="form-group mb-3">
                  <label for="exampleInputEmail1">Name</label>
                  <input type="name" class="form-control input-style" id="" placeholder="Enter name"  onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name='name'
                            value={formik.values.name}
                  
                   />
                    {formik.errors.name ? (
                          <p className='error'>{formik.errors.name}</p>
                        ) : null}
                  </div>

                  <div class="form-group mb-3">
                  <label for="exampleInputEmail1">Email</label>
                  <input type="email" class="form-control input-style" id="" placeholder="Enter email"
                    onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name='email'
                            value={formik.values.email}
                  
                   />
                    {formik.errors.email ? (
                          <p className='error'>{formik.errors.email}</p>
                        ) : null}
                  </div>

                  <div className='mb-3' style={{ display:password }}>
                        <label className='form-label'>
                          Password<span className='blue_text'>*</span>{' '}
                        </label>
                        <div className='input-group auth-pass-inputgroup'>
                          <input
                            type={password_text}
                            className='form-control input-style'
                            placeholder='Min.8 characters '
                            aria-label='Password'
                            aria-describedby='password-addon'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name='password'
                            value={formik.values.password}
                          />
                          <button
                            className='btn btn-eye-popup   input-style'
                            type='button'
                            id='password-addon'
                            onClick={() => Setpassword(password_text == 'input' ? 'password' : 'input')}
                          >
                            <i className='mdi mdi-eye-outline'></i>
                          </button>
                        </div>
                        {formik.errors.password ? (
                          <p className='error'>{formik.errors.password}</p>
                        ) : null}
                      </div>

                  <div class="radio-buttons mb-4">
                  <div className='form-label-text'>Role</div>
                  <div className='flex-radio'>
                  <div class="form-group">
                    <input type="radio" id="superadmin" 
                         onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name='role'
                            value="0"
                        checked=   {formik.values.role==0}
                  
                   />
                    <label for="superadmin">Super Admin</label>
                  </div>

                  <div class="form-group">
                    <input type="radio" id="admin" 
                        name="role"  value="1"   onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked=   {formik.values.role==1}
                   />
                    <label for="admin">Admin</label>
                  </div> 
                  </div>  
               
                    {formik.errors.role ? (
                          <p className='error'>{formik.errors.role}</p>
                        ) : null}                            
                </div>

                <div class="radio-buttons">
                  <div className='form-label-text'>Permissions</div>
                  <div className='flex-radio'>
                  <div class="form-group">
                    <input type="radio" id="canedit" name="permission"  value="0"   onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked=   {formik.values.permission==0} />
                    <label for="canedit">Can Edit</label>
                  </div>

                  <div class="form-group">
                    <input type="radio" id="viewonly" name="permission"  value="1"   onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            checked= {formik.values.permission==1} />
                    <label for="viewonly">View Only</label>
                  </div> 
                  </div>   

                    {formik.errors.permission ? (
                          <p className='error'>{formik.errors.permission}</p>
                        ) : null}      


                </div>

               
                  
                  </Modal.Body>
                  <Modal.Footer className='popup-fotter-style'>                  
                    <Button className='blue-btn-fill-style'  type='submit'>
                      Submit
                    </Button>
                  </Modal.Footer>
                  </Form>
                </Modal>

                <div className='table-responsive dealer-table-c table-scroll'>
                  <table className='table dt-responsive vehicles_table nowrap w-100'>
                    <thead className='block-none-table'>
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Permissions</th>
                        <th>Email</th>
                        <th>Edit</th>
                      </tr>
                    </thead>

                    <tbody className='td_color sticky-header-c tbody-height-fix table-scroll scroll-bottom-none'>
                    {

                      adminlist?.length==0?<tr className='placholder-text-height'>
                      <td>
                      <p className='placeholdet-text-table'>No data found</p>
                      </td>
                     </tr>:


                      adminlist?.map(function(admin_object,i){
                        return(
                          <tr key={i}>
                        <td >
                         
                          <img
                            src={process.env.REACT_APP_IMAGE_URL+"/admin/"+admin_object.profileimage}
                            alt=''     
                            height="50"
                            width= "50"         
                          />

                        </td>
                        <td>{admin_object.name}</td>
                        <td> {admin_object.role==0?"Super Admin":"Admin"}   </td>
                        <td>
                          <select name='permission' onClick={(e)=>{
                            UpdatepermissionAuction({
                              id:admin_object._id,
                              permission:e.target.value

                            })
                          }} id='' className='form-select'>
                            <option value='0' selected={admin_object.permission==0?true:""}  onClick={(e)=>{
                            UpdatepermissionAuction({
                              id:admin_object._id,
                              permission:0

                            })
                          }}>Can Edit</option>
                            <option value='1' selected={admin_object.permission==1?true:""}  onClick={(e)=>{
                            UpdatepermissionAuction({
                              id:admin_object._id,
                              permission:1

                            })
                          }}>View Only</option>
                          </select>
                        </td>
                        <td>{admin_object.email}</td>

                        <td onClick={()=>{
                          handleShow()


                          formik.setFieldValue('name',admin_object.name)
                        formik.setFieldValue('email',admin_object.email)
                        formik.setFieldValue('password',admin_object.password)
                        formik.setFieldValue('role',admin_object.role)
                        formik.setFieldValue('permission',admin_object.permission)
                        formik.setFieldValue('profileimage',admin_object.profileimage)
                        Setiduser(admin_object._id)

                        setpassword("none")

                      
                          
                          
                          }}>
                          <img
                            src={require('../assets/images/Edit.svg').default}
                            alt=''
                          />
                        </td>
                      </tr>
                        )
                      })
                    }                                  
                   
            
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}
