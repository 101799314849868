import React from 'react'
import ReactPaginate from 'react-paginate'

export default function Pagination({totalpage,pagiantiondata}) {
const handlePageClick=(page_no)=>{
    pagiantiondata(page_no?.selected + 1)
}

  return (
    <ReactPaginate
    previousLabel={'Previous'}
    nextLabel={'Next'}
    breakLabel={'...'}
    pageCount={ totalpage }
    marginPagesDisplayed={2}
    pageRangeDisplayed={3}
    onPageChange={handlePageClick}
    containerClassName={'pagination justify-content-center'}
    pageClassName={'page-item'}
    pageLinkClassName={'page-link'}
    previousClassName={'page-item'}
    previousLinkClassName={'page-link'}
    nextClassName={'page-item'}
    nextLinkClassName={'page-link'}
    breakClassName={'page-item'}
    breakLinkClassName={'page-link'}
    activeClassName={'active'}
  />
  )
}
