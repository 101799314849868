import moment from 'moment';
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom'
import { dealer_vehicles } from '../../Redux/Action/DealerVehiclesAction';
import Master from '../Include/Master'

export default function DealerVehicles() {
    const dealer_vehicle_id=useParams();

    
    const dispatch=useDispatch();
    useEffect(()=>{
        dispatch(dealer_vehicles({
            dealer_id:dealer_vehicle_id.id
        }))
    },[])

    const DealerVehicleslist=useSelector((state)=>state.dealervehicleslist.dealer_vehicles_list)
  
  return (
   <Master>
  {/* start page title */}
        <div className='row'>
          <div className='col-12'>
            <div className='page-title-head d-sm-flex align-items-center justify-content-between'>
              <div className='page-title-head-left d-sm-flex align-items-center justify-content-between'>
                <NavLink to='/admin/dealers'>
                  {' '}
                  <img
                    src={require('../assets/images/Expand_left.svg').default}
                    alt=''
                    className='back_btn'
                  />
                </NavLink>
                <h4 className='mb-sm-0 font-size-34 blue-color fw-700'>
                  {DealerVehicleslist?.dealerDetails?.company_name}
                </h4>
              </div>

              <div className='page-title-right'>
                <p className='mb-0 blue-color font-size-14 fw-700'>
                  Joined: {moment(DealerVehicleslist?.dealerDetails?.createdAt).format('DD/MM/Y')}
                </p>
              </div>
            </div>
          </div>
        </div>
      {/* end page title  */}

      <div className='row'>
            <div className='col-xl-12'>
            <div className='tabs-btn-right'>
              <ul>
                <li>
                  <NavLink to={`/admin/dealer_detail/`+DealerVehicleslist?.dealerDetails?._id} className="white-anchor-right">Overview</NavLink>
                </li>
                <li>
                  <NavLink to={'/admin/dealer_vehicles/'+ dealer_vehicle_id?.id}  className="blue-anchor-right">Vehicles</NavLink>
                </li>
                <li>
                <NavLink to={'/admin/dealer_live_bids/'+ dealer_vehicle_id?.id} className="white-anchor-right">Live Auctions</NavLink>                </li>
                <li>
                     
                <NavLink to={'/admin/dealer_expire_bids/'+ dealer_vehicle_id?.id} className="white-anchor-right">Ended Auctions</NavLink>                
                </li>
                <li>
                <NavLink to={'/admin/offer_accepted/'+ dealer_vehicle_id?.id} className="white-anchor-right">Sold Vehicles</NavLink>
                </li>
                <li>
                <NavLink to={'/admin/purchases/'+dealer_vehicle_id?.id} className="white-anchor-right">Purchases</NavLink>
                </li>  
                <li>
                <NavLink to={'/admin/invoice/'+dealer_vehicle_id?.id} className="white-anchor-right">Invoices</NavLink>
                </li>               
              </ul>
            </div>
             
            </div>
          </div>

      <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <h4 className='title_text'>All Vehicles {DealerVehicleslist?.dealer_vehicles?.length}</h4>
             
                </div>

                <div className='table-responsive dealer-table-c table-scroll'>
                  <table
                    id=''
                    className='table dt-responsive vehicles_table nowrap w-100'
                  >
                    <thead className='width-thead-table block-none-table'>
                      <tr>
                       
                        <th>Main Image</th>
                        <th>Registration (VRM)</th>
                        <th>Mileage</th>
                        <th>Make</th>
                        <th>Model </th>
                        <th>Stock Age</th>
                        <th>Retail Price</th>
                        <th>Auctions</th>
                      
                       
                      </tr>
                    </thead>

                    <tbody className='td_color sticky-header-c tbody-height-fix table-scroll scroll-bottom-none'>

                    {

                      DealerVehicleslist?.dealer_vehicles?.length==0?<tr className='placholder-text-height'>
                      <td>
                      <p className='placeholdet-text-table'>No data found</p>
                      </td>
                     </tr>:



                        DealerVehicleslist?.dealer_vehicles?.map(function(dealer_vehicle_obj,i){
                            return(
                                <tr key={i}>
                    
                        <td>
                         

                        <a href={dealer_vehicle_obj.DeepLink} target='_blank'>
                                    <img src={dealer_vehicle_obj.image[4]} height='40' alt='' />
                                </a>
                        </td>

                        <td>{dealer_vehicle_obj.vrm}</td>
                        <td>{   new Intl.NumberFormat( {style: 'currency'}).format(dealer_vehicle_obj.mileage ) .replace(/\.00$/, '')}</td>
                        <td>{dealer_vehicle_obj.make}</td>
                        <td>{dealer_vehicle_obj.model}</td>
                        <td>{dealer_vehicle_obj.stocking_age}</td> 
                        <td>{ new Intl.NumberFormat('en-GB', {
                                style: 'currency',
                                currency: 'GBP',
                                })
                                .format(dealer_vehicle_obj?.price)
                                .replace(/\.00$/, '')}</td>
                      
                        <td>   {  
                          
                          dealer_vehicle_obj?.myvehicleconditionreports_data?.length>0?
                          
                           Math.ceil(Math.abs(new Date() - new Date(dealer_vehicle_obj?.myvehicleconditionreports_data[0]?.offer_expire) ) / 36e5)==0?"Expired":"Live":"-"}</td>
                
                               
                      </tr>
                            )
                        })
                    }
              
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   </Master>
  )
}
