

import { ARCHIVE_LIST } from "../Constant"

const initialValues={
archive_list:[]
}
const Archivereducer=(state = initialValues, action)=>{
    switch(action.type){
        case ARCHIVE_LIST:
        return {
            ...state,
            archive_list:action.payload
                } 
                default:
                return state;
    }
}
export default Archivereducer