



import { toast } from "react-toastify";
import API from "../../Service/Api"
import { END_AUCTION_DETAILS, EXPIRE_BIDS, SELLER_ENDED_AUCTION } from "../Constant";


export const  ExpireBidsAuction=(data)=>async(dispatch)=>{
    try {
     const expire=  await API.post('admin/expireBidsList',data);

     if(expire.data.status==200){
        dispatch({
            type:EXPIRE_BIDS,
            payload:expire.data.data
        })

     }else{
        toast.warning(expire.data.message)
     }

    } catch (error) {
        toast.error(error.message)
    }
}


export const  SellerEndedActions=(data)=>async(dispatch)=>{
    try {
     const expire=  await API.post('admin/sellerEndedAuctions',data);

     if(expire.data.status==200){
        dispatch({
            type:SELLER_ENDED_AUCTION,
            payload:expire.data.data
        })

     }else{
        toast.warning(expire.data.message)
     }

    } catch (error) {
        toast.error(error.message)
    }
}




export const  endAuctiondetailsAuction=(id)=>async(dispatch)=>{
    try {
     const endAuctiondetails=  await API.get(`admin/endAuctiondetails?id=${id}`);

     if(endAuctiondetails.data.status==200){
        dispatch({
            type:END_AUCTION_DETAILS,
            payload:endAuctiondetails.data.data
        })

     }else{
        toast.warning(endAuctiondetails.data.message)
     }

    } catch (error) {
        toast.error(error.message)
    }
}
