import { LIVE_AUCTION_DETAILS, LIVE_BIDS } from "../Constant";

const initialValues={
    livebidslist:[],
    live_auction_details:""
}

const LiveBidsreducer=(state=initialValues,action)=>{
    switch(action.type){
        case LIVE_BIDS:
        return({
            ...state,
            livebidslist:action.payload

        })

        case LIVE_AUCTION_DETAILS:
            return({
                ...state,
                live_auction_details:action.payload
    
            })
      
        
     default:
     return state;

}

}

export default LiveBidsreducer