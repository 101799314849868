import React from 'react';

export default function Header() {
  return (
    <div className='navbar-header'>
      <div className='d-flex'>
        <div className='navbar-brand-box'>
          <a href='#' className='logo logo-light'>
            <span className='logo-sm'>
              <img
                src={require('../assets/images/logo-light.svg').default}
                alt=''
                height='12'
              />
            </span>
            <span className='logo-lg'>
              <img
                src={require('../assets/images/logo-light.svg').default}
                alt=''
                height='40'
              />
            </span>
          </a>
        </div>

        <button
          type='button'
          className='btn btn-sm px-3 font-size-16 header-item waves-effect d-none'
          id='vertical-menu-btn'
        >
          <i className='fa fa-fw fa-bars'></i>
        </button>
      </div>
    </div>
  );
}
