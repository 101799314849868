import { toast } from "react-toastify";
import API from "../../Service/Api"
import { LIVE_AUCTION_DETAILS, LIVE_BIDS } from "../Constant";

export const  LiveBidsAuction=(data)=>async(dispatch)=>{
    try {
     const livebidsdata=  await API.post('admin/livebidsList',data);

     if(livebidsdata.data.status==200){
        dispatch({
            type:LIVE_BIDS,
            payload:livebidsdata.data.data
        })

     }else{
        toast.warning(livebidsdata.data.message)
     }

    } catch (error) {
        toast.error(error.message)
    }
}

export const  liveVehiclesDetailsAuction=(id)=>async(dispatch)=>{
    try {
     const livebidsdata_details=  await API.get(`admin/liveVehiclesDetails?id=${id}`);

     if(livebidsdata_details.data.status==200){

       
        dispatch({
            type:LIVE_AUCTION_DETAILS,
            payload:livebidsdata_details.data.data
        })

     }else{
        toast.warning(livebidsdata_details.data.message)
     }

    } catch (error) {
        toast.error(error.message)
    }
}

