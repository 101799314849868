import { toast } from "react-toastify"
import API from "../../Service/Api"
import { MANAGE_ADMIN } from "../Constant"

export const AddAdminAuction=(data)=>async(dispatch)=>{
    try {
        await API.post('/admin/UserRegistration',data)
    } catch (error) {
        
    }
}


export const GetAdminlistAuction=(data)=>async(dispatch)=>{
    try {
      const Adminlist=  await API.post('/admin/GetAdminlist',data)
      if(Adminlist.data.status==200){
        dispatch({
            type:MANAGE_ADMIN,
            payload:Adminlist.data.data
        })
      }else{
        toast.warning(Adminlist.data.message)

      }
    } catch (error) {
        
    }
}

export const UpdatepermissionAuction=(data)=>async(dispatch)=>{
    try {
      const Adminlist=  await API.post('/admin/updatepermission',data)
      if(Adminlist.data.status==200){
        
        dispatch({
            type:MANAGE_ADMIN,
            payload:Adminlist.data.data
        })
      }else{
        toast.warning(Adminlist.data.message)

      }
    } catch (error) {
        
    }
}



