import { toast } from "react-toastify"
import API from "../../Service/Api"
import { DEALER_LIVE_BIDS, DEALER_VEHICLES_LIST } from "../Constant"



export const dealer_vehicles=(dealer_id)=>async(dispatch)=>{
    try {
        
      
        const VehiclesList=await API.post('/dealer_vehicles',dealer_id)
      
        if(VehiclesList.data.status==200){
            dispatch({
                type:DEALER_VEHICLES_LIST,
                payload:VehiclesList.data.data
            })
            
        }else{
            toast.error(VehiclesList.data.message)
        }

    } catch (error) {
        toast.error(error.message)
    }
}



export const DealerLivebids=(dealer_id)=>async(dispatch)=>{
    try {
        
      
        const livebids=await API.post('/DealerLivebids',dealer_id)
      
        if(livebids.data.status==200){
            dispatch({
                type:DEALER_LIVE_BIDS,
                payload:livebids.data.data
            })
            
        }else{
            toast.error(livebids.data.message)
        }

    } catch (error) {
        toast.error(error.message)
    }
}

