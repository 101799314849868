import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { admin_login } from '../../Redux/Action/LoginAction';
import { useDispatch } from 'react-redux';
export default function Login() {
  const [password_text, Setpassword] = useState('password');
  const password_hide_show = () => {
    Setpassword(password_text == 'input' ? 'password' : 'input');
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
      password: Yup.string().required(),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(admin_login(values, navigate));
    },
  });
  console.log(formik.errors);
  return (
    <div className='container-fluid p-0'>
      <div className='row g-0'>
        <div className='col-xl-6'>
          <div className='auth-full-page-content p-md-10 p-4'>
            <div className='w-100'>
              <div className='d-flex flex-column h-100'>
                <div className='my-auto'>
                  <div className='mt-4'>
                    <div className='titl_text mb-5'>
                      <h1 className='p-color font-weight-semibold'>Sign In</h1>
                      <p className='g-color fw-regular'>
                        Enter your email and password to sign in!
                      </p>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className='mb-3'>
                        <label for='username' className='form-label'>
                          Email<span className='blue_text'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='username'
                          placeholder='mail@simmmple.com'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name='email'
                          value={formik.values.email}
                        />
                        {formik.errors.email ? (
                          <p className='error'>{formik.errors.email}</p>
                        ) : null}
                      </div>

                      <div className='mb-3'>
                        <label className='form-label'>
                          Password<span className='blue_text'>*</span>{' '}
                        </label>
                        <div className='input-group auth-pass-inputgroup'>
                          <input
                            type={password_text}
                            className='form-control bright'
                            placeholder='Min.8 characters '
                            aria-label='Password'
                            aria-describedby='password-addon'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name='password'
                            value={formik.values.password}
                          />
                          <button
                            className='btn btn-light '
                            type='button'
                            id='password-addon'
                            onClick={() => password_hide_show()}
                          >
                            <i className='mdi mdi-eye-outline'></i>
                          </button>
                        </div>
                        {formik.errors.password ? (
                          <p className='error'>{formik.errors.password}</p>
                        ) : null}
                      </div>

                      <div className='d-flex justify-content-between mb-3'>
                        <div className='form-check login-check '>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='remember-check'
                          />
                          <label
                            className='form-check-label'
                            for='remember-check'
                          >
                            Keep me logged in
                          </label>
                        </div>
                        <NavLink
                          to='/admin/forgot'
                          className='font-weight-semibold'
                        >
                          Forgot password?
                        </NavLink>
                      </div>
                      <div className='mt-4 d-grid'>
                        <button
                          type='submit'
                          className='btn btn-primary waves-effect waves-light'
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end col --> */}

        <div className='col-xl-6'>
          <div className='auth-full-bg bg-black pt-lg-5 p-4'>
            <div className='bg-overlay'></div>
          </div>
        </div>
        {/* <!-- end col --> */}
      </div>
      {/* <!-- end row --> */}
    </div>
  );
}
