import {
  DEALER_DETAILS,
  DEALER_LIST,
  DEALER_STOCKE_AGE,
  DEALER_VEHICLES,
  DEALER_VEHICLES_DATA,
  DMS_PROVIDER,
  UPDATE_COLUMN_VALUE,
  USER_LOGIN_SUCCESSFULLY,
  UPDATE_PRICE_VALUE,
  ACCOUNT_MANAGER_LIST,
  DEALER_LIST_EXPORT,
} from '../Constant';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../Service/Api';

//admin
export const Get_dealer_list =
  (pageno, filter, search, sort,filteraccountholdlive,carsort,datacar) => async (dispatch) => {
    try {
      var dealer_list = await API.post(
        `/Dealer_list?page_no=${pageno}&filter=${filter}&search=${search}&sort=${sort}&filteraccountholdlive=${filteraccountholdlive} &carsort=${carsort}`,datacar
      );

      if (dealer_list.data.status == 200) {
        dispatch({
          type: DEALER_LIST,
          payload: dealer_list.data.data,
        });
      } else {
        toast.warning(dealer_list.data.data.message);
      }
    } catch (error) {
      toast.warning(error.message);
    }
  };
export const Get_dealer_list_pagination = (pageno) => async (dispatch) => {
  try {
    var dealer_list = await API.get(`/Dealer_list`);

    if (dealer_list.data.status == 200) {
      dispatch({
        type: DEALER_LIST,
        payload: dealer_list.data.data,
      });
    } else {
      toast.warning(dealer_list.data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const Update_status_data = (updateStus) => async (dispatch) => {
  try {
    var dealer_list = await API.post('/Update_status', updateStus);

    if (dealer_list.data.status == 200) {
    } else {
      toast.warning(dealer_list.data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const reset_password_data = (email_data) => async (dispatch) => {
  try {
    var dealer_list = await API.post(`/Reset_password?email=${email_data}`);
    console.log(dealer_list);

    if (dealer_list.data.status == 200) {
      // toast.success(dealer_list.data.message);
     return dealer_list
    } else {
      toast.warning(dealer_list.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const Dealer_profile_data = (Dealer_profile_update) => async () => {
  try {
    var response_backend = await API.post(
      '/Dealer_profile_admin',
      Dealer_profile_update
    );
    if (response_backend.data.status == 200) {
      toast.success(response_backend.data.message);
    } else {
      toast.warning(response_backend.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

//end admin
export const Dealer_details_data = (dealer_id) => async (dispatch) => {
  try {
    var dealer_detail_data = await API.get(
      `/Dealer_details?dealer_id=${dealer_id}`
    );

    if (dealer_detail_data.data.status == 200) {
      //toast.success(dealer_detail_data.data.message);
      dispatch({
        type: DEALER_DETAILS,
        payload: dealer_detail_data.data.data,
      });
    } else {
      toast.warning(dealer_detail_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const AddDealer_stock_age = () => async (dispatch) => {
  try {
    var stock_age = await API.get('/AddDealer_stock_age');

    if (stock_age.data.status == 200) {
      dispatch({
        type: DEALER_STOCKE_AGE,
        payload: stock_age.data.data,
      });
    } else {
      toast.warning(stock_age.data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const dms_provider = () => async (dispatch) => {
  try {
    var dms = await API.get('/dms_provider');

    if (dms.data.status == 200) {
      dispatch({
        type: DMS_PROVIDER,
        payload: dms.data.data,
      });
    } else {
      toast.warning(dms.data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const add_dealer = (dealer_data, navigate) => async (dispatch) => {
  try {
    var dms = await API.post('/Dealer', dealer_data);

    if (dms.data.status == 200) {
      navigate('/submit_registration');
    } else {
      toast.warning(dms.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const update_dealer_id_data =
  (update_dealer_data) => async (dispatch) => {
    try {
      var dms = await API.post('/update_dealer_id', update_dealer_data);

      if (dms.data.status == 200) {
      } else {
        toast.warning(dms.data.message);
      }
    } catch (error) {
      toast.warning(error.message);
    }
  };

export const change_password_dealer = (change_password_data) => async () => {
  try {
    var response_backend = await API.post(
      '/Change_password',
      change_password_data
    );
    console.log(response_backend.data, 'sfdddddddddd');
    if (response_backend.data.status == 200) {
      toast.success(response_backend.data.message);
    } else {
      toast.warning(response_backend.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const email_update_dealer = (email_update_data) => async () => {
  try {
    var response_backend = await API.post('/update_email', email_update_data);
    if (response_backend.data.status == 200) {
      toast.success(response_backend.data.message);
    } else {
      toast.warning(response_backend.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const Dealer_profile_update = (Dealer_profile_update) => async () => {
  try {
    var response_backend = await API.post(
      '/Dealer_profile_update',
      Dealer_profile_update
    );
    if (response_backend.data.status == 200) {
      toast.success(response_backend.data.message);
    } else {
      toast.warning(response_backend.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const Dealer_details_dealer = (dealer_id) => async (dispatch) => {
  try {
    var dealer_detail_data = await API.get(
      `/Dealer_details?dealer_id=${dealer_id}`
    );

    if (dealer_detail_data.data.status == 200) {
      //toast.success(dealer_detail_data.data.message);
      return dealer_detail_data.data.data;
      // dispatch({
      //   type: DEALER_DETAILS,
      //   payload: dealer_detail_data.data.data,
      // });
    } else {
      toast.warning(dealer_detail_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const dealer_vehicle_list = (dealer_vehicle_filter) => async (dispatch) => {
  try {
    var dealer_detail_data = await API.post(`/Dealervehicals_list`,dealer_vehicle_filter);

    if (dealer_detail_data.data.status == 200) {
      //toast.success(dealer_detail_data.data.message);

      dispatch({
        type: DEALER_VEHICLES,
        payload: dealer_detail_data.data.data,
      });
    } else {
      toast.warning(dealer_detail_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const ConditionReport_data =
  (condition_report_data) => async (dispatch) => {
    try {
      var response_backend = await API.post(
        '/ConditionReport',
        condition_report_data
      );
      if (response_backend.data.status == 200) {
        // dispatch({
        //   type: UPDATE_PRICE_VALUE,
        //   payload: response_backend.data,
        // });
        // toast.success(response_backend.data.message);
      } else {
        toast.warning(response_backend.data.message);
      }
    } catch (error) {
      toast.warning(error.message);
    }
  };
export const ConditionReport_data_admin =
  (condition_report_data) => async (dispatch) => {
    try {
      var response_backend = await API.post(
        '/ConditionReport_admin',
        condition_report_data
      );
      if (response_backend.data.status == 200) {
        dispatch({
          type: UPDATE_PRICE_VALUE,
          payload: response_backend.data,
        });
      } else {
        toast.warning(response_backend.data.message);
      }
    } catch (error) {
      toast.warning(error.message);
    }
  };

export const getConditionReport = (id) => async (dispatch) => {
  try {
    var dealer_detail_data = await API.get(
      `/getConditionReport?vehicle_id=${id}`
    );

    if (dealer_detail_data.data.status == 200) {
      //toast.success(dealer_detail_data.data.message);
      return dealer_detail_data.data.data;
      // dispatch({
      //   type: DEALER_DETAILS,
      //   payload: dealer_detail_data.data.data,
      // });
    } else {
      toast.warning(dealer_detail_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const change_password_data = (new_password) => async (dispatch) => {
  try {
    var response_backend = await API.post('/Setpassword', new_password);
    if (response_backend.data.status == 200) {
      window.location.href = '/';
    
    } else {
      toast.warning(response_backend.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const CompanyDetail_save = (compny_detail) => async (dispatch) => {
  try {
    var save_company_detail = await API.post(
      '/AddCompanydetail ',
      compny_detail
    );
    if (save_company_detail.data.status == 200) {
      toast.success(save_company_detail.data.message);
    } else {
      toast.warning(save_company_detail.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const import_dealer_datat = (import_dealer) => async (dispatch) => {
  try {
    var importdealer = await API.post('/import_dealer ', import_dealer);
    if (importdealer.data.status == 200) {
      toast.success(importdealer.data.message);
    } else {
      toast.warning(importdealer.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};


export const AddDealernotes = (notest_data) => async (dispatch) => {
  try {
    var add_successful = await API.post('/AddDealernotes', notest_data);
    if (add_successful.data.status == 200) {
      //toast.success(add_successful.data.message);
    } else {
      toast.warning(add_successful.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};


export const Getevaluation=(data_for_evaluation,GETOFFER)=>async(dispatch)=>{
  try {
    var Getevaluation = await API.get(`/Getvaluations?id=${data_for_evaluation}&type=${GETOFFER}`);

    if(Getevaluation.data.status == 200){
      if(GETOFFER=="TCBGACCEPT"){
        console.log(Getevaluation.data.data,"kkkkkkkkkkkk");

        dispatch({
          type: UPDATE_PRICE_VALUE,
          payload: Getevaluation.data.data,
        });
      }
         return Getevaluation.data.data;
    }

  } catch (error) {
    // toast.warning(error.message);
  }
}

export const Getvaluationvehicleunderwrite_data=(data_for_evaluation)=>async(dispatch)=>{
  try {
    var Getevaluation = await API.post('/Getvaluationvehicleunderwrite',data_for_evaluation);

    if(Getevaluation.data.status == 200){
        return Getevaluation.data.data;
    }else{
      toast.warning(Getevaluation.data.message);
    }

  } catch (error) {
    toast.warning(error.message);
  }
}


export const delete_dealer=(dealer_id)=>async(dispatch)=>{
  try {
    var Dealerdelete = await API.get(`/delete_dealer?dealer_id=${dealer_id}`);
   

    if(Dealerdelete.data.status == 200){
      
      toast.success(Dealerdelete.data.message);
    }else{
      toast.warning(Dealerdelete.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
}


export const GetAccountmanager=()=>async(dispatch)=>{
  try {
    var AccountManager = await API.get(`/GetClienttmanager`);
   

    if(AccountManager.data.status == 200){
      
      dispatch({
        type: ACCOUNT_MANAGER_LIST,
        payload: AccountManager.data.data,
      });
    }else{
      toast.warning(AccountManager.data.message);
    }
  }catch (error) {
    toast.warning(error.message);
  }
}
export const EmailSubscribe=(email)=>async(dispatch)=>{
  try {
    var AddExcelsheet = await API.get(`/EmailSubscribe?email=${email}`);
    if(AddExcelsheet.data.status == 200){
      
      toast.success(AddExcelsheet.data.message);
    }else{
      toast.warning(AddExcelsheet.data.message);
    }

  } catch (error) {
    toast.warning(error.message);
  }
}


export const exportDealers =
  (dealer_id) => async (dispatch) => {
    try {
      var dealer_list = await API.post(
        `/exportDealers`,dealer_id
      );

      if (dealer_list.data.status == 200) {
        return dealer_list.data.data
       
      } else {
        toast.warning(dealer_list.data.data.message);
      }
    } catch (error) {
      toast.warning(error.message);
    }
  };

