import React, { useState } from 'react';
import { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import {  GetArchive_get } from '../../Redux/Action/ArchiveAction';
import Master from '../Include/Master';
import moment from 'moment';
export default function Archive() {
  const [valuedate, setValuedate] = useState(moment().format('YYYY-MM-DD'));
  const [PageNo, setValuePageNo] = useState(1);
  
  const dispatch=useDispatch();
      useEffect(()=>{
        let data={pageNo: PageNo}
    dispatch(GetArchive_get(data))
      },[])


      const archive_data=useSelector((state)=>state.archive.archive_list.vehicle_list_data );
      const vehicle_list_yotrader=useSelector((state)=>(  state.archive.archive_list?.youtrader));
      const pagiantion_data=useSelector((state)=>(  state.archive.archive_list?.pagination));
      
     
      const handlePageClick = async (page_no) => {
        setValuePageNo(page_no?.selected + 1);
        dispatch(
          GetArchive_get({pageNo:page_no?.selected + 1,date:valuedate})
        );
      };

      function Yotraderbidcalculate(data, yo_trader_bid) {
        if (yo_trader_bid != undefined) {
          var temp_cap =
            data.cap_live >= data.cap_monthly ? data.cap_monthly : data.cap_live;
          var youtrader_bid = parseFloat(
            (parseFloat(temp_cap) *
              parseFloat(
                data.confirmed_yotraderbid != null
                  ? data.confirmed_yotraderbid
                  : yo_trader_bid
              )) /
            100
          );
          var retail_price =
            (parseFloat(data.price) - parseFloat(youtrader_bid) >= 250 &&
              parseFloat(temp_cap) - parseFloat(youtrader_bid) >= 800) == true
              ? youtrader_bid
              : '';
    
          return retail_price;
        } else {
          return 0;
        }
      }
      const view_condition_report_view_hide_show = (row) => {
        if (row.report_condition == 1) {
          return (
            <button
              className='btn pending_genr'
             
            >
              View{' '}
            </button>
          );
        }
      };
   
     
      const filter_vehicle=(e)=>{
        setValuedate(e.target.value)
        dispatch(
          GetArchive_get({pageNo:PageNo,date:e.target.value})
        );
       

      }

  return (
    <Master>
      {/* <!-- start page title --> */}
      <div className='row'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <div>
              <span className='small_text'>YoTrader </span>
              <h4 className='mb-sm-0 font-size-28'>
                Archive
                <span className='blue_text'>
                  <input className=' date_new' type='date'   
              


              onChange={(event) =>filter_vehicle( event)}


              />
                </span>
              </h4>
            </div>

            <div className='page-title-right'>
              {/* <!-- App Search--> */}
              <form className='app-search d-none d-lg-block '>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='position-relative w-100'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Search'
                    />
                    <span className='bx bx-search'></span>
                  </div>
                  <img
                    src={require('../assets/images/avatar.svg').default}
                    className='avata_serch'
                    alt=''
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end page title --> */}

      <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <div className='d-flex'>
                    <h4 className='title_text'>Archive</h4>
                    {/* <div className='select_new'>
                      <span>
                        <select className='form-select' name='' id=''>
                          <option value=''>Saved Filters</option>
                        </select>
                      </span>
                      <span>
                        <select className='form-control' name='' id=''>
                          <option value=''>Save Selection</option>
                        </select>
                      </span>
                    </div> */}
                  </div>
                </div>

                <div className='table-responsive'>
                <div className='table-responsive dealer-table-c'>
                  <table
                    id=''
                    className='table dt-responsive dealers_table nowrap w-100 custom-table-new'
                  >
                    <thead>
                      <tr>
                        <th>Dealer Name</th>
                        {/* <th>Company Name</th> */}
                        <th>Contact Name</th>

                        <th>Mobile Phone</th>
                        
                        <th>Main Phone</th>
                        
                        <th>Location </th>
                        <th style={{ position: "relative" }}>
                   
                              <div>County</div>
                    
                        </th>
                        <th>Main Image</th>
                        <th>Registration (VRM)</th>
                        <th style={{ position: "relative" }}>
                          <div className='dearler-th-drop-c'>

                    
                              <div>Mileage</div>
                           

                         
                          </div>
                        </th>
                        <th style={{ position: "relative" }}>
                          <div className='dearler-th-drop-c '>
                    
                              <div>Make</div>
                            

                           
                          </div> </th>
                        <th style={{ position: "relative" }}>
                   
                              <div>Model</div>
                             

                       
                
                        </th>

                        <th style={{ position: "relative" }}>
                          <div className='dearler-th-drop-c'>

                     
                                <div >Stocking Age</div>
                               
                              </div>

                          
                        </th>
                        <th >
                              Retail price
                        </th>
                        <th >
                              Cap Live
                        </th>
                    
                        <th >
                              Cap Monthly
                        </th>
                        <th>Outside Bid</th>
                        <th>YoTrader Bid</th>
                        <th style={{ position: "relative" }}>  Distance From Retail </th>
                        <th style={{ position: "relative" }}> Date of Bid </th>
                        <th style={{ position: "relative" }}>Condition Report </th>
                        <th style={{ position: "relative" }}>Re Conditioning </th>
                        <th style={{ position: "relative" }}>Price Adjused</th>
                        <th style={{ position: "relative" }}>Bought Price</th>
                        <th style={{ position: "relative" }}>V5 Present?</th>
                        <th style={{ position: "relative" }}>Service History</th>
                        <th style={{ position: "relative" }}>Number Keys</th>
                        <th style={{ position: "relative" }}>Manual/Auto</th>
                        <th style={{ position: "relative" }}>HPI</th>
                        <th>24 Hour Reduction </th>
                        <th >
                     
                              Year
                              

                       
                         </th>
                        <th style={{ position: "relative" }}>
                  
                             Body Style
                            

                           
                       
                        </th>
                        <th>Gross Price Change </th>
                        
                      </tr>
                    </thead>
                   
                    {archive_data?.length > 0 ? (
                      <tbody className='td_color sticky-header-c tbody-height'>
                        {archive_data?.map(function (vehicle_list_obj, k) {
                          return (
                            <tr key={k}>
                              <td>
                                {vehicle_list_obj.dealer_id != null
                                  ? vehicle_list_obj.dealer_id.company_name
                                  : ''}
                              </td>
                              <td>
                                {vehicle_list_obj.dealer_id != null
                                  ? vehicle_list_obj.dealer_id.name
                                  : ''}
                              </td>
                              <td>
                                {vehicle_list_obj.dealer_id != null
                                  ? vehicle_list_obj.dealer_id.main_phone_number
                                  : ''}
                              </td>
                              <td>
                                {vehicle_list_obj.dealer_id != null
                                  ? vehicle_list_obj.dealer_id.phone_number
                                  : ''}
                              </td>
                              <td>
                                {vehicle_list_obj.dealer_id != null
                                  ? vehicle_list_obj.dealer_id.town_city
                                  : ''}
                              </td>
                              <td>
                                {vehicle_list_obj.dealer_id != null
                                  ? vehicle_list_obj.dealer_id.county
                                  : ''}
                              </td>
                              <td
                              // onClick={() => {
                              //   handleShow(vehicle_list_obj.image);
                              // }}
                              >
                                <a
                                  href={vehicle_list_obj.DeepLink}
                                  target='_blank'
                                >
                                  <img
                                    src={vehicle_list_obj.image}
                                    height='40'
                                    alt=''
                                  />
                                </a>
                              </td>
                              <td>
                                {vehicle_list_obj.vrm != '' &&
                                  vehicle_list_obj.vrm != null
                                  ? vehicle_list_obj.vrm
                                  : ''}
                              </td>
                              <td>{vehicle_list_obj.mileage}</td>
                              <td>{vehicle_list_obj.make}</td>
                              <td>{vehicle_list_obj.model}</td>
                              <td>
                               
                                    {  vehicle_list_obj.stocking_age}
                                
                              </td>
                              <td>    {new Intl.NumberFormat('en-GB', { style: 'currency',currency: 'GBP',  }) .format(vehicle_list_obj.price) .replace(/\.00$/, '')}</td>
                              <td>
                               
                               {  vehicle_list_obj.cap_live}
                           
                         </td>

                               <td>
                               
                                    {  vehicle_list_obj.cap_monthly}
                                
                              </td>

                              <td>{vehicle_list_obj.out_side_bid}</td>
                            
                              <td>
                        

                               
                                {new Intl.NumberFormat('en-GB', { style: 'currency',currency: 'GBP',  }) .format(Yotraderbidcalculate(vehicle_list_obj,vehicle_list_obj.bid_value)) .replace(/\.00$/, '')
                               }
                              </td>
                           
                              <td>
                                0
                                </td>
                                <td>


                                {
                                  Yotraderbidcalculate(vehicle_list_obj, vehicle_list_obj.bid_value) > 0 ?
                                  vehicle_list_obj?.distance_from_retail == 0  ? '' : new Intl.NumberFormat('en-GB', {
                                  style: 'currency',
                                  currency: 'GBP',
                                }) .format(vehicle_list_obj?.distance_from_retail) .replace(/\.00$/, '') : ""
                                }
                               
                              </td>
                              <td>{view_condition_report_view_hide_show(vehicle_list_obj)}</td>
                              <td>{

                                parseFloat(vehicle_list_obj.cap_live) -
        

                                Yotraderbidcalculate(vehicle_list_obj, vehicle_list_obj?.bid_value)

                                -
                                parseFloat(vehicle_list_obj?.Price_Adjusted) >
                                0 && vehicle_list_obj.report_condition == 1
                                ? new Intl.NumberFormat('en-GB', {
                                  style: 'currency',
                                  currency: 'GBP',
                                })
                                  .format(
                                    parseFloat(vehicle_list_obj.cap_live) -

                            
                                    parseFloat(vehicle_list_obj?.Price_Adjusted)
                                  )
                                  .replace(/\.00$/, '')
                                : ''


                              }</td>

                              <td>
                                {  parseFloat(vehicle_list_obj.Price_Adjusted) > 0 ? (
                            
                                  new Intl.NumberFormat('en-GB', {
                                    style: 'currency',
                                    currency: 'GBP',
                                  })
                                    .format(vehicle_list_obj.Price_Adjusted)
                                    .replace(/\.00$/, '')
                              
                              ) : (
                                ''
                              )}
                              </td>
                              <td>
                                {vehicle_list_obj.bought_price}
                              </td>
                              <td>
                                {vehicle_list_obj.v5_present}
                              </td>
                              <td>
                                {vehicle_list_obj.service_history}
                              </td>
                              <td>
                                {vehicle_list_obj.number_of_key}
                              </td>
                              <td>{vehicle_list_obj.auto_manual}</td>
                              <td></td>
                              <td></td>
                              <td>{vehicle_list_obj.year}</td>
                              <td>{vehicle_list_obj.body_style}</td>
                              <td>£0</td>
                           
                             
                            </tr>
                          );
                        })}
                      </tbody>
                    ): <tbody className='tbody-height'><div className='loader-center-d'> <img  src={require('../assets/images/loader.gif')}  height='60' /></div></tbody>}
                    
                  </table>
                </div>
                <div className='pagination-wrapper custom-pagination-b'>
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pagiantion_data?.total_page}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}
