import { Action } from 'history';
import { ACCOUNT_LIST_DATA, MANAGE_ADMIN } from '../Constant';

const initialValues = {
  accountlist: [],
  adminlist:[]
};

const Account = (state = initialValues, action) => {
  switch (action.type) {
    case ACCOUNT_LIST_DATA:
      return {
        ...state,
        accountlist: action.payload,
      };
      case MANAGE_ADMIN:
        return {
          ...state,
          adminlist: action.payload,
        };

      
    default:
      return state;
  }
};
export default Account;
