import React, { createContext,  useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  exportDealers,
  Get_dealer_list,
  Get_dealer_list_pagination,
  import_dealer_datat,
  reset_password_data,
  Update_status_data,
} from '../../Redux/Action/DealerAction';
import Master from '../Include/Master';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import ReactPaginate from 'react-paginate';

import { ToastContainer, toast } from 'react-toastify';
export const dealercontext = createContext();


export default function Dealers_list() {

  const [sort, Setsort] = useState(1);
  const [ShowModal, Setmodal]=useState("pop-modal-hide");
  const [sortcar, Setsortcar] = useState(1);
  const [dealerid,Setdealerid]= useState([])
  const [datacar, Setdatacar]=useState([]);
   
  const [loadIndicator, setLoadIndicator] = useState(false);
  const [car_list, Setcar_list]=useState([]);
  const [filteraccount, Setfilteracc] = useState('all');
  const [name, setName] = useState('');
  const [filteraccountholdlive, Setfilteraccountholdlive] = useState("0,1");

   

  const dispatch = useDispatch();






  useEffect(() => {
    
    dispatch(Get_dealer_list(1,
        ((localStorage.getItem('filteracc') !='all'&& localStorage.getItem('filteracc') !=null) ?localStorage.getItem('filteracc'):filteraccount),
         ((localStorage.getItem('Namesearch') !=''&&localStorage.getItem('Namesearch') !=null) ?localStorage.getItem('Namesearch'):name),
          1,  
    (((localStorage.getItem('filteraccounthold') !="0,1"&&localStorage.getItem('filteraccounthold') !=null) ?localStorage.getItem('filteraccounthold'):filteraccountholdlive)),
      sortcar));
  }, []);



  


























  const dealer_list_data = useSelector(
    (state) => state.dealerreducer.dealer_list?.dealer_data
  );
  const pagiantion_data = useSelector(
    (state) => state.dealerreducer.dealer_list?.pagination
  );




  const update_status = (dealer_id, status) => {
    var data = {
      dealer_id: dealer_id,
      status: status,
    };
    dispatch(Update_status_data(data)).then(function () {
   //   dispatch(Get_dealer_list());
   
      dispatch(Get_dealer_list(1,
        ((localStorage.getItem('filteracc') !='all'&& localStorage.getItem('filteracc') !=null) ?localStorage.getItem('filteracc'):filteraccount),
        ((localStorage.getItem('Namesearch') !=''&&localStorage.getItem('Namesearch') !=null) ?localStorage.getItem('Namesearch'):name),
           sort, 
           (((localStorage.getItem('filteraccounthold') !="0,1"&&localStorage.getItem('filteraccounthold') !=null) ?localStorage.getItem('filteraccounthold'):filteraccountholdlive)),
            sortcar,{datacar:datacar}));
    });
  };


  const reset_password = (email) => {
    dispatch(reset_password_data(email)).then(function () {
      dispatch(Get_dealer_list(1, 
        ((localStorage.getItem('filteracc') !='all'&&localStorage.getItem('filteracc') !=null) ?localStorage.getItem('filteracc'):filteraccount)
        , ((localStorage.getItem('Namesearch') !=''&&localStorage.getItem('Namesearch') !=null) ?localStorage.getItem('Namesearch'):name), 
        sort,  
        (((localStorage.getItem('filteraccounthold') !="0,1"&&localStorage.getItem('filteraccounthold') !=null) ?localStorage.getItem('filteraccounthold'):filteraccountholdlive)),
        sortcar,{datacar:datacar}));
    });
  };


  const navigate = useNavigate();
  const Dealer_details_data = (id) => {
    navigate(`/admin/dealer_detail/${id}`);
  };


  //search
  const filter = (e) => {
    const keyword = e;
   setLoadIndicator(true)
   localStorage.setItem('Namesearch',keyword)
    dispatch(Get_dealer_list(1,
      ((localStorage.getItem('filteracc') !='all'&&localStorage.getItem('filteracc') !=null) ?localStorage.getItem('filteracc'):filteraccount),
       keyword, 
       sort, 
      (((localStorage.getItem('filteraccounthold') !="0,1"&&localStorage.getItem('filteraccounthold') !=null) ?localStorage.getItem('filteraccounthold'):filteraccountholdlive))       ,sortcar,{datacar:datacar})).then(function(){
      setLoadIndicator(false)
    });

    setName(keyword);
  };
  const importdealer = (e) => {
    const appendata = new FormData();
    appendata.append('uploadfile', e.target.files[0]);
    dispatch(import_dealer_datat(appendata));
  };


 const keyPress=(e)=>{
  if (e.key === "Enter") {
    filter(name);
  }
 }

  //end search


  //second pagination

  const handlePageClick = async (page_no) => {
    console.log(page_no?.selected, 'page_no');
    setLoadIndicator(true)
    dispatch(Get_dealer_list(page_no?.selected + 1,
       ((localStorage.getItem('filteracc') !='all'&&localStorage.getItem('filteracc') !=null) ?localStorage.getItem('filteracc'):filteraccount),
        ((localStorage.getItem('Namesearch') !=''&&localStorage.getItem('Namesearch') !=null) ?localStorage.getItem('Namesearch'):name), 
        sort,
          ((localStorage.getItem('filteraccounthold') !="0,1"&&localStorage.getItem('filteraccounthold') !=null) ?localStorage.getItem('filteraccounthold'):filteraccountholdlive)
          ,sortcar,{datacar:datacar})).then(function(){
      setLoadIndicator(false)
    });
  };

  //end second pagination

  const handelchangeaccount = (e) => {
    Setfilteracc(e.target.value);
    localStorage.setItem('filteracc',e.target.value)
    setLoadIndicator(true)
    dispatch(Get_dealer_list(1, e.target.value, 
      
      ((localStorage.getItem('Namesearch') !=''&&localStorage.getItem('Namesearch') !=null) ?localStorage.getItem('Namesearch'):name)
      
      , sort,
      ((localStorage.getItem('filteraccounthold') !="0,1"&&localStorage.getItem('filteraccounthold') !=null) ?localStorage.getItem('filteraccounthold'):filteraccountholdlive)        ,sortcar,{datacar:datacar})).then(function(){
      setLoadIndicator(false)
    });
  };


  const handelchangeholdlive = (e) => {
    Setfilteraccountholdlive(e.target.value);
    setLoadIndicator(true)

    localStorage.setItem('filteraccounthold',e.target.value)

    dispatch(Get_dealer_list(1,
       ((localStorage.getItem('filteracc') !='all'&&localStorage.getItem('filteracc') !=null) ?localStorage.getItem('filteracc'):filteraccount),
        ((localStorage.getItem('Namesearch') !=''&&localStorage.getItem('Namesearch') !=null) ?localStorage.getItem('Namesearch'):name),
         sort,e.target.value,sortcar,{datacar:datacar})).then(function(){
      setLoadIndicator(false)
    });
  };

  



  const sort_date = () => {
    Setsort(sort == 1 ? -1 : 1);
    setLoadIndicator(true)
    dispatch(Get_dealer_list(1,
       ((localStorage.getItem('filteracc') !='all'&&localStorage.getItem('filteracc') !=null) ?localStorage.getItem('filteracc'):filteraccount),
        ((localStorage.getItem('Namesearch') !=''&&localStorage.getItem('Namesearch') !=null) ?localStorage.getItem('Namesearch'):name),
         sort == 1 ? -1 : 1, 
          ((localStorage.getItem('filteraccounthold') !="0,1"&&localStorage.getItem('filteraccounthold') !=null) ?localStorage.getItem('filteraccounthold'):filteraccountholdlive),sortcar,{datacar:datacar})).then(function(){
      setLoadIndicator(false)
    });
  };

  const sort_car = () => {
    Setsortcar(sortcar == 1 ? -1 : 1);
    setLoadIndicator(true)
    dispatch(Get_dealer_list(1,
      ((localStorage.getItem('filteracc') !='all'&&localStorage.getItem('filteracc') !=null) ?localStorage.getItem('filteracc'):filteraccount),
      ((localStorage.getItem('Namesearch') !=''&&localStorage.getItem('Namesearch') !=null) ?localStorage.getItem('Namesearch'):name),
         sort, 
         ((localStorage.getItem('filteraccounthold') !="0,1"&&localStorage.getItem('filteraccounthold') !=null) ?localStorage.getItem('filteraccounthold'):filteraccountholdlive)
          ,sortcar == 1 ? -1 : 1,{datacar:datacar})).then(function(){
      setLoadIndicator(false)
    });
  };




  const car_listdata = [
    { id: 0,
       car: '0', 
       car_data: '0',
        checked: true 
      },
    {
      id: 1,
      car: '1-20',
      car_data: '1-20',
      checked: true,
    },
    {
      id: 2,
      car: '21 - 50',
      car_data: '21-50',
      checked: true,
    },
    {
      id: 3,
      car: '50 - 100',
      car_data: '50-100',
      checked: true,
    },
    {
      id: 4,
      car: '100 - 150',
      car_data: '100-150',
      checked: true,
    },
    { id: 5, car: '150-50000', car_data: '150+', checked: true },
  ];

  if (car_list?.length == 0 && car_listdata?.length > 0) {
    Setcar_list(car_listdata);
  }






const show_and_hide=()=>{
  Setmodal(ShowModal=="pop-modal-hide"?"pop-modal-show":"pop-modal-hide")
}

const handleChangefilterChecked=(value)=>{


  if(!datacar.includes(value)){        
 
    Setdatacar([...datacar, value])
                 
}else{
  datacar.splice(datacar.indexOf(value), 1);  //deleting
}



}


const handleApply=()=>{
 
  setLoadIndicator(true)
  Setmodal(ShowModal=="pop-modal-hide"?"pop-modal-show":"pop-modal-hide")

  dispatch(Get_dealer_list(1,
    ((localStorage.getItem('filteracc') !='all'&&localStorage.getItem('filteracc') !=null) ?localStorage.getItem('filteracc'):filteraccount),
    ((localStorage.getItem('Namesearch') !=''&&localStorage.getItem('Namesearch') !=null) ?localStorage.getItem('Namesearch'):name), sort, 
     ((localStorage.getItem('filteraccounthold') !="0,1"&&localStorage.getItem('filteraccounthold') !=null) ?localStorage.getItem('filteraccounthold'):filteraccountholdlive)
      ,sortcar == 1 ? -1 : 1,
      {datacar:datacar})).then(function(){
    setLoadIndicator(false)
  });
}







const exportdata=()=>{
  if(dealerid.length>0){
    dispatch(exportDealers({dealer_id_arr:dealerid})).then(function(data){

      window.location.href=process.env.REACT_APP_IMAGE_URL+"/dealer/"+data+'.csv'
      
        })
  }else{
    toast.warning('No dealer is selected to export')

  }
 



  

}
 
 
const clickoncheckbox=(id)=>{ 

 if( dealerid.includes(id) ==true){



Setdealerid( dealerid.filter(item => item !== id))

 }else{
  Setdealerid([...dealerid,id])
 }
 
}






const clearfilter=()=>{
  localStorage.removeItem("filteracc");
  localStorage.removeItem("filteraccounthold");
  localStorage.removeItem("Namesearch");
  Setfilteracc('all');
  setName('');
  Setfilteraccountholdlive("0,1");
  setLoadIndicator(true)

  dispatch(Get_dealer_list(1, 
     ((localStorage.getItem('filteracc') !='all'&&localStorage.getItem('filteracc') !=null)?localStorage.getItem('filteracc'):'all'), 
     ((localStorage.getItem('Namesearch') !=''&&localStorage.getItem('Namesearch') !=null) ?localStorage.getItem('Namesearch'):''),
      1,  
  ((localStorage.getItem('filteraccounthold') !="0,1"&&localStorage.getItem('filteraccounthold') !=null) ?localStorage.getItem('filteraccounthold'):"0,1"),
    sortcar)).then(function(){
      setLoadIndicator(false)
    });

}






  return (
    <Master>
   
      <div className='row'> 
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <div>
              <span className='small_text'>
            YoTrader</span>
              <h4 className='mb-sm-0 font-size-28'>
                All Dealers{' '}
                <span className='blue_text'>{pagiantion_data?.total_data}</span>
              </h4>
            </div>
           
                                
         
            <div className='page-title-right'>
              <div className='app-search d-none d-lg-block'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='position-relative w-100'>
                    <input
                      className='form-control'
                      placeholder='Search'
                      type='search'
                      value={name}
                      
                     
                    

                        onChange={(e)=>{
                          setName(e.target.value)
                        }}
                      onKeyDown={keyPress}
                    />
                    <span className='bx bx-search'></span>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


   

      <div className='row'>
        <div className='col-xl-12'>
          <div className='members_tbl'>
          <div className='card'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <div className='headingw-btn content-flex'>
                  <h4 className='title_text'>Dealers</h4>
                  <button className='white_clearfilter-btn' onClick={()=>clearfilter()}>Clear Filters</button>
                  </div>
                  
                

                    <span>
                    <div className="btn bg_import export-button-m mr-20">
                    <a  onClick={()=>{
                      exportdata()
                    }}>Export as CSV</a>
                    </div>
                 
                    <input
                        type='file'
                        className='btn hide-upload-input '
                        onChange={importdealer}
                      />
                      <button className='btn bg_import upload-csv-m '>
                        Import Dealers
                      </button>
                    </span>
                   
                  </div>

                 
                  <div className='table-responsive dealer-table-c table-scroll'>
                    <table
                      id=''
                      className='table dt-responsive dealers_table nowrap w-100 custom-table-new position-relative'
                    >
                      <thead>
                        <tr>
                        <th></th>
                        <th>Dealer ID</th>
                        <th>DMS Feed</th>
                          <th>Dealer Name</th>
                          <th
                            onClick={sort_date}
                            className='dealer-date-sort cursor_pointer'
                          >
                            Date Joined{' '}
                            {sort == 1 ? (
                              <img
                                src={
                                  require('../assets/images/down-arrow-dealer.svg')
                                    .default
                                }
                              />
                            ) : (
                              <img
                                src={
                                  require('../assets/images/up-arrow-dealer.svg')
                                    .default
                                }
                              />
                            )}
                          </th>
                          {/* <th>Company Name</th> */}
                          <th>Contact Name</th>
                          <th>Contact Email</th>
                          <th>Bid Email </th>
                          <th>Main Phone</th>
                          <th>Mobile Phone</th>
                         
                          <th>Website URL</th>
                          <th>Street </th>
                          <th>Town/ City</th>
                          <th>County </th>
                          <th>Post Code</th>
                          <th>Lat/ Long</th>
                         

                          <th
                           
                            className='dealer-date-sort cursor_pointer'
                          >
                           No. Cars{' '}
                            {sortcar == 1 ? (
                              <img
                                src={
                                  require('../assets/images/down-arrow-dealer.svg')
                                    .default
                                }
                                onClick={sort_car}
                              />
                            ) : (
                              <img
                                src={
                                  require('../assets/images/up-arrow-dealer.svg')
                                    .default
                                }
                                onClick={sort_car}
                              />
                            )}

                            <div className='dearler-th-drop-c width40'>

                            <div  className='filterdropth modal-filter-n'>
                              <svg  class="modal-arrow-down top1" focusable="false"  onClick={()=>{
                                show_and_hide()}}
                              aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
                            </div>

                            <div className={`${ShowModal} left80`}>
                              <div >

                                <div className='checkbox-list-part' >
                              
                              {

                                
                                car_list?.map((item, i) => 

                                (
                                  <div  class="form-check align-check-part" key={i} onClick={()=>{
                                    handleChangefilterChecked(item.car)
                                  }}>

                                    <input class="form-check-input"  type="checkbox" />
                                    <label class="form-check-label" for="flexCheckDefault">
                                      {item?.car_data}
                                    </label>
                                  </div>
                                ))
                              }
                                </div>
                                <div className='filter-appply-btn-part'>
                                  <button className='btn reset-apply-btn' >Reset</button>
                                  <button className='btn reset-apply-btn'   onClick={() => handleApply()}>Apply</button>
                                </div>
                              </div>

                            </div>
                            </div>
                          </th>
                          <th>
                            <select
                              className='boder-th'
                              onChange={handelchangeaccount}
                              value={filteraccount}
                            >
                              <option value='all'>View All</option>
                              <option value='0'> Active </option>
                              <option value='1'> Inactive </option>
                              <option value='null'> Pending Feeds ID</option>
                              <option value='pending_email'>
                                Pending Email
                              </option>
                            </select>
                          </th>
                          <th>
                            <select
                              className='boder-th'
                              onChange={handelchangeholdlive}
                              value={filteraccountholdlive}
                            >
                              <option value={[0,1]}>Live / on Hold</option>
                              <option value='0'> Live </option>
                              <option value='1'> on Hold </option>
                            
                            </select>
                          </th>

                          
                          <th>Last log In </th>
                          <th>Sold Vehicles</th>
                          <th>Purchases</th>
                          <th>Password Reset </th>
                          <th>Last Comments  </th>
                          {/* <th>Client Manager  </th> */}
                        </tr>
                      </thead>

                      <tbody className='td_color sticky-header-c table-scroll'>
                        {dealer_list_data?.length > 0 ? (
                          dealer_list_data?.map(function (dealer_obj, i) {
                            return (
                              <tr key={i}>
                              <td>
                             <input type="checkbox" onClick={()=>{
                              clickoncheckbox(dealer_obj?.dealers?.dealer_info?._id)
                               
                             }}
                             
                            //  defaultChecked={dealerid.includes(dealer_obj?.dealers?.dealer_info?._id)}
                            checked= {
                              dealerid.includes(dealer_obj?.dealers?.dealer_info?._id)==true?"checked"  :""
                            }
                              />
                              
                              </td>
                              <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  <a href='#'>{(dealer_obj?.dealers?.dealer_info?. dealer_id)?.toString()}   </a>
                                </td>

                                <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  <a href='#'>{dealer_obj?.dealers?.dms_provider_value[0]?. name}</a>
                                </td>

                                

                                <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  <a href='#'>{dealer_obj?.dealers?.dealer_info?. company_name}</a>
                                </td>

                                <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  {' '}
                                  {moment(dealer_obj?.dealers?.dealer_info?.dealer_create_date).format(
                                    'DD/MM/Y'
                                  )}
                                </td>
                                {/* <td
                                onClick={() => {
                                  Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                }}
                              >
                                {dealer_obj?.dealers?.dealer_info?.company_name}
                              </td> */}
                                <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  {dealer_obj?.dealers?.dealer_info?.name}
                                </td>
                                <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  {' '}
                                  {dealer_obj?.dealers?.dealer_info?.email}
                                </td>
                                <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  {' '}
                                  {dealer_obj?.dealers?.dealer_info?.bid_email}
                                </td>

                                <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  {dealer_obj?.dealers?.dealer_info?.phone_number}
                                </td>
                                <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  {dealer_obj?.dealers?.dealer_info?.main_phone_number}
                                </td>
                                <td
                                  // onClick={() => {
                                  //   Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  // }}
                                  className='cursor_pointer'
                                >
                                  <a
                                    href={dealer_obj?.dealers?.dealer_info?.website_url}
                                    target='_blank'
                                  >
                                    {dealer_obj?.dealers?.dealer_info?.website_url}
                                  </a>
                                </td>
                                <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  {dealer_obj?.dealers?.dealer_info?.dealer_house_name_number}
                                </td>
                                <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  {dealer_obj?.dealers?.dealer_info?.town_city}
                                </td>

                                <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  {dealer_obj?.dealers?.dealer_info?.county}
                                </td>
                                <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  {dealer_obj?.dealers?.dealer_info?.post_code_dealer_ship}
                                </td>
                                <td>
                                  {dealer_obj?.dealers?.dealer_info?.location?.coordinates['0']}/
                                  {dealer_obj?.dealers?.dealer_info?.location?.coordinates['1']}
                                </td>
                                <td
                                  onClick={() => {
                                    Dealer_details_data(dealer_obj?.dealers?.dealer_info?._id);
                                  }}
                                  className='cursor_pointer'
                                >
                                  {dealer_obj?.dealers?.total_car}
                                </td>

                                <td>
                                  <div className='dropdown'>
                                    <button
                                      className='p-0 btn-custom-drop dropdown-toggle'
                                      type='button'
                                      id='dropdownMenuButton'
                                      data-bs-toggle='dropdown'
                                      aria-expanded='false'
                                    >
                                      {dealer_obj?.dealers?.dealer_info?.status == 0 &&
                                      Math.ceil(
                                        Math.floor(
                                          (new Date() -
                                            new Date(dealer_obj?.dealers?.dealer_info?.current_date)) /
                                            (24 * 60 * 60 * 1000)
                                        ) / 7
                                      ) < 4 ? (
                                        <i className='fa fa-check-circle active_c'>
                                          <span className='drop-font'>
                                            Active
                                          </span>
                                        </i>
                                      ) : dealer_obj?.dealers?.dealer_info?.dealer_id != null ? (
                                        <i className='fa fa-exclamation-circle suspended_c'>
                                          {' '}
                                          <span className='drop-font'>
                                            Inactive
                                            
                                          </span>
                                        </i>
                                      ) : (
                                        <i className='fa fa-exclamation-circle suspended_c'>
                                          {' '}
                                          <span className='drop-font'>
                                            Pending Feeds ID
                                          </span>
                                        </i>
                                      )}
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <div className='dropdown'>
                                    <button
                                      className='p-0 btn-custom-drop dropdown-toggle'
                                      type='button'
                                      id='dropdownMenuButton'
                                      data-bs-toggle='dropdown'
                                      aria-expanded='false'
                                    >
                                      {dealer_obj?.dealers?.dealer_info?.live == 0 ? (
                                        <i className='fa fa-check-circle active_c'>
                                          <span className='drop-font'>
                                            Live
                                          </span>
                                        </i>
                                      ) : (
                                        <i className='fa fa-exclamation-circle suspended_c'>
                                          {' '}
                                          <span className='drop-font'>
                                            On Hold
                                          </span>
                                        </i>
                                      )}
                                      <i className='mdi mdi-chevron-down'></i>
                                    </button>
                                    <div
                                      className='dropdown-menu'
                                      aria-labelledby='dropdownMenuButton'
                                    >
                                      <a
                                        className='dropdown-item'
                                        href='# '
                                        onClick={() =>
                                          update_status(dealer_obj?.dealers?.dealer_info?._id, 1)
                                        }
                                      >
                                        {' '}
                                        <i className='fa fa-exclamation-circle suspended_c'></i>{' '}
                                        On Hold
                                      </a>
                                      <a
                                        className='dropdown-item'
                                        href='#'
                                        onClick={() =>
                                          update_status(dealer_obj?.dealers?.dealer_info?._id, 0)
                                        }
                                      >
                                        {' '}
                                        <i className='fa fa-check-circle active_c'></i>{' '}
                                        Live
                                      </a>
                                    </div>
                                  </div>
                                </td>

                                <td>
                                  {' '}
                                  {dealer_obj?.dealers?.dealer_info?.status == 0
                                    ? moment(dealer_obj?.dealers?.dealer_info?.updatedAt).format(
                                        'DD/MM/Y'
                                      )
                                    : ''}
                                </td>
                                <td>{dealer_obj?.sold_vehilces}</td>
                                <td>{dealer_obj?.purchase_vehilces}</td>
                                <td>
                                {dealer_obj?.dealers?.dealer_info?.email!=undefined &&dealer_obj?.dealers?.dealer_info?.email!=null &&dealer_obj?.dealers?.dealer_info?.email!=""?
                                  <button
                                    className='white-btn-td'
                                  
                                    onClick={() => {
                                      reset_password(dealer_obj?.dealers?.dealer_info?.email);
                                    }}
                                  >
                                    Reset password
                                  </button>
                                  : <button
                                    className='white-btn-td'
                                  
                                    
                                  >
                                    Reset password
                                  </button>}
                                </td>
                                <td>
                                
                                  {dealer_obj?.dealers?.dealer_notes[0]?.notes.length > 15 ?
                                    `${dealer_obj?.dealers?.dealer_notes[0]?.notes.substring(0, 15)}...` : dealer_obj?.dealers?.dealer_notes[0]?.notes
                                  }
                                
                                   </td>

                                   {/* <td>
                                    
                                   </td> */}
                              </tr>
                            );
                          })
                        ) : (
                          <tbody className='tbody-height'>
                          
                          <div className='loader-center-c'>
                            <img
                              src={require('../assets/images/loader.gif')}
                              height='60'
                            />
                          </div>
                          </tbody>
                        )}

                      </tbody>
                    </table>
                   
                    {loadIndicator && (

                  <div className='loader-center-d'>
                    <img
                      src={require('../assets/images/loader.gif')}
                      height='60'
                    />
                  </div>
                  )}

                  </div>

            
                  <div className='pagination-wrapper custom-pagination-b'>
                    <ReactPaginate
                      previousLabel={'Previous'}
                      nextLabel={'Next'}
                      breakLabel={'...'}
                      pageCount={
                        pagiantion_data?.total_page != undefined
                          ? pagiantion_data?.total_page
                          : 0
                      }
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination justify-content-center'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      activeClassName={'active'}
                    />
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    
    </Master>
  );
}
