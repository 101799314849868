import { USER_LOGIN_SUCCESSFULLY } from '../Constant';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../Service/Api';

export const admin_login = (userinfo, navigate) => async (dispatch) => {
  try {
    const login_user = await API.post('/LoginAdmin', userinfo);

    if (login_user.data.status == 400) {
      toast.warning(login_user?.data.message);
    }
    if (login_user.data.status == 200) {
    
      localStorage.setItem('accessToken', login_user?.data?.data?.token);
      localStorage.setItem('user_detail', login_user?.data?.data.email);
      localStorage.setItem('login_user_type', 1);
      localStorage.setItem('permission', login_user?.data?.data.permission);
      window.location.href = '/admin/dashboard';
      dispatch({
        type: USER_LOGIN_SUCCESSFULLY,
        payload: login_user.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
export const dealer_login = (userinfo, navigate) => async (dispatch) => {
  try {
    const login_user = await API.post('/DealerLogin', userinfo);

    if (login_user.data.status == 400) {
      toast.warning(login_user?.data.message);
    }
    if (login_user.data.status == 200) {
      localStorage.setItem('accessToken', login_user?.data?.data?.token);
      localStorage.setItem('user_dealer', login_user?.data?.data);
      localStorage.setItem('dealer_id', login_user?.data?.data._id);
      localStorage.setItem('dealer_name', login_user?.data?.data.name);
      localStorage.setItem('company_name', login_user?.data?.data.company_name);
      localStorage.setItem('dealer_id_data', login_user?.data?.data.dealer_id);
      localStorage.setItem('login_user_type', 2);
      window.location.href = '/user_dashboard';
      dispatch({
        type: USER_LOGIN_SUCCESSFULLY,
        payload: login_user.data.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
