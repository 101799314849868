import moment from 'moment';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import Master from '../Include/Master'
import { SellerEndedActions } from '../../Redux/Action/ExpireBidsAuction';

export default function DealerExpireBides() {
    const dealer_vehicle_id=useParams();


    const dispatch=useDispatch();
    useEffect(()=>{
        dispatch(SellerEndedActions({
            dealer_id:dealer_vehicle_id.id
        }))
    },[])

    const expierbidslist=useSelector((state)=>state.expiredreducer.endedauctions);

    return (
    <Master>
        {/* start page title */}
        <div className='row'>
          <div className='col-12'>
            <div className='page-title-head d-sm-flex align-items-center justify-content-between'>
              <div className='page-title-head-left d-sm-flex align-items-center justify-content-between'>
                <NavLink to='/admin/dealers'>
                  {' '}
                  <img
                    src={require('../assets/images/Expand_left.svg').default}
                    alt=''
                    className='back_btn'
                  />
                </NavLink>
                <h4 className='mb-sm-0 font-size-34 blue-color fw-700'>
                  {expierbidslist?.dealerDetails?.company_name}
                </h4>
              </div>

              <div className='page-title-right'>
                <p className='mb-0 blue-color font-size-14 fw-700'>
                  Joined: {moment(expierbidslist?.dealerDetails?.createdAt).format('DD/MM/Y')}
                </p>
              </div>
            </div>
          </div>
        </div>
      {/* end page title  */}

         <div className='row'>
            <div className='col-xl-12'>
            <div className='tabs-btn-right'>
              <ul>
                <li>
                  <NavLink to={`/admin/dealer_detail/`+expierbidslist?.dealerDetails?._id} className="white-anchor-right">Overview</NavLink>
                </li>
                <li>
                  <NavLink to={'/admin/dealer_vehicles/'+ dealer_vehicle_id.id}  className="white-anchor-right">Vehicles</NavLink>
                </li>
                <li>
                  <NavLink to={'/admin/dealer_live_bids/'+ dealer_vehicle_id?.id} className="white-anchor-right">Live Auctions</NavLink>
                </li>
                <li>
                <NavLink to={'/admin/dealer_expire_bids/'+ dealer_vehicle_id?.id} className="blue-anchor-right">Ended Auctions</NavLink>                
                </li>
                
                <li>
                <NavLink to={'/admin/offer_accepted/'+ dealer_vehicle_id?.id} className="white-anchor-right">Sold Vehicles</NavLink>
                </li>
                <li>
                <NavLink to={'/admin/purchases/'+dealer_vehicle_id?.id} className="white-anchor-right">Purchases</NavLink>
                </li>  
                <li>
                <NavLink to={'/admin/invoice/'+dealer_vehicle_id?.id} className="white-anchor-right">Invoices</NavLink>

                </li>               
              </ul>
            </div>
             
            </div>
          </div>

      <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <h4 className='title_text'>Ended Auctions {expierbidslist?.Expirebidslist?.length}</h4>
             
                </div>

                <div className='table-responsive dealer-table-c table-scroll'>
                  <table
                    id=''
                    className='table dt-responsive vehicles_table nowrap w-100'
                  >
                    <thead>
                    <tr>
                        <th>Main Image</th>
                        <th>Registration (VRM)</th>
                        <th>Mileage</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Stock Age</th>
                        <th>Retail Price</th>
                        <th>Buy Now</th>
                        <th>Reserve Price</th>
                        <th>No. Bids</th>
                        <th>Highest Bid</th>
                        <th>Highest Bidder</th>
                        <th>Ended</th>
                    
                      </tr>
                    </thead>

                    <tbody className='td_color sticky-header-c'>

                    {
                       

                      
                        expierbidslist?.Expirebidslist?.length==0?<tr className='placholder-text-height'>
                      <td>
                      <p className='placeholdet-text-table'>No data found</p>
                      </td>
                     </tr>:


                      expierbidslist?.Expirebidslist.map(function(endauction_object,i){
                        return( 
                          
                          <tr key={i}>
                          <td>
                          <img
                            src={endauction_object.images[2]}
                            height='40'
                            alt=''
                          />
                        </td>
                        <td>{endauction_object.vrm}</td>
                       
                        <td>{   new Intl.NumberFormat('en-GB').format(endauction_object.mileage).replace(/\.00$/, '') }</td>
                        <td>{endauction_object.make}</td>
                        <td>{endauction_object.model}</td>
                        <td>2</td>
                        <td>{   new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format(endauction_object.vehicle_id.price).replace(/\.00$/, '') }</td>
                        <td>{   new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format(endauction_object.price_now).replace(/\.00$/, '') }</td>

                        <td>  {  endauction_object.price_reserve==undefined?"---": new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format(endauction_object.price_reserve).replace(/\.00$/, '') }</td>

                        <td>{endauction_object.yotraderbids.length}</td>

                        <td>{new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format((endauction_object?.yotraderbids.length==0)?0:Math.max(...endauction_object?.yotraderbids.map(e => e.yotrader_value))).replace(/\.00$/, '')}</td> 



                        <td>{
                              (() => {
                                const data = endauction_object?.yotraderbids.reduce((p, c) => p.yotrader_value > c.yotrader_value ? p : c);
                                if (data) {
                                  if (data.bidsfrom === "TCGB") {
                                    return 'TCGB';
                                  } else if (data.bidsfrom === "G3") {
                                    return 'G3';
                                  } else if (data.dealersbids && data.dealersbids.company_name) {
                                    return data.dealersbids.company_name;
                                  }
                                }
                                return 'Default Value'; // Provide a default value or handle other cases
                              })()
                            }</td> 
                            <td>{ moment(endauction_object.expire_off).format('DD-MM-YYYY H:mm')}</td>
                       
                      </tr>)
                      })
                    
                    }
                    
                
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  )
}
