import React from 'react';
import Master from '../Include/Master';

export default function Underwrites() {
  return (
    <Master>
      {/* <!-- start page title --> */}
      <div className='row'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-end'>
              <div>
                <span className='small_text'>YoTrader </span>
                <h4 className='mb-sm-0 font-size-28'>
                  {' '}
                  All Underwrites<span className='blue_text'>189</span>
                </h4>
              </div>
              <div className='snd_bulk'>
                <button className='btn  livebids_btn  underwrites '>
                  <select name='' className='form-select' id=''>
                    <option value=''>Show all</option>
                  </select>
                </button>
              </div>
            </div>

            <div className='page-title-right'>
              {/* <!-- App Search--> */}
              <form className='app-search d-none d-lg-block '>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='position-relative w-100'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Search'
                    />
                    <span className='bx bx-search'></span>
                  </div>
                  <img
                    src={require('../assets/images/avatar.svg').default}
                    className='avata_serch'
                    alt=''
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end page title --> */}

      <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <h4 className='title_text'>Underwrites</h4>
                </div>

                <div className='table-responsive'>
                  <table
                    id=''
                    className='table dt-responsive vehicles_table nowrap w-100'
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Dealer Name</th>
                        <th>Contact Name</th>
                        <th>Contact Number </th>
                        <th>Locations</th>
                        <th>Main Image</th>
                        <th>Registration (VRM)</th>
                        <th>Mileage</th>
                        <th>Make </th>
                        <th>Model</th>
                        <th>Stocking Age</th>
                        <th>Retail Price</th>
                        <th>Bidder Name 1</th>
                        <th>Bidder Name 2</th>
                        <th>Bidder Ref Number</th>
                      </tr>
                    </thead>

                    <tbody className='td_color'>
                      <tr>
                        <td>25/08/2022</td>
                        <td>Sandal BMW</td>
                        <td>Alex Perry</td>
                        <td>02038806050</td>
                        <td>Leeds</td>

                        <td>
                          <img
                            src='assets/images/iphone.png'
                            height='40'
                            alt=''
                          />
                        </td>

                        <td>ND20OGR</td>
                        <td>34,533</td>

                        <td>Mercedes</td>
                        <td>A200</td>
                        <td>54</td>
                        <td>£37,500</td>
                        <td>
                          <button className='btn  vech_rupes_active  rest_passwod'>
                            £35,000
                          </button>
                        </td>
                        <td>
                          <button className='btn  vech_rupes  rest_passwod'>
                            £34,200
                          </button>
                        </td>

                        <td>43231313</td>
                      </tr>

                      <tr>
                        <td>25/08/2022</td>
                        <td>Sandal BMW</td>
                        <td>Alex Perry</td>
                        <td>02038806050</td>
                        <td>Leeds</td>

                        <td>
                          <img
                            src='assets/images/iphone.png'
                            height='40'
                            alt=''
                          />
                        </td>

                        <td>ND20OGR</td>
                        <td>34,533</td>

                        <td>Mercedes</td>
                        <td>A200</td>
                        <td>54</td>
                        <td>£37,500</td>
                        <td>
                          <button className='btn  vech_rupes_active  rest_passwod'>
                            £35,000
                          </button>
                        </td>
                        <td>
                          <button className='btn  vech_rupes  rest_passwod'>
                            £34,200
                          </button>
                        </td>

                        <td>43231313</td>
                      </tr>

                      <tr>
                        <td>25/08/2022</td>
                        <td>Sandal BMW</td>
                        <td>Alex Perry</td>
                        <td>02038806050</td>
                        <td>Leeds</td>

                        <td>
                          <img
                            src='assets/images/iphone.png'
                            height='40'
                            alt=''
                          />
                        </td>

                        <td>ND20OGR</td>
                        <td>34,533</td>

                        <td>Mercedes</td>
                        <td>A200</td>
                        <td>54</td>
                        <td>£37,500</td>
                        <td>
                          <button className='btn  vech_rupes_active  rest_passwod'>
                            £35,000
                          </button>
                        </td>
                        <td>
                          <button className='btn  vech_rupes  rest_passwod'>
                            £34,200
                          </button>
                        </td>

                        <td>43231313</td>
                      </tr>

                      <tr>
                        <td>25/08/2022</td>
                        <td>Sandal BMW</td>
                        <td>Alex Perry</td>
                        <td>02038806050</td>
                        <td>Leeds</td>

                        <td>
                          <img
                            src='assets/images/iphone.png'
                            height='40'
                            alt=''
                          />
                        </td>

                        <td>ND20OGR</td>
                        <td>34,533</td>

                        <td>Mercedes</td>
                        <td>A200</td>
                        <td>54</td>
                        <td>£37,500</td>
                        <td>
                          <button className='btn  vech_rupes_active  rest_passwod'>
                            £35,000
                          </button>
                        </td>
                        <td>
                          <button className='btn  vech_rupes  rest_passwod'>
                            £34,200
                          </button>
                        </td>

                        <td>43231313</td>
                      </tr>

                      <tr>
                        <td>25/08/2022</td>
                        <td>Sandal BMW</td>
                        <td>Alex Perry</td>
                        <td>02038806050</td>
                        <td>Leeds</td>

                        <td>
                          <img
                            src='assets/images/iphone.png'
                            height='40'
                            alt=''
                          />
                        </td>

                        <td>ND20OGR</td>
                        <td>34,533</td>

                        <td>Mercedes</td>
                        <td>A200</td>
                        <td>54</td>
                        <td>£37,500</td>
                        <td>
                          <button className='btn  vech_rupes_active  rest_passwod'>
                            £35,000
                          </button>
                        </td>
                        <td>
                          <button className='btn  vech_rupes  rest_passwod'>
                            £34,200
                          </button>
                        </td>

                        <td>43231313</td>
                      </tr>

                      <tr>
                        <td data-bs-toggle='modal' data-bs-target='#pop'>
                          25/08/2022
                        </td>
                        <td>Sandal BMW</td>
                        <td>Alex Perry</td>
                        <td>02038806050</td>
                        <td>Leeds</td>

                        <td>
                          <img
                            src='assets/images/iphone.png'
                            height='40'
                            alt=''
                          />
                        </td>

                        <td>ND20OGR</td>
                        <td>34,533</td>

                        <td>Mercedes</td>
                        <td>A200</td>
                        <td>54</td>
                        <td>£37,500</td>
                        <td>
                          <button className='btn  vech_rupes_active  rest_passwod'>
                            £35,000
                          </button>
                        </td>
                        <td>
                          <button className='btn  vech_rupes  rest_passwod'>
                            £34,200
                          </button>
                        </td>

                        <td>43231313</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}
