import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import {
  AddDealernotes,
  Dealer_details_data,
  Dealer_details_dealer,
  Dealer_profile_data,
  delete_dealer,
  dms_provider,
  GetAccountmanager,
  reset_password_data,
  Update_status_data,
} from '../../Redux/Action/DealerAction';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import Master from '../Include/Master';
const API_KEY = 'AIzaSyAEMTS-crQSSCObP_P6gNUQ0vSHnp7NQO8';

export default function Dealer_details() {
  //modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate=useNavigate();

  //modal
  const { id } = useParams();
  const [post_code, setpostcode] = useState('');
  const [address, setaddress] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Dealer_details_data(id));
  }, []);

  const dealer_data = useSelector(
    (state) => state.dealerreducer.dealer_details
  );
  const update_status = (dealer_id, status) => {
    var data = {
      dealer_id: dealer_id,
      status: status,
    };
    dispatch(Update_status_data(data)).then(function () {
      dispatch(Dealer_details_data(id));
    });
  };
  const reset_password = (email) => {
    dispatch(reset_password_data(email)).then(function () {
      dispatch(Dealer_details_data(id));
    });
  };

  useEffect(() => {
    dispatch(Dealer_details_dealer(id)).then((data) => {
      var fileds = [
        'contact_person',
        'contact_email',
        'contact_number_first',
        'contact_number_second',
        'dealer_house_name_number',
        'dealer_street',
        'town_city',
        'county',
        'website_url',
        'dms_provider',
        'dealer_id',
        'main_phone_number',
        'second_phone_number',
        'bid_email',
        'email',
        'phone_number',
        'name',
        'location',
        'account_manager_id',
        'company_name'
      ];

      fileds.forEach((el) => {
        setpostcode(data['post_code']);

        var location_data = {
          latitude: data['location'].coordinates[0],
          longitude: data['location'].coordinates[1],
        };
        setaddress(location_data);
        if(el=='dealer_id'){
          formik.setFieldValue(el, data[el].toString(), false);
        }else{
          formik.setFieldValue(el, data[el], false);
        }

        
      });
    });
  }, [id]);

  const formik = useFormik({
    initialValues: {
      contact_number_first: '',
      contact_number_second: '',
      dealer_house_name_number: '',
      dealer_street: '',
      town_city: '',
      county: '',
      website_url: '',
      dealer_id: '',
      main_phone_number: '',
      second_phone_number: '',
      bid_email: '',
      email: '',
      phone_number: '',
      name: '',
    },
    validationSchema: yup.object({
      email: yup.string().email().required('Contact email is required'),

      dealer_house_name_number: yup.string().required('First line is required'),

      town_city: yup.string().required('Town/City is required'),

      website_url: yup.string().required('Website is required'),
      dealer_id: yup.string().required('Dealer id is required'),
    }),
    onSubmit: (values) => {
      var save_dealer_profile_detail = new FormData();
    
      save_dealer_profile_detail.append(
        'contact_person',
        values.contact_person
      );
      save_dealer_profile_detail.append('id', id);
      save_dealer_profile_detail.append('contact_email', values.contact_email);
      save_dealer_profile_detail.append('phone_number', values.phone_number);
      save_dealer_profile_detail.append('email', values.email);
      save_dealer_profile_detail.append('post_code', post_code);
      save_dealer_profile_detail.append('company_name', values.company_name);
      
      save_dealer_profile_detail.append(
        'contact_number_first',
        values.contact_number_first
      );
      save_dealer_profile_detail.append(
        'contact_number_second',
        values.contact_number_second
      );
      save_dealer_profile_detail.append(
        'dealer_house_name_number',
        values.dealer_house_name_number
      );
      save_dealer_profile_detail.append('name', values.name);
      if (typeof values.dms_provider != 'object') {
        save_dealer_profile_detail.append('dms_provider', values.dms_provider);
      }

      if (typeof values.account_manager_id != 'object') {
        save_dealer_profile_detail.append('account_manager_id', values.account_manager_id);
      }


      save_dealer_profile_detail.append('bid_email', values.bid_email);
      save_dealer_profile_detail.append('dealer_street', values.dealer_street);
      save_dealer_profile_detail.append('town_city', values.town_city);
      save_dealer_profile_detail.append('county', values.county);
      save_dealer_profile_detail.append('website_url', values.website_url);
      save_dealer_profile_detail.append('dealer_id', values.dealer_id.toString());
      save_dealer_profile_detail.append('lat', address.latitude);
      save_dealer_profile_detail.append('long', address.longitude);
  
      

      save_dealer_profile_detail.append(
        'main_phone_number',
        values.main_phone_number
      );
      save_dealer_profile_detail.append(
        'second_phone_number',
        values.second_phone_number
      );
      dispatch(Dealer_profile_data(save_dealer_profile_detail));
    },
  });

  useEffect(() => {
    dispatch(dms_provider());
  }, []);
  const dms_provider_data = useSelector(
    (state) => state.dealerreducer.dms_provider
  );


  useEffect(() => {
    dispatch(GetAccountmanager());
  }, []);
  
  const account_manager_list = useSelector(
    (state) => state.dealerreducer.account_manager_list
  );


  //google


  const fetchData = async () => {
    try {
      const url = `https://api.getaddress.io/find/${post_code}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
      const response = await fetch(url);  
      const json = await response.json();
      console.log(json);
      setaddress(json);
    } catch (error) {
      console.log('error', error);
    }
  };
  const [status, setStatus] = useState(null);

  function changeStatus(e) {
    setStatus(e.target.value);

    var data_list = address.addresses[e.target.value];

    formik.setFieldValue('dealer_house_name_number', data_list.line_1, false);
    formik.setFieldValue('town_city', data_list.town_or_city, false);
    formik.setFieldValue('county', data_list.county, false);
  }

  //google

  const formiknotes = useFormik({
    initialValues: {
      notes: '',
    },
    validationSchema: yup.object({
      notes: yup.string().required('Notes is required'),
    }),
    onSubmit: (values) => {
      values.dealer_id = id;
      dispatch(AddDealernotes(values));

      dispatch(Dealer_details_data(id));
      formiknotes.setFieldValue('notes', '', false);
    },
  });
  return (
    <>
      <Master>
        <div className='row'>
          <div className='col-12'>
            <div className='page-title-head d-sm-flex align-items-center justify-content-between'>
              <div className='page-title-head-left d-sm-flex align-items-center justify-content-between'>
                <NavLink to='/admin/dealers'>
                  {' '}
                  <img
                    src={require('../assets/images/Expand_left.svg').default}
                    alt=''
                    className='back_btn'
                  />
                </NavLink>
                <h4 className='mb-sm-0 font-size-34 blue-color fw-700'>
                  {dealer_data.company_name}
                </h4>
              </div>

              <div className='page-title-right'>
                <p className='mb-0 blue-color font-size-14 fw-700'>
                  Joined: {moment(dealer_data.createdAt).format('DD/MM/Y')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='row'>
            <div className='col-xl-12'>
            <div className='tabs-btn-right'>
              <ul>
                <li>
                  <NavLink to={`/admin/dealer_detail/`+id} className="blue-anchor-right">Overview</NavLink>
                </li>
                <li>
                  <NavLink to={'/admin/dealer_vehicles/'+ dealer_data?.dealer_id?.toString()}  className="white-anchor-right">Vehicles</NavLink>
                </li>
                <li>
                  <NavLink to={'/admin/dealer_live_bids/'+ dealer_data?.dealer_id?.toString()} className="white-anchor-right">Live Auctions</NavLink>
                </li>
                <li>
                <NavLink to={'/admin/dealer_expire_bids/'+dealer_data?.dealer_id?.toString()} className="white-anchor-right">Ended Auctions</NavLink>                
                </li> 
                <li>
                
                  <NavLink to={'/admin/offer_accepted/'+dealer_data?.dealer_id?.toString()} className="white-anchor-right">Sold Vehicles</NavLink>
                </li>
                <li>
                
                <NavLink to={'/admin/offer_accepted/'+dealer_data?.dealer_id?.toString()} className="white-anchor-right">Purchases</NavLink>
                </li>
                
                <li>
                <NavLink to={'/admin/invoice/'+dealer_data?.dealer_id?.toString()} className="white-anchor-right">Invoices</NavLink>                 
                </li>               
              </ul>
            </div>
              <div className='members_tbl'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='d-flex justify-content-between align-items-center '>
                      <h4 className='title_text blue-color mb-0'>
                        Contact Details
                      </h4>
                    </div>

                    <div className='contact-details-info row'>
                      <div className='col-md-6'>
                        <ul className='mb-0'>
                        <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                            Dealer Name
                            </p>
                            <input
                              className='blue-color font-size-14 fw-700 input_new'
                              type='text'
                              name='company_name'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.company_name}
                            />
                            {formik.errors.company_name ? (
                              <strong style={{ color: 'red' }}>
                                {formik.errors.company_name}
                              </strong>
                            ) : null}
                          </li>
                          <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                              Contact Name
                            </p>
                            <input
                              className='blue-color font-size-14 fw-700 input_new'
                              type='text'
                              name='name'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                            />
                            {formik.errors.name ? (
                              <strong style={{ color: 'red' }}>
                                {formik.errors.name}
                              </strong>
                            ) : null}
                          </li>
                          <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                              Email (Contact)
                            </p>
                            <input
                              className='blue-color font-size-14 fw-700 input_new'
                              type='text'
                              name='email'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                            />
                            {formik.errors.email ? (
                              <strong style={{ color: 'red' }}>
                                {formik.errors.email}
                              </strong>
                            ) : null}
                          </li>
                          <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                              Email (Lead - Bid)
                            </p>
                            <input
                              className='blue-color font-size-14 fw-700 input_new'
                              type='text'
                              name='bid_email'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.bid_email}
                            />
                            {formik.errors.bid_email ? (
                              <strong style={{ color: 'red' }}>
                                {formik.errors.bid_email}
                              </strong>
                            ) : null}
                          </li>

                          <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                              {' '}
                              {/* Phone Number (Main) */}
                              Phone Number (Main)
                            </p>
                            <input
                              className='blue-color font-size-14 fw-700 input_new'
                              type='text'
                              name='phone_number'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.phone_number}
                            />
                            {formik.errors.phone_number ? (
                              <strong style={{ color: 'red' }}>
                                {formik.errors.phone_number}
                              </strong>
                            ) : null}
                          </li>
                          <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                            Phone Number (Mobile) 
                            </p>

                            <input
                              className='blue-color font-size-14 fw-700 input_new'
                              type='text'
                              name='main_phone_number'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.main_phone_number}
                            />
                            {formik.errors.main_phone_number ? (
                              <strong style={{ color: 'red' }}>
                                {formik.errors.main_phone_number}
                              </strong>
                            ) : null}
                          </li>
                        </ul>
                      </div>
                      <div className='col-md-6'>
                        <ul className='mb-0'>
                        <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                            Client Manager
                            </p>
                            <select
                              name='account_manager_id'
                              className='mb-0 blue-color font-size-14 fw-700 input_new'
                            
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.account_manager_id}
                            >
                              <option value=''>Select  Client Manager</option>
                              {account_manager_list.map(function (
                                account_manager_list,
                                s
                              ) {
                                return (
                                  <option value={account_manager_list._id} key={s}>
                                    {account_manager_list.name}
                                  </option>
                                );
                              })}
                            </select>

                            
                          </li>
                          <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                              Website
                            </p>
                            <input
                              type='text'
                              className='mb-0 blue-color font-size-14 fw-700 input_new input-l-r-pending'
                              name='website_url'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.website_url}
                            />
                            {formik.errors.website_url ? (
                              <strong style={{ color: 'red' }}>
                                {formik.errors.website_url}
                              </strong>
                            ) : null}
                            {/* <span className='mb-0 blue-color font-size-14 fw-700 input_new'>
                            {dealer_data.website_url}
                          </span> */}
                          </li>                     
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-xl-12'>
              <div className='members_tbl'>
                <div className='card'>
                  <div className='card-body '>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h4 className='title_text blue-color mb-0'>
                        Dealer Details
                      </h4>
                    </div>

                    <div className='contact-details-info d-flex row'>
                      <div className='col-md-6'>
                        <ul className='mb-0'>
                          <li className='d-flex'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                              Address:{' '}
                            </p>
                            <span className='blue-color font-size-14 fw-700'>
                              <div className='d-flex align-items-center'>
                                <ul className='dlrdtl_br mr-3 placholder-text'>
                                  <li>
                                    <select
                                      name='dms_provider'
                                      className='mb-0 blue-color font-size-14 fw-700 input_new street-autopik'
                                      id=''
                                      value={status}
                                      onChange={changeStatus}
                                    >
                                      <option value=''>Please select</option>
                                      {address?.addresses?.map(function (
                                        ADDRESS_DATAT,
                                        k
                                      ) {
                                        return (
                                          <option value={k} key={k}>
                                            {ADDRESS_DATAT.line_1 +
                                              ADDRESS_DATAT.county +
                                              ADDRESS_DATAT.town_or_city}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </li>
                                  <li>
                                    <input
                                      type='text'
                                      placeholder='First line'
                                      name='dealer_house_name_number'
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={
                                        formik.values.dealer_house_name_number
                                      }
                                    />{' '}
                                    {formik.errors.dealer_house_name_number ? (
                                      <strong style={{ color: 'red' }}>
                                        {formik.errors.dealer_house_name_number}
                                      </strong>
                                    ) : null}
                                  </li>
                                  {/* <li>
                                    <input
                                      type='text'
                                      name='dealer_street'
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.dealer_street}
                                    />{' '}
                                    {formik.errors.dealer_street ? (
                                      <strong style={{ color: 'red' }}>
                                        {formik.errors.dealer_street}
                                      </strong>
                                    ) : null}
                                  </li> */}
                                  <li>
                                    <input
                                      type='text'
                                      placeholder='Town/City'
                                      name='town_city'
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.town_city}
                                    />
                                    {formik.errors.town_city ? (
                                      <strong style={{ color: 'red' }}>
                                        {formik.errors.town_city}
                                      </strong>
                                    ) : null}
                                  </li>
                                  <li>
                                    <input
                                      type='text'
                                      placeholder='County'
                                      name='county'
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.county}
                                    />
                                    {formik.errors.county ? (
                                      <strong style={{ color: 'red' }}>
                                        {formik.errors.county}
                                      </strong>
                                    ) : null}
                                  </li>
                                  <input
                                    className='form-input'
                                    type='text'
                                    name='post_code_dealer_ship'
                                    placeholder='Post code'
                                    value={post_code}
                                    onChange={(e) =>
                                      setpostcode(e.target.value)
                                    }
                                  />
                                  <li>
                                    <div className='pl-10'>
                                    {address.latitude} / {address.longitude}
                                    </div>
                                  </li>
                                </ul>
                                <p
                                  className='serch_bar text-center'
                                  onClick={() => fetchData()}
                                >
                                  Search
                                </p>
                              </div>
                            </span>
                          </li>
                       
                          <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                              DMS Provided
                            </p>
                            <select
                              name='dms_provider'
                              className='mb-0 blue-color font-size-14 fw-700 input_new'
                              id=''
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.dms_provider?._id}
                            >
                              <option value=''>Select DMS Provider</option>
                              {dms_provider_data.map(function (
                                dms_provider,
                                k
                              ) {
                                return (
                                  <option value={dms_provider._id} key={k}>
                                    {dms_provider.name}
                                  </option>
                                );
                              })}
                            </select>

                            
                          </li>
                          
                          
                          <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                            (use comma for multiple Dealer IDs)



                            </p>
                            {/* <span className='mb-0 blue-color font-size-14 fw-700 input_new'>
                            {dealer_data.dealer_id == ''
                              ? ' '
                              : dealer_data.dealer_id}


                          </span> */}

                            <input
                              type='text'
                              className='mb-0 blue-color font-size-14 fw-700 input_new input-l-r-pending'
                              name='dealer_id'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.dealer_id.toString()}
                            />
                            {formik.errors.dealer_id ? (
                              <strong style={{ color: 'red' }}>
                                {formik.errors.dealer_id}
                              </strong>
                            ) : null}
                            {/* <input
                            className='mb-0 blue-color font-size-14 fw-700 input_new'
                            type='text'
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            // value={
                            //   dealer_data.dealer_id == ''
                            //     ? ' '
                            //     : dealer_data.dealer_id
                            // }
                            name='dealer_id'
                            value={
                              dealer_data.dealer_id != null
                                ? dealer_data.dealer_id
                                : input
                            }
                            onChange={(e) => setInput(e.target.value)}
                          /> */}
                            <div>
                              <button
                                type='submit'
                                className='update-btn-admin'
                                // onClick={() => update_deailer_id()}
                              >
                                Update
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className='col-md-6'>
                        <ul className='mb-0'>
                          <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                              Last Log In
                            </p>
                            <span className='blue-color font-size-14 fw-700'>
                              {' '}
                              {moment(dealer_data.updatedAt).format('DD/MM/Y')}
                            </span>
                          </li>
                          <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                              Account Status
                            </p>

                            <div className='dropdown'>
                              <button
                                className='p-0 btn-custom-drop dropdown-toggle'
                                type='button'
                                id='dropdownMenuButton'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                              >
                                {dealer_data.status == 0 ? (
                                  <i className='fa fa-check-circle active_c'>
                                    <span className='drop-font'>Active</span>
                                  </i>
                                ) : (
                                  <i className='fa fa-exclamation-circle suspended_c'>
                                    {' '}
                                    <span className='drop-font'>Inactive</span>
                                  </i>
                                )}

                                {/* <i className='mdi mdi-chevron-down'></i> */}
                              </button>

                              {/* <div
                                className='dropdown-menu'
                                aria-labelledby='dropdownMenuButton'
                              >
                                <a
                                  href='#'
                                  className='dropdown-item'
                                  onClick={() =>
                                    update_status(dealer_data._id, 1)
                                  }
                                >
                                  {' '}
                                  <i className='fa fa-exclamation-circle suspended_c'></i>{' '}
                                  Suspended
                                </a>

                                <a
                                  className='dropdown-item'
                                  href='#'
                                  onClick={() =>
                                    update_status(dealer_data._id, 0)
                                  }
                                >
                                  {' '}
                                  <i className='fa fa-check-circle active_c'></i>{' '}
                                  Active
                                </a>
                              </div> */}
                            </div>
                          </li>
                          <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                              Live / On Hold
                            </p>
                            <div className='dropdown'>
                              <button
                                className='p-0 btn-custom-drop dropdown-toggle'
                                type='button'
                                id='dropdownMenuButton'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                              >
                                {dealer_data.live == 0 ? (
                                  <i className='fa fa-check-circle active_c'>
                                    <span className='drop-font'>Live</span>
                                  </i>
                                ) : (
                                  <i className='fa fa-exclamation-circle suspended_c'>
                                    {' '}
                                    <span className='drop-font'>On Hold</span>
                                  </i>
                                )}
                                <i className='mdi mdi-chevron-down'></i>
                              </button>
                              <div
                                className='dropdown-menu'
                                aria-labelledby='dropdownMenuButton'
                              >
                                <a
                                  className='dropdown-item'
                                  href='#'
                                  onClick={() =>
                                    update_status(dealer_data._id, 1)
                                  }
                                >
                                  {' '}
                                  <i className='fa fa-exclamation-circle suspended_c'></i>{' '}
                                  On Hold
                                </a>
                                <a
                                  className='dropdown-item'
                                  href='#'
                                  onClick={() =>
                                    update_status(dealer_data._id, 0)
                                  }
                                >
                                  {' '}
                                  <i className='fa fa-check-circle active_c'></i>{' '}
                                  Live
                                </a>
                              </div>
                            </div>
                          </li>
                          <li className='d-flex align-items-center resetnone'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                              Password Reset
                            </p>
                            <button
                             type='button'
                              className='white-btn-reset'

                              onClick={() => {
                                dealer_data.email !=""&&
                                reset_password(dealer_data.email);
                              }}
                            >
                              Reset password
                            </button>
                          </li>
                          <li className='d-flex align-items-center'>
                            <p className='mb-0 blue-color font-size-14 fw-700'>
                              Delete Dealer
                            </p>
                            <button
                               type='button'
                              className='btn_danger'
                              onClick={handleShow}
                            >
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className='row'>
          <div className='col-xl-12'>
            <div className='members_tbl'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h4 className='title_text blue-color mb-0'>
                      Vehicles & Deals
                    </h4>
                  </div>

                  <div className='contact-details-info row'>
                    <div className='col-md-6'>
                      <ul className='mb-0'>
                        <li className='d-flex align-items-center'>
                          <p className='mb-0 blue-color font-size-14 fw-700'>
                            Number of Cars in Stock
                          </p>
                          <span className='blue-color font-size-14 fw-700'>
                            {dealer_data?.total_car}
                          </span>
                        </li>
                        <li className='d-flex align-items-center'>
                          <p className='mb-0 blue-color font-size-14 fw-700'>
                            Number of Traded Cars
                          </p>
                          <span className='mb-0 blue-color font-size-14 fw-700'>
                            {dealer_data?.total_trade_car}
                          </span>
                        </li>
                        <li className='d-flex align-items-center'>
                          <p className='mb-0 blue-color font-size-14 fw-700'>
                            Number of Underwrites
                          </p>
                          <span className='mb-0 blue-color font-size-14 fw-700'>
                            0
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='members_tbl'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h4 className='title_text blue-color mb-0'>Notes</h4>
                  </div>

                  <div className='contact-details-info row notes'>
                    <div className='col-md-6'>
                      <ul className='mb-16'>
                        {dealer_data?.notes_data?.map(function (data, i) {
                          return (
                            <li key={i}>
                              <div className='d-flex align-items-start'>
                                <img
                                  src={require('../assets/images/User_cicrle.svg').default}
                                  alt=''
                                />
                                <p className='mb-0 blue-color font-size-14 fw-700'>
                                  {data.notes}
                                </p>
                              </div>
                              <h6 className='blue-color font-size-10 fw-700 text_right'>
                                {moment(data.createdAt).format('LLL')}
                              </h6>
                            </li>
                          );
                        })}
                      </ul>
                      <form
                        onSubmit={formiknotes.handleSubmit}
                        className='d-flex'
                      >
                        <textarea
                          name='notes'
                          id=''
                          cols='80'
                          rows='1'
                          className='note_insert me-4'
                          onChange={formiknotes.handleChange}
                          value={formiknotes.values.notes}
                        ></textarea>
                        <button
                          type='submit'
                          className='btn btn-primary btn-sm  rest_passwod'
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Dealer Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this dealer?</Modal.Body>
          <Modal.Footer>
            <button
              className='btn btn-primary   rest_passwod1'
              onClick={handleClose}
            >
              Cancel
            </button>
            <button className='btn btn_danger1   white ' onClick={()=>{
              dispatch(delete_dealer(id)).then(function(){
                navigate('/admin/dealers');

              })

            }}>Delete</button>
          </Modal.Footer>
        </Modal>
      </Master>
    </>
  );
}
