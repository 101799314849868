import React from 'react'
import Master from '../Include/Master'

export default function VehicleDetails() {
  return (
    <Master>
          {/* page header */}
          <div className='row'>
            <div className='col-12'>
        
            </div>
      </div>
      {/* end page title  */}
    </Master>
    
  )
}
