import React, { useState, useEffect } from 'react';
import Master from '../Include/Master';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, validateYupSchema } from 'formik';
import * as yup from 'yup';
import {
  CompanyDetail_save,
  Get_dealer_list,
} from '../../Redux/Action/DealerAction';
import { company_listdata } from '../../Redux/Action/CompanyAction';

export default function CompanyDetail() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [post_code, setpostcode] = useState('');
  const [address, setaddress] = useState([]);
  //get company list

  useEffect(() => {
    dispatch(company_listdata());
  }, []);

  const company_list_data = useSelector((state) => state.company.company_list);
  console.log(company_list_data, 'company_list_data');
  //end company list api

  const postcode = async (e) => {
    try {
      setpostcode(e.target.value);
      const url = `https://api.getaddress.io/find/${e.target.value}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
      const response = await fetch(url);
      const json = await response.json();
      console.log(json);
      setaddress(json);
    } catch (error) {
      console.log('error', error);
    }
  };
  const [status, setStatus] = useState(null);

  function changeStatus(e) {
    setStatus(e.target.value);
    console.log(
      address.addresses[e.target.value],
      'lllllllllllllllllllllllllll'
    );
    var data_list = address.addresses[e.target.value];

    formik.setFieldValue('first_line', data_list.line_1, false);
    formik.setFieldValue('city_town', data_list.town_or_city, false);
    formik.setFieldValue('country', data_list.county, false);
  }

  const formik = useFormik({
    initialValues: {
      company_name: '',
      first_line: '',
      city_town: '',
      country: '',
      post_code: '',
      contact_number: '',
    },

    validationSchema: yup.object({
      company_name: yup.string().required('Company name is required'),
      first_line: yup.string().required('First line is required'),
      city_town: yup.string().required('City/Town  is required'),
      country: yup.string().required('Country name is required'),

      contact_number: yup.string().required('Contact number is required'),
    }),
    onSubmit: (values) => {
      console.log(values);
      values.post_code = post_code;
      dispatch(CompanyDetail_save(values));
      setShow(false);
    },
  });
  console.log(formik.errors);

  return (
    <Master>
      {/* <!-- start page title --> */}
      <div className='row'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <div>
              <span className='small_text'>YoTrader </span>
              <h4 className='mb-sm-0 font-size-28'>
                <span className='blue_text'>Transport Companies</span>
              </h4>
            </div>

            <div className='page-title-right'>
              {/* <!-- App Search--> */}
              <form className='app-search d-none d-lg-block '>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='position-relative w-100'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Search'
                    />
                    <span className='bx bx-search'></span>
                  </div>
                  <img
                    src={require('../assets/images/avatar.svg').default}
                    className='avata_search'
                    alt='abc'
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end page title --> */}

      <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <h4 className='title_text'>Transport Companies</h4>
                  <div>
                    <button className='btn bg_import' onClick={handleShow}>
                      Create New
                    </button>
                  </div>

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Company Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='form-fields row'>
                        <div className='col-md-12'>
                          <form className='p-3' onSubmit={formik.handleSubmit}>
                            <ul className='mb-0 p-0'>
                              <li className='mb-24'>
                                <label for=''>Company Name</label>
                                <input
                                  type='text'
                                  placeholder='Type Here.. '
                                  className='form-input-control'
                                  name='company_name'
                                  value={formik.values.company_name}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.company_name ? (
                                  <strong style={{ color: 'red' }}>
                                    {formik.errors.company_name}
                                  </strong>
                                ) : null}
                              </li>
                              <li className='mb-24'>
                                <label for='address'>Address</label>

                                <input
                                  type='text'
                                  placeholder='Post Code'
                                  name=''
                                  onChange={postcode}
                                  className='form-input-control mb-9'
                                />
                                <select
                                  name='dms_provider'
                                  className='form-control'
                                  id=''
                                  value={status}
                                  onChange={changeStatus}
                                >
                                  <option value=''>Please select</option>
                                  {address?.addresses?.map(
                                    (ADDRESS_DATAT, k) => {
                                      return (
                                        <option value={k} key={k}>
                                          {ADDRESS_DATAT?.line_1 +
                                            ADDRESS_DATAT?.county +
                                            ADDRESS_DATAT?.town_or_city}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                <input
                                  type='text'
                                  placeholder='First Line'
                                  className='form-input-control mb-9'
                                  name='first_line'
                                  value={formik.values.first_line}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.first_line ? (
                                  <strong style={{ color: 'red' }}>
                                    {formik.errors.first_line}
                                  </strong>
                                ) : null}

                                <input
                                  type='text'
                                  placeholder='City Town'
                                  className='form-input-control mb-9'
                                  name='city_town'
                                  value={formik.values.city_town}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.city_town ? (
                                  <strong style={{ color: 'red' }}>
                                    {formik.errors.city_town}
                                  </strong>
                                ) : null}
                                <input
                                  type='text'
                                  placeholder='Country'
                                  className='form-input-control mb-9'
                                  name='country'
                                  value={formik.values.country}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.country ? (
                                  <strong style={{ color: 'red' }}>
                                    {formik.errors.country}
                                  </strong>
                                ) : null}
                              </li>
                              <li>
                                <label for=''>Contact Number</label>
                                <input
                                  type='text'
                                  className='form-input-control'
                                  placeholder='Contact Number'
                                  name='contact_number'
                                  value={formik.values.contact_number}
                                  onChange={formik.handleChange}
                                />
                                {formik.errors.contact_number ? (
                                  <strong style={{ color: 'red' }}>
                                    {formik.errors.contact_number}
                                  </strong>
                                ) : null}
                              </li>
                            </ul>
                            <Modal.Footer>
                              <Button
                                className='btn bg_import-cancel'
                                onClick={handleClose}
                              >
                                Close
                              </Button>
                              <Button className='btn bg_import' type='submit'>
                                Save Changes
                              </Button>
                            </Modal.Footer>
                          </form>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>

                <div className='table-responsive'>
                  <table
                    id=''
                    className='table dt-responsive  delivery_bought nowrap w-100'
                  >
                    <thead>
                      <tr>
                        <th>Company Name</th>
                        <th>First Line</th>
                        <th>City/Town</th>
                        <th>Country</th>
                        <th>Post code</th>
                        <th>Contact number</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody className='td_color'>
                      {company_list_data.map(function (company_obj, k) {
                        return (
                          <tr key={k}>
                            <td>{company_obj.company_name}</td>
                            <td>{company_obj.first_line}</td>
                            <td>{company_obj.city_town}</td>
                            <td>{company_obj.country}</td>
                            <td>{company_obj.post_code}</td>

                            <td>{company_obj.contact_number}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}
