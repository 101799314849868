import React, { useEffect, useState } from 'react'
import Master from '../Include/Master'
import { NavLink, useParams } from 'react-router-dom'
import CryptoJS from 'crypto-js';
import { useDispatch, useSelector } from 'react-redux';
import { endAuctiondetailsAuction } from '../../Redux/Action/ExpireBidsAuction';
import moment from 'moment';
import Carousel from 'react-bootstrap/Carousel';

// import SimpleImageSlider from "react-simple-image-slider";




export default function End_aution_details() {
const {id}=useParams();


  const livebidsid= CryptoJS.AES.decrypt(id, 'your-secret-key').toString(CryptoJS.enc.Utf8);
  console.log(livebidsid)
const dispatch=useDispatch();

  useEffect(()=>{
    dispatch(endAuctiondetailsAuction(livebidsid))
   },[livebidsid])
   const endauctiondetails=useSelector((state)=>state.expiredreducer.endauctiondetails);





   const [imagesArr,setImages]=useState([]);



   useEffect(() => {
    if (endauctiondetails?.images?.length > 0) {
      const filteredImages = endauctiondetails.images.filter((image) => {
        return image !== "" && image !== null;
      });
  
      if (filteredImages.length > 0) {
        setImages([...imagesArr, ...filteredImages.map((url) => ({ url }))]);
      }
    }
  }, [endauctiondetails]);





  return (
    <Master>
     <div className='row'>
          <div className='col-12'>
            <div className='page-title-head d-sm-flex align-items-center justify-content-between'>
              <div className='page-title-head-left d-sm-flex align-items-center justify-content-between'>
                <NavLink to={`/admin/live-bids`}>
                  {' '}
                  <img
                    src={require('../assets/images/Expand_left.svg').default}
                    alt=''
                    className='back_btn'
                  />
                </NavLink>
                <h4 className='mb-sm-0 font-size-34 blue-color fw-700'>
                {endauctiondetails?.vrm} -   {endauctiondetails?.make+ " "}{endauctiondetails?.model}
                </h4>                               
              </div>             

              <div className='page-title-right'>
              <div className='medium-blue-text-list'>Joined: {moment( endauctiondetails?.createdAt).format('DD/MM/yyyy')}</div> 
              </div>
            </div>
          </div>
        </div>

        <div className='detail-card'>
        <h4 className="title_text">Vehicle Details</h4>
          <ul className='colum-row'>
            <li>
              <div className='img-colum-part slider-img-details'>
              {/* <img  src={endauctiondetails?.images[2]} alt='' /> */}
              <Carousel data-bs-theme="dark">
              {
                endauctiondetails?.images?.map(function(imageobject,i){
                  return(
                    <Carousel.Item key={i}>
                    <img
                      className="d-block w-100"
                      src={imageobject}
                      alt="First slide"
                    />     
                  </Carousel.Item>
                  )
                })
              }
                  

    </Carousel>

             
              </div>
            </li>
            <li>
              <div className='vehicle-details-info'>
              <div className='vrm-yellow'>
                <span></span>
                <div className='vrm-number-show'>  {endauctiondetails?.vrm}</div>
              </div>
              <div className='blue-text-normal-line'>{endauctiondetails?.make+ " "}{endauctiondetails?.model}</div>
              <div className='blue-text-normal-line'>{endauctiondetails?.engine_size}cc</div>
              <div className='blue-text-normal-line'>{new Intl.NumberFormat('en-GB')
              .format(endauctiondetails?.mileage)
              .replace(/\.00$/, '')} Miles</div>
              <div className='blue-text-normal-line'>{endauctiondetails?.year??""}</div>
              <div className='blue-text-normal-line'>Retail Price: {new Intl.NumberFormat('en-GB', {style: 'currency',currency: 'GBP',}).format(endauctiondetails?.vehicle_id?.price).replace(/\.00$/, '')}</div>
              </div>
            </li>
            <li>
              <div className='vehicle-details-info'>
              <ul>
                <li className='blue-text-normal-line'>
                <div className='flex-colum-text'>
                  <p>Buy it Now</p>
                  <p>{new Intl.NumberFormat('en-GB', {style: 'currency',currency: 'GBP', }) .format(endauctiondetails?.price_now)
                                              .replace(/\.00$/, '')}</p>
                </div>
                </li>

                <li className='blue-text-normal-line'>
                <div className='flex-colum-text'>
                  <p>Reserve Price</p>
                  <p>{endauctiondetails?.price_reserve==undefined?"----":   new Intl.NumberFormat('en-GB', {
                                              style: 'currency',
                                              currency: 'GBP',
                                            })
                                              .format(endauctiondetails?.price_reserve)
                                              .replace(/\.00$/, '')}</p>
                </div>
                </li>

                <li className='blue-text-normal-line'>
                <div className='flex-colum-text'>
                  <p>Selling Dealer</p>
                  <p>{endauctiondetails?.user_detail?.company_name}</p>
                </div>
                </li>

                <li className='blue-text-normal-line'>
                <div className='flex-colum-text'>
                  <p>Contact Name</p>
                  <p>{endauctiondetails?.user_detail?.name}</p>
                </div>
                </li>

                <li className='blue-text-normal-line'>
                <div className='flex-colum-text'>
                  <p>Contact Email</p>
                  <p>{endauctiondetails?.user_detail?.email}</p>
                </div>
                </li>

                <li className='blue-text-normal-line'>
                <div className='flex-colum-text'>
                  <p>Contact Phone</p>
                  <p>{endauctiondetails?.user_detail?.phone_number}</p>
                </div>
                </li>
              </ul>       
        
              </div>
            </li>
            <li >
              <div className='button-colum-part'>
              <ul>
                <li>
                  <NavLink to={"/admin/dealer_detail/"+endauctiondetails?.user_detail?._id} className="white-btn-primary-fill">Go To Dealer Profile</NavLink>
                </li>
             
              </ul>
              </div>
            </li>
          </ul>    
        </div>  

        <div className='row mt-4'>
          <div className='col-md-12'>
            <div className='card'>
              <div className='card-body'>
              <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <h4 className='green-color-heading-table d-flex align-items-center'>Bid History<span>{ endauctiondetails?.yotraderbids?.length}</span> <div className='green-dot-circle'></div></h4>                 
               
              </div> 
              <div className='table-responsive'>
                  <table
                    id='myTable'
                    className='table  dt-responsive manadmin_table nowrap w-100'
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time</th>
                        {/* <th>CAP Live</th>
                        <th>Cap Monthly</th> */}
                        <th>Bid Value</th>
                        <th>Bidder</th>
                        <th>Contact Name</th>
                        <th>Contact Email</th>
                        <th>Contact Phone</th>
                      </tr>
                    </thead>

                    <tbody className='td_color'>
                
                      {
                      endauctiondetails?.yotraderbids?.map(function(biders_object,i){
                        return(
                      
                      <tr key={i}  className='green-text-color'>
                        <td>{moment( biders_object?.createdAt).format('DD/MM/yyyy')}</td>
                        <td>{moment( biders_object?.createdAt).format('HH:MM')}</td>
                      
                        <td>{new Intl.NumberFormat('en-GB', {
                                              style: 'currency',
                                              currency: 'GBP',
                                            })
                                              .format(biders_object?.yotrader_value)
                                              .replace(/\.00$/, '')}</td>
                        <td>{    biders_object?.bidsfrom==undefined?   biders_object?.dealersbids?.company_name:biders_object?.bidsfrom=="dealer"?biders_object?.dealersbids?.company_name:biders_object?.bidsfrom}</td>
                       
                       
                        <td>{    biders_object?.bidsfrom==undefined?   biders_object?.dealersbids?.name:biders_object?.bidsfrom=="dealer"?biders_object?.dealersbids?.name:biders_object?.bidsfrom=="G3"?"G3":"The Car Buying Group"}</td>
                       
                        <td>{    biders_object?.bidsfrom==undefined?   biders_object?.dealersbids?.name:biders_object?.bidsfrom=="dealer"?biders_object?.dealersbids?.name:biders_object?.bidsfrom=="G3"?"info@g3r.co.uk":"info@thecarbuyinggroup.co.uk"}</td>
                        <td>{    biders_object?.bidsfrom==undefined?   biders_object?.dealersbids?.phone_number:biders_object?.bidsfrom=="dealer"?biders_object?.dealersbids?.phone_number:biders_object?.bidsfrom=="G3"?"+(44) 01132014444":"0333 3201 614"}</td>
                       
                       
                      </tr>
                          
                      )
                      })
                    }
                    </tbody>
                  </table>
          </div> 
              </div>
            </div>
          </div>
        </div>
         
   
  
</Master>
  )
}
