import { COMPANY_LIST } from "../Constant"

const initialValues={
company_list:[]
}
const Company=(state = initialValues, action)=>{
    switch(action.type){
        case COMPANY_LIST:
        return {
            ...state,
            company_list:action.payload
                } 
                default:
                return state;
    }
}
export default Company