import { TRANSPORT_LIST } from "../Constant"

const initialValues = {
    Transport_list: []
}
const Transport = (state = initialValues, action) => {
    switch (action.type) {
        case TRANSPORT_LIST:
            return {
                ...state,
                Transport_list: action.payload
            }
        default:
            return state;
    }
}
export default Transport