const axios = require('axios');
console.log(process.env.REACT_APP_API_URL, 'jjjjjjjjjjjjjjjjjjjjjjjj');
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 50000000,
  headers: {
    authorization: localStorage.getItem('accessToken'),
  },
});
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error,"errorerrorerrorerrorerror")
    if (error?.response?.status === 401) {
      // toast.error('Session Expired');
      setTimeout(() => {
        localStorage.clear();
        window.location.reload(false);
        window.location.href = '/';
      }, 1000);
    }

    if (error?.response?.status === 404) {
    }
  }
);
export default API;
