import { toast } from "react-toastify";
import API from "../../Service/Api"
import { ACCPTED_OFFER, PURCHASE_VEHICLES, SELLER_ACCPTED_OFFER } from "../Constant";


export const getAcceptedoffersaction=(data)=>async(dispatch)=>{
    try {
     const getacceptedoffers=  await API.post('/admin/getAcceptedoffers',data);
     
     if(getacceptedoffers.data.status==200){
        dispatch({
            type:ACCPTED_OFFER,
            payload:getacceptedoffers.data.data
        })

     }else{
        toast.warning(getacceptedoffers.data.message);
     }
    } catch (error) {
         toast.warning(error.message);
    }

}

export const sellerAcceptedoffersAction=(data)=>async(dispatch)=>{
    try {
     const getacceptedoffers=  await API.post('/admin/sellerAcceptedoffers',data);
     
     if(getacceptedoffers.data.status==200){
        dispatch({
            type:SELLER_ACCPTED_OFFER,
            payload:getacceptedoffers.data.data
        })

     }else{
        toast.warning(getacceptedoffers.data.message);
     }
    } catch (error) {
         toast.warning(error.message);
    }

}


export const purchaseVehiclesAction=(data)=>async(dispatch)=>{
    try {
     const purchasevehicles=  await API.post('/admin/purchaseVehicles',data);
     
     if(purchasevehicles.data.status==200){
        dispatch({
            type:PURCHASE_VEHICLES,
            payload:purchasevehicles.data.data
        })

     }else{
        toast.warning(purchasevehicles.data.message);
     }
    } catch (error) {
         toast.warning(error.message);
    }

}






