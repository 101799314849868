import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetBuyingDropdownData, Get_Buying_Pagination, importcsvfile, vehicles_list } from '../../Redux/Action/VehilceAction';
import Pagination from 'react-bootstrap/Pagination';
import Master from '../Include/Master';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Dropdown from 'react-bootstrap/Dropdown';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import ReactPaginate from 'react-paginate';
import MoonLoader from 'react-spinners/MoonLoader';
import { NavLink } from 'react-router-dom';
import { FormControl, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import '../Buying/style.css';
import { distance_from_retail, mileage_list, stocking_list, Stocking, body_style, year_list, county_list, retail_list } from './commonData';

const classes = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 600,
  },
  stocking: {
    margin: theme.spacing(1),
    width: 600,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

export default function Vehicles() {
  const [page_no_data, SetoageNo] = useState(1);
  const [vrm_search, Setvrm_search] = useState('');
  const [mileage, setmileage_data] = useState([]);
  const [arrdata, setArr] = useState([]);
  const [vehicle, vehicleset] = useState([]);
  const [paginationChange, setPaginationChange] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [show, setShow] = useState(false);
  const [handleDiv, sethandleDiv] = useState(false);
  const [imagedata, setimage] = useState('');
  const [make_dealer, setMake_Dealer] = useState([]);
  const [model_dealer, setModel_Dealer] = useState([]);
  const [stockingAge, setStockingAge] = useState([]);
  const [stockingData, setstockingData] = useState([]);
  const [body_style_data, setbody_style] = useState([]);
  const [year, setYear] = useState([]);
  const [country_code, setcounty_list] = useState([]);
  const [retail, setretail_data] = useState([]);
  const [retailprice_data, setretailprice_data] = useState([]);
  const [loadIndicator, setLoadIndicator] = useState(false);
  const [sort_data_value, Setsort] = useState(1);
  const [wrappedRefType, setWrappedRef] = useState();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const dispatch = useDispatch();


  const { buying_drop, vehicle_list_data, total_page, total_car, vehicle_list_yotrader, dealer_list, filter_single_vehicle_data, pagiantion_data } = useSelector(
    (state) => ({
      buying_drop: state.vehiclereducer.buying_drop,
      vehicle_list_data: state.vehiclereducer.buying_page.vehicle_list_data,
      total_page: state.vehiclereducer.vehicle_list?.total_page,
      total_car: state.vehiclereducer.vehicle_list?.total_no_vehicl,
      vehicle_list_yotrader: state.vehiclereducer.vehicle_list?.youtrader,
      dealer_list: state.dealerreducer.dealer_list,
      filter_single_vehicle_data: state?.vehiclereducer?.filter_single_vehicle,
      pagiantion_data: state.vehiclereducer.buying_page.pagination,
    })
  );



  useEffect(() => {
    console.log(filter_single_vehicle_data?._id, 'ggggggggggggggggggggggggggg');
    if (
      filter_single_vehicle_data?._id != undefined &&
      paginationChange == false
    ) {
      console.log('pagination change');
      const updatedFilterArr = vehicle?.map((obj, i) =>
        obj._id.toString() == filter_single_vehicle_data?._id?.toString()
          ? filter_single_vehicle_data
          : obj
      );
      vehicleset(updatedFilterArr);
      setLoadIndicator(false)
    } else {
      vehicleset(vehicle_list_data);
      setLoadIndicator(false)
    }
  }, [vehicle_list_data, filter_single_vehicle_data]);

  useEffect(() => {
    dispatch(Get_Buying_Pagination(1, [], searchInput, sort_data_value));
    dispatch(GetBuyingDropdownData());
    setPaginationChange(true);
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = (image) => {
    setimage(image);
    setShow(true);
  };
  const onImageChange = (e) => {
   // var image = e.target.files[0];
    //var image_data = new FormData();
   // image_data.append('uploadfile', image);
  //  dispatch(importcsvfile(image_data));
    // dispatch(vehicles_list());
  };

  const [value, setvalue] = useState('');

  const handleOnchange = (val) => {
    setvalue(val);
  };

  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState('#42a033');

  const options = [
    { label: 'Option 1', value: 'option_1' },
    { label: 'Option 2', value: 'option_2' },
    { label: 'Option 3', value: 'option_3' },
    { label: 'Option 4', value: 'option_4' },
  ];

  const handlePageClick = async (page_no) => {
    console.log(page_no?.selected, 'page_no');
    setLoadIndicator(true)
    setPaginationChange(true);
    dispatch(
      Get_Buying_Pagination(page_no?.selected + 1, arrdata, searchInput, sort_data_value)
    );
  };
  const search_vrm = (e) => {
    Setvrm_search(e.target.value);
    dispatch(vehicles_list({ page_no: page_no_data, vrm: e.target.value }));
  };

  const searchWithFilter = (e) => {
    setSearchInput(e);

    dispatch(Get_Buying_Pagination(1, arrdata, e, sort_data_value));
  };

  const handleChangefilterChecked = (value, type) => {
    //    setLoadIndicator(true)
    var event;
    event = value;
    console.log(event, "event0000000")
    const checkType = arrdata?.find((o) => o.type == type);

    if (checkType == undefined || (value == "selectall" || value == "deselect")) {
      console.log(checkType, "omg--1")
      if (value == "selectall" || value == "deselect") {
        const arr = [];
        console.log("omg--")
        switch (type) {

          case 'mileage':
            mileage?.forEach(function (data) {
              if (value == "selectall")
                data.checked = true;

              if (value == "deselect")
                data.checked = false;

              setArr([...arrdata, { type: type, data: [data.milage] }]);
            });
            break;

          case 'stocking_age_range':
            stockingAge?.forEach(function (data, i) {
              if (value == "selectall") {
                data.checked = true;
                arr.push()
              }
              if (value == "deselect") {
                data.checked = false;

                if (i >= 1)
                  arr.push(data.stocking)
              }
            });
            arrdata?.splice(arrdata?.findIndex((item) => item?.type == "stocking_age_range"), 1);
            setArr([...arrdata, { type: type, data: arr }]);
            break;

          case 'stocking_age':
            stockingData?.forEach(function (data, i) {
              if (value == "selectall") {
                data.checked = true;
                arr.push()
              }
              if (value == "deselect") {
                data.checked = false;

                if (i >= 1)
                  arr.push(data.stocking)
              }
            });
            arrdata?.splice(arrdata?.findIndex((item) => item?.type == "stocking_age"), 1);
            setArr([...arrdata, { type: type, data: arr }]);
            break;

          case 'price':
            retail?.forEach(function (data) {
              if (value == "selectall")
                data.checked = true;

              if (value == "deselect")
                data.checked = false;
            });
            break;

          case 'distance_from_retail':
            retailprice_data?.forEach(function (data) {
              if (value == "selectall")
                data.checked = true;

              if (value == "deselect")
                data.checked = false;

              setArr([...arrdata, { type: type, data: [data.distance_from_retail] }]);
            });
            break;

          case 'year':
            year?.forEach(function (data, i) {
              if (value == "selectall") {
                data.checked = true;
                arr.push()
              }
              if (value == "deselect") {
                data.checked = false;

                if (i >= 1)
                  arr.push(data.year_select)
              }
            });
            arrdata?.splice(arrdata?.findIndex((item) => item?.type == "year"), 1);
            setArr([...arrdata, { type: type, data: arr }]);
            break;


          case 'body_style':
            body_style_data?.forEach(function (data) {
              if (value == "selectall")
                data.checked = true;

              if (value == "deselect")
                data.checked = false;

              setArr([...arrdata, { type: type, data: [data.body_style_obj] }]);
            });


            break;

          case 'country_postcode':
            country_code?.forEach(function (data, i) {
              if (value == "selectall") {
                data.checked = true;
                arr.push()
              }
              if (value == "deselect") {
                data.checked = false;

                if (i >= 1)
                  arr.push(data.county)
              }
            });
            arrdata?.splice(arrdata?.findIndex((item) => item?.type == "country_postcode"), 1);
            setArr([...arrdata, { type: type, data: arr }]);
            break;

          case 'make':
            make_dealer?.forEach(function (data, i) {
              if (value == "selectall") {
                data.checked = true;
                arr.push()
              }
              if (value == "deselect") {
                data.checked = false;

                if (i >= 1)
                  arr.push(data.make)
              }
            });
            arrdata?.splice(arrdata?.findIndex((item) => item?.type == "make"), 1);
            setArr([...arrdata, { type: type, data: arr }]);
            break;

          case 'model':
            model_dealer?.forEach(function (data, i) {
              if (value == "selectall") {
                data.checked = true;
                arr.push()
              }
              if (value == "deselect") {
                data.checked = false;

                if (i >= 1)
                  arr.push(data.model)
              }
            });
            arrdata?.splice(arrdata?.findIndex((item) => item?.type == "model"), 1);
            setArr([...arrdata, { type: type, data: arr }]);
            break;


          default:
        }
      } else {

        setArr([...arrdata, { type: type, data: [event] }]);
      }

    } else if (checkType?.type != undefined) {
      console.log("omg--2")
      arrdata?.forEach(function (data) {
        if (data?.type == type) {
          if (!data?.data?.includes(event)) {
            data?.data?.push(event);
          } else {
            data?.data?.splice(data?.data?.indexOf(event), 1);
          }
        }
      });
    }


    if (!['selectall', 'deselect'].includes(value)) {
      switch (type) {
        case 'mileage':
          mileage?.forEach(function (data) {
            if (event == data?.milage)
              data.checked = data?.checked == true ? false : true;
          });
          setmileage_data([]);
          setmileage_data(mileage);
          break;

        case 'stocking_age_range':
          stockingAge?.forEach(function (data) {
            if (event == data?.stocking)
              data.checked = data?.checked == true ? false : true;
          });
          setStockingAge([]);
          setStockingAge(stockingAge);
          break;
        case 'stocking_age':
          stockingData?.forEach(function (data) {
            if (event == data?.stocking)
              data.checked = data?.checked == true ? false : true;
          });
          setstockingData([]);
          setstockingData(stockingData);
          break;

        case 'price':
          retail?.forEach(function (data) {
            if (event == data?.retail)
              data.checked = data?.checked == true ? false : true;
          });
          setretail_data([]);
          setretail_data(retail);
          break;

        case 'distance_from_retail':
          retailprice_data?.forEach(function (data) {
            if (event == data?.distance_from_retail)
              data.checked = data?.checked == true ? false : true;
          });
          setretailprice_data([]);
          setretailprice_data(retailprice_data);
          break;

        case 'year':
          year?.forEach(function (data) {
            console.log(event, data?.year_select, 'yearse');
            if (event == data?.year_select)
              data.checked = data?.checked == true ? false : true;
          });
          setYear([]);
          setYear(year);
          break;


        case 'body_style':
          body_style_data?.forEach(function (data) {
            console.log(event, data?.body_style, 'yearse');
            if (event == data?.body_style)
              data.checked = data?.checked == true ? false : true;
          });
          setbody_style([]);
          setbody_style(body_style_data);
          break;

        case 'country_postcode':
          country_code?.forEach(function (data) {
            console.log(event, data?.county, 'yearse');
            if (event == data?.county)
              data.checked = data?.checked == true ? false : true;
          });
          setcounty_list([]);
          setcounty_list(country_code);
          break;

        case 'make':
          make_dealer?.forEach(function (data) {
            console.log(event, data?.make, 'yearse');
            if (event == data?.make)
              data.checked = data?.checked == true ? false : true;
          });
          setMake_Dealer([]);
          setMake_Dealer(make_dealer);
          break;

        case 'model':
          model_dealer?.forEach(function (data) {
            console.log(event, data?.make, 'yearse');
            if (event == data?.model)
              data.checked = data?.checked == true ? false : true;
          });
          setModel_Dealer([]);
          setModel_Dealer(model_dealer);
          break;




        default:
      }
    }
  };

  if (mileage?.length == 0 && mileage_list?.length > 0) {
    setmileage_data(mileage_list);
  }

  if (make_dealer?.length == 0 && buying_drop?.buyingMake?.length > 0) {
     if (!buying_drop?.buyingMake?.includes({ checked: true })) {
      buying_drop?.buyingMake?.unshift({ checked: true })
    }

    setMake_Dealer(buying_drop?.buyingMake);
  }

  if (model_dealer?.length == 0 && buying_drop?.buyingModel?.length > 0) {
      if (!buying_drop?.buyingModel?.includes({ model: "ajay", checked: true })) {
      buying_drop?.buyingModel?.unshift({ model: "ajay", checked: true })
    }
  
    setModel_Dealer(buying_drop?.buyingModel);
  }

  if (stockingData?.length == 0 && stocking_list?.length > 0) {
    setstockingData(stocking_list);
  }

  if (stockingAge?.length == 0 && Stocking?.length > 0) {
    setStockingAge(Stocking);
  }

  if (body_style_data?.length == 0 && body_style?.length > 0) {
    // console.log(year, 'yyyyyyyyyyyyyyyyyyyyyy');
    setbody_style(body_style);
  }
  if (year?.length == 0 && year_list?.length > 0) {
    console.log(year, 'yyyyyyyyyyyyyyyyyyyyyy');
    setYear(year_list);
  }
  if (country_code?.length == 0 && county_list?.length > 0) {
    // console.log(year, 'yyyyyyyyyyyyyyyyyyyyyy');
    setcounty_list(county_list);
  }
  if (retail?.length == 0 && retail_list?.length > 0) {
    setretail_data(retail_list);
  }

  if (retailprice_data?.length == 0 && distance_from_retail?.length > 0) {
    setretailprice_data(distance_from_retail);
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          sethandleDiv(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }


  const sort_data = () => {

    Setsort(sort_data_value == 1 ? -1 : 1);

    dispatch(
      Get_Buying_Pagination(1, arrdata, searchInput, sort_data_value == 1 ? -1 : 1)
    );

  }

  const handleApply = () => {
    setLoadIndicator(true)
    dispatch(Get_Buying_Pagination(1, arrdata, searchInput, sort_data_value));
    sethandleDiv(false)
  }

  const handleReset = (type) => {
    setLoadIndicator(true)
    arrdata?.splice(arrdata?.findIndex((item) => item?.type == type), 1)

    switch (type) {
      case 'mileage':
        mileage?.forEach(function (data) {
          data.checked = true;
        });
        setmileage_data([]);
        setmileage_data(mileage);
        break;

      case 'stocking_age_range':
        stockingAge?.forEach(function (data) {
          data.checked = true;
        });
        setStockingAge([]);
        setStockingAge(stockingAge);
        break;
      case 'stocking_age':
        stockingData?.forEach(function (data) {
          data.checked = true;
        });
        setstockingData([]);
        setstockingData(stockingData);
        break;

      case 'price':
        retail?.forEach(function (data) {
          data.checked = true;
        });
        setretail_data([]);
        setretail_data(retail);
        break;

      case 'distance_from_retail':
        retailprice_data?.forEach(function (data) {
          data.checked = true;
        });
        setretailprice_data([]);
        setretailprice_data(retailprice_data);
        break;

      case 'year':
        year?.forEach(function (data) {
          data.checked = true;
        });
        setYear([]);
        setYear(year);
        break;


      case 'body_style':
        body_style_data?.forEach(function (data) {
          data.checked = true;
        });
        setbody_style([]);
        setbody_style(body_style_data);
        break;

      case 'country_postcode':
        country_code?.forEach(function (data) {
          data.checked = true;
        });
        setcounty_list([]);
        setcounty_list(country_code);
        break;

      case 'make':
        make_dealer?.forEach(function (data) {
          data.checked = true;
        });
        setMake_Dealer([]);
        setMake_Dealer(make_dealer);
        break;

      case 'model':
        model_dealer?.forEach(function (data) {
          data.checked = true;
        });
        setModel_Dealer([]);
        setModel_Dealer(model_dealer);
        break;

      default:
    }
    dispatch(Get_Buying_Pagination(1, arrdata, searchInput, sort_data_value));
    sethandleDiv(false)
  }


  return (
    <Master>
      {/* start page title  */}
      <div className='row'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <div>
              <span className='small_text'>YoTrader </span>
              <h4 className='mb-sm-0 font-size-28'>
                All Vehicles <span className='blue_text'> {pagiantion_data?.total_data}</span>
              </h4>
            </div>

            <div className='page-title-right'>
              {/* App Search */}
              <form className='app-search d-none d-lg-block '>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='position-relative w-100'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Search'
                      value={searchInput}
                      onChange={(e) => searchWithFilter(e.target.value)}
                    />
                    <span className='bx bx-search'></span>
                  </div>
                  <img
                    src={require('../assets/images/avatar.svg').default}
                    className='avata_serch'
                    alt=''
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end page title  */}

      <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <h4 className='title_text'>All Vehicles</h4>
                  {/* <Dropdown>
                    <Dropdown.Toggle variant='success' id='dropdown-basic'>
                      Dropdown Button
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
                      <Dropdown.Item href='#/action-2'>
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href='#/action-3'>
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}

                  <div>
                    <input
                      type='file'
                      className='btn hide-upload-input '
                      onChange={onImageChange}
                    />
                    <button className='btn bg_import upload-csv-m '>
                      Import CSV
                    </button>
                  </div>

                  {/* </button> */}
                </div>

                <div className='table-responsive dealer-table-c table-scroll'>
                  <table
                    id=''
                    className='table dt-responsive dealers_table nowrap w-100 custom-table-new'
                  >
                    <thead>
                      <tr>
                        <th>Dealer Name</th>
                        {/* <th>Company Name</th> */}
                        <th>Contact Name</th>

                        <th>Mobile Phone</th>
                        
                        <th>Main Phone</th>
                        
                        <th>Location </th>
                        <th style={{ position: "relative" }}>
                          <div className='dearler-th-drop-c '>
                            <div onClick={() => {
                              sethandleDiv({ "country_postcode": handleDiv.country_postcode == undefined ? true : !handleDiv.country_postcode })
                              setWrappedRef("country_postcode");
                            }} className='filterdropth modal-filter-n'>
                              <div>County</div>
                              <svg onClick={() => sethandleDiv({ "country_postcode": handleDiv.country_postcode == undefined ? true : !handleDiv.country_postcode })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
                            </div>

                            <div ref={wrappedRefType == "country_postcode" ? wrapperRef : null} className={(handleDiv.country_postcode == undefined ? false : handleDiv.country_postcode) ? 'pop-modal-show' : 'pop-modal-hide'}>
                              <div className='select-all-check'>
                                <div onClick={() =>
                                  handleChangefilterChecked(country_code[0]?.checked ? 'deselect' : 'selectall', "country_postcode")
                                } class="form-check align-check-part">
                                  <input class="form-check-input" checked={country_code[0]?.checked ? true : false} type="checkbox" value="" />
                                  <label class="form-check-label" for="">
                                    {country_code[0]?.checked ? "Deselect" : "Select All"}
                                  </label>
                                </div>
                              </div>
                              <div className='checkbox-list-part' >


                                {country_code?.map((item, i) => {
                                  return (
                                    i >= 1 && <div onClick={() =>
                                      handleChangefilterChecked(item?.county, 'country_postcode')
                                    } class="form-check align-check-part">

                                      <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                                      <label class="form-check-label" for="flexCheckDefault">
                                        {item?.county_select}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                              <div className='filter-appply-btn-part'>
                                <button className='btn reset-apply-btn' onClick={() => handleReset("country_postcode")}>Reset</button>
                                <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
                              </div>
                            </div>

                          </div>
                        </th>
                        <th>Main Image</th>
                        <th>Registration (VRM)</th>
                        <th style={{ position: "relative" }}>
                          <div className='dearler-th-drop-c'>

                            <div onClick={() => {
                              sethandleDiv({ "mileage": handleDiv.mileage == undefined ? true : !handleDiv.mileage })
                              setWrappedRef("mileage");
                            }} className='filterdropth modal-filter-n'>
                              <div>Mileage</div>
                              <svg onClick={() => sethandleDiv({ "mileage": handleDiv.mileage == undefined ? true : !handleDiv.mileage })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
                            </div>

                            <div className={(handleDiv.mileage == undefined ? false : handleDiv.mileage) ? 'pop-modal-show' : 'pop-modal-hide'}>
                              <div ref={wrappedRefType == "mileage" ? wrapperRef : null}>

                                <div className='checkbox-list-part' >
                                  {mileage?.map((item, i) => {
                                    return (
                                      <div onClick={() =>
                                        handleChangefilterChecked(item?.milage, 'mileage')
                                      } class="form-check align-check-part">

                                        <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                          {item?.milage_data}
                                        </label>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div className='filter-appply-btn-part'>
                                  <button className='btn reset-apply-btn' onClick={() => handleReset("mileage")}>Reset</button>
                                  <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
                                </div>
                              </div>

                            </div>
                          </div>
                        </th>
                        <th style={{ position: "relative" }}>
                          <div className='dearler-th-drop-c '>
                            <div onClick={() => {
                              sethandleDiv({ "make": handleDiv.make == undefined ? true : !handleDiv.make })
                              setWrappedRef("make");
                            }} className='filterdropth modal-filter-n'>
                              <div>Make</div>
                              <svg onClick={() => sethandleDiv({ "make": handleDiv.make == undefined ? true : !handleDiv.make })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
                            </div>

                            <div ref={wrappedRefType == "make" ? wrapperRef : null} className={(handleDiv.make == undefined ? false : handleDiv.make) ? 'pop-modal-show' : 'pop-modal-hide'}>
                              <div className='select-all-check'>
                                <div onClick={() =>
                                  handleChangefilterChecked(make_dealer[0]?.checked ? 'deselect' : 'selectall', "make")
                                } class="form-check align-check-part">
                                  <input class="form-check-input" checked={make_dealer[0]?.checked ? true : false} type="checkbox" value="" />
                                  <label class="form-check-label" for="">
                                    {make_dealer[0]?.checked ? "Deselect" : "Select All"}
                                  </label>
                                </div>
                              </div>
                              <div className='checkbox-list-part' >


                                {make_dealer?.map((item, i) => {
                                  return (
                                    i >= 1 && <div onClick={() =>
                                      handleChangefilterChecked(item?.make, 'make')
                                    } class="form-check align-check-part">

                                      <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                                      <label class="form-check-label" for="flexCheckDefault">
                                        {item?.make}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                              <div className='filter-appply-btn-part'>
                                <button className='btn reset-apply-btn' onClick={() => handleReset("make")}>Reset</button>
                                <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
                              </div>
                            </div>
                          </div> </th>
                        <th style={{ position: "relative" }}>
                          <div className='dearler-th-drop-c '>
                            <div onClick={() => {
                              sethandleDiv({ "model": handleDiv.model == undefined ? true : !handleDiv.model })
                              setWrappedRef("model");
                            }} className='filterdropth modal-filter-n'>
                              <div>Model</div>
                              <svg onClick={() => sethandleDiv({ "model": handleDiv.model == undefined ? true : !handleDiv.model })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
                            </div>

                            <div ref={wrappedRefType == "model" ? wrapperRef : null} className={(handleDiv.model == undefined ? false : handleDiv.model) ? 'pop-modal-show' : 'pop-modal-hide'}>
                              <div className='select-all-check'>
                                <div onClick={() =>
                                  handleChangefilterChecked(model_dealer[0]?.checked ? 'deselect' : 'selectall', "model")
                                } class="form-check align-check-part">
                                  <input class="form-check-input" checked={model_dealer[0]?.checked ? true : false} type="checkbox" value="" />
                                  <label class="form-check-label" for="">
                                    {model_dealer[0]?.checked ? "Deselect" : "Select All"}
                                  </label>
                                </div>
                              </div>
                              <div className='checkbox-list-part' >


                                {model_dealer?.map((item, i) => {
                                  return (
                                    i >= 1 && <div onClick={() =>
                                      handleChangefilterChecked(item?.model, 'model')
                                    } class="form-check align-check-part">

                                      <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                                      <label class="form-check-label" for="flexCheckDefault">
                                        {item?.model}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                              <div className='filter-appply-btn-part'>
                                <button className='btn reset-apply-btn' onClick={() => handleReset("model")}>Reset</button>
                                <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
                              </div>
                            </div>
                          </div>
                        </th>

                        {/* <th style={{ position: "relative" }}>
                          <div className='dearler-th-drop-c'>

                            <div className='stocking-range-left'>
                              <div className='filterdropth modal-filter-n'>
                                <div >Stocking Age</div>
                                <div onClick={() => {
                                  sethandleDiv({ "stocking_age_range": handleDiv.stocking_age_range == undefined ? true : !handleDiv.stocking_age_range })
                                  setWrappedRef("stocking_age_range");
                                }}>
                                  <svg class="modal-arrow-down left_dropdown" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
                                </div>
                              </div>

                              <div ref={wrappedRefType == "stocking_age_range" ? wrapperRef : null} className={(handleDiv.stocking_age_range == undefined ? false : handleDiv.stocking_age_range) ? 'pop-modal-show' : 'pop-modal-hide'}>
                                <div className='select-all-check'>
                                  <div onClick={() =>
                                    handleChangefilterChecked(stockingAge[0]?.checked ? 'deselect' : 'selectall', "stocking_age_range")
                                  } class="form-check align-check-part">
                                    <input class="form-check-input" checked={stockingAge[0]?.checked ? true : false} type="checkbox" value="" />
                                    <label class="form-check-label" for="">
                                      {stockingAge[0]?.checked ? "Deselect" : "Select All"}
                                    </label>
                                  </div>
                                </div>
                                <div className='checkbox-list-part' >


                                  {stockingAge?.map((item, i) => {
                                    return (
                                      i >= 1 && <div onClick={() =>
                                        handleChangefilterChecked(item?.stocking, 'stocking_age_range')
                                      } class="form-check align-check-part">

                                        <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                          {item?.stocking}
                                        </label>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div className='filter-appply-btn-part'>
                                  <button className='btn reset-apply-btn' onClick={() => handleReset("stocking_age_range")}>Reset</button>
                                  <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
                                </div>
                              </div>

                            </div>

                            <div className='stocking-range-right'>
                              <div onClick={() => {
                                sethandleDiv({ "stocking_age": handleDiv.stocking_age == undefined ? true : !handleDiv.stocking_age })
                                setWrappedRef("stocking_age");
                              }} className='filterdropth modal-filter-n'>

                                <svg onClick={() => sethandleDiv({ "stocking_age": handleDiv.stocking_age == undefined ? true : !handleDiv.stocking_age })} class="modal-arrow-down right_dropdown" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
                              </div>

                              <div ref={wrappedRefType == "stocking_age" ? wrapperRef : null} className={(handleDiv.stocking_age == undefined ? false : handleDiv.stocking_age) ? 'pop-modal-show' : 'pop-modal-hide'}>
                                <div className='select-all-check'>
                                  <div onClick={() =>
                                    handleChangefilterChecked(stockingData[0]?.checked ? 'deselect' : 'selectall', "stocking_age")
                                  } class="form-check align-check-part">
                                    <input class="form-check-input" checked={stockingData[0]?.checked ? true : false} type="checkbox" value="" />
                                    <label class="form-check-label" for="">
                                      {stockingData[0]?.checked ? "Deselect" : "Select All"}
                                    </label>
                                  </div>
                                </div>
                                <div className='checkbox-list-part' >


                                  {stockingData?.map((item, i) => {
                                    return (
                                      i >= 1 && <div onClick={() =>
                                        handleChangefilterChecked(item?.stocking, 'stocking_age')
                                      } class="form-check align-check-part">

                                        <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                          {item?.stocking_data}
                                        </label>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div className='filter-appply-btn-part'>
                                  <button className='btn reset-apply-btn' onClick={() => handleReset("stocking_age")}>Reset</button>
                                  <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
                                </div>
                              </div>

                            </div>
                          </div>
                        </th> */}
                        <th style={{ position: "relative" }}>
                          <div className='dearler-th-drop-c '>
                            <div onClick={() => {
                              sethandleDiv({ "price": handleDiv.price == undefined ? true : !handleDiv.price })
                              setWrappedRef("price");
                            }} className='filterdropth modal-filter-n'>
                              <div>Retail Price</div>
                              <svg onClick={() => sethandleDiv({ "price": handleDiv.price == undefined ? true : !handleDiv.price })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
                            </div>

                            <div ref={wrappedRefType == "price" ? wrapperRef : null} className={(handleDiv.price == undefined ? false : handleDiv.price) ? 'pop-modal-show' : 'pop-modal-hide'}>
                              {/* <div className='select-all-check'>
              <div class="form-check align-check-part">
                <input class="form-check-input" checked={price[0]?.checked ? true : false} type="checkbox" value="" />
                <label onClick={() =>
                  handleChangefilterChecked(price[0]?.checked ? 'deselect' : 'selectall', "price")
                } class="form-check-label" for="">
                  {price[0]?.checked ? "Deselect" : "Select All"}
                </label>
              </div>
            </div> */}
                              <div className='checkbox-list-part' >


                                {retail?.map((item, i) => {
                                  return (
                                    <div onClick={() =>
                                      handleChangefilterChecked(item?.retail, 'price')
                                    } class="form-check align-check-part">

                                      <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                                      <label class="form-check-label" for="flexCheckDefault">
                                        {item?.retail_data}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                              <div className='filter-appply-btn-part'>
                                <button className='btn reset-apply-btn' onClick={() => handleReset("price")}>Reset</button>
                                <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
                              </div>
                            </div>

                          </div>
                        </th>
                        <th>YoTrader Bid</th>
                        <th>24 Hour Reduction </th>
                        <th style={{ position: "relative" }}>
                          <div className='dearler-th-drop-c '>
                            <div onClick={() => {
                              sethandleDiv({ "year": handleDiv.year == undefined ? true : !handleDiv.year })
                              setWrappedRef("year");
                            }} className='filterdropth modal-filter-n'>
                              <div>Year</div>
                              <svg onClick={() => sethandleDiv({ "year": handleDiv.year == undefined ? true : !handleDiv.year })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
                            </div>

                            <div ref={wrappedRefType == "year" ? wrapperRef : null} className={(handleDiv.year == undefined ? false : handleDiv.year) ? 'pop-modal-show' : 'pop-modal-hide'}>
                              <div className='select-all-check'>
                                <div onClick={() =>
                                  handleChangefilterChecked(year[0]?.checked ? 'deselect' : 'selectall', "year")
                                } class="form-check align-check-part">
                                  <input class="form-check-input" checked={year[0]?.checked ? true : false} type="checkbox" value="" />
                                  <label class="form-check-label" for="">
                                    {year[0]?.checked ? "Deselect" : "Select All"}
                                  </label>
                                </div>
                              </div>
                              <div className='checkbox-list-part' >


                                {year?.map((item, i) => {
                                  return (
                                    i >= 1 && <div onClick={() =>
                                      handleChangefilterChecked(item?.year_select, 'year')
                                    } class="form-check align-check-part">

                                      <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                                      <label class="form-check-label" for="flexCheckDefault">
                                        {item?.year}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                              <div className='filter-appply-btn-part'>
                                <button className='btn reset-apply-btn' onClick={() => handleReset("year")}>Reset</button>
                                <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
                              </div>
                            </div>

                          </div></th>
                        <th style={{ position: "relative" }}>
                          <div className='dearler-th-drop-c'>

                            <div onClick={() => {
                              sethandleDiv({ "body_style": handleDiv.body_style == undefined ? true : !handleDiv.body_style })
                              setWrappedRef("body_style");
                            }} className='filterdropth modal-filter-n'>
                              <div>Body Style</div>
                              <svg onClick={() => sethandleDiv({ "body_style": handleDiv.body_style == undefined ? true : !handleDiv.body_style })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
                            </div>

                            <div className={(handleDiv.body_style == undefined ? false : handleDiv.body_style) ? 'pop-modal-show' : 'pop-modal-hide'}>
                              <div ref={wrappedRefType == "body_style" ? wrapperRef : null}>

                                <div className='checkbox-list-part' >
                                  {body_style_data?.map((item, i) => {
                                    return (
                                      <div onClick={() =>
                                        handleChangefilterChecked(item?.body_style, 'body_style')
                                      } class="form-check align-check-part">

                                        <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                          {item?.body_style_obj}
                                        </label>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div className='filter-appply-btn-part'>
                                  <button className='btn reset-apply-btn' onClick={() => handleReset("body_style")}>Reset</button>
                                  <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
                                </div>
                              </div>

                            </div>
                          </div>
                        </th>
                        <th>Gross Price Change </th>
                        <th style={{ position: "relative" }}>
                          <div className='distance-drop-right'>
                            <div className='dearler-th-drop-c '>
                              <div onClick={() => {
                                sethandleDiv({ "distance_from_retail": handleDiv.distance_from_retail == undefined ? true : !handleDiv.distance_from_retail })
                                setWrappedRef("distance_from_retail");
                              }} className='filterdropth modal-filter-n'>
                                <div>Distance From Retail</div>
                                <svg onClick={() => sethandleDiv({ "distance_from_retail": handleDiv.distance_from_retail == undefined ? true : !handleDiv.distance_from_retail })} class="modal-arrow-down" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
                              </div>

                              <div ref={wrappedRefType == "distance_from_retail" ? wrapperRef : null} className={(handleDiv.distance_from_retail == undefined ? false : handleDiv.distance_from_retail) ? 'pop-modal-show' : 'pop-modal-hide'}>
                                {/* <div className='select-all-check'>
              <div class="form-check align-check-part">
                <input class="form-check-input" checked={distance_from_retail[0]?.checked ? true : false} type="checkbox" value="" />
                <label onClick={() =>
                  handleChangefilterChecked(distance_from_retail[0]?.checked ? 'deselect' : 'selectall', "distance_from_retail")
                } class="form-check-label" for="">
                  {distance_from_retail[0]?.checked ? "Deselect" : "Select All"}
                </label>
              </div>
            </div> */}
                                <div className='checkbox-list-part' >


                                  {retailprice_data?.map((item, i) => {
                                    return (
                                      <div onClick={() =>
                                        handleChangefilterChecked(item?.distance_from_retail, 'distance_from_retail')
                                      } class="form-check align-check-part">

                                        <input class="form-check-input" checked={item?.checked ? true : false} type="checkbox" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                          {item?.distance_from_retail_data}
                                        </label>
                                      </div>
                                    )
                                  })}
                                </div>
                                <div className='filter-appply-btn-part'>
                                  <button className='btn reset-apply-btn' onClick={() => handleReset("distance_from_retail")}>Reset</button>
                                  <button className='btn reset-apply-btn' onClick={() => handleApply()}>Apply</button>
                                </div>
                              </div>
                              <div className='sort_dis' >
                                {
                                  sort_data_value == -1 ? <img src={require('../assets/images/down_arrow-b.svg').default} onClick={sort_data} /> : <img src={require('../assets/images/up_arrow-b.svg').default} onClick={sort_data} />
                                }


                              </div>
                            </div>

                          </div>
                        </th>
                      </tr>
                    </thead>
                    {vehicle_list_data?.length > 0 ? (
                      <tbody className='td_color sticky-header-c tbody-height table-scroll'>
                        {vehicle_list_data.map(function (vehicle_list_obj, k) {
                          return (
                            <tr key={k}>
                              <td>
                                {vehicle_list_obj.dealer_id != null
                                  ? vehicle_list_obj.dealer_id.company_name
                                  : ''}
                              </td>
                              <td>
                                {vehicle_list_obj.dealer_id != null
                                  ? vehicle_list_obj.dealer_id.name
                                  : ''}
                              </td>
                              <td>
                                {vehicle_list_obj.dealer_id != null
                                  ? vehicle_list_obj.dealer_id.main_phone_number
                                  : ''}
                              </td>
                              <td>
                                {vehicle_list_obj.dealer_id != null
                                  ? vehicle_list_obj.dealer_id.phone_number
                                  : ''}
                              </td>
                              <td>
                                {vehicle_list_obj.dealer_id != null
                                  ? vehicle_list_obj.dealer_id.town_city
                                  : ''}
                              </td>
                              <td>
                                {vehicle_list_obj.dealer_id != null
                                  ? vehicle_list_obj.dealer_id.county
                                  : ''}
                              </td>
                              <td
                              // onClick={() => {
                              //   handleShow(vehicle_list_obj.image);
                              // }}
                              >
                                <a
                                  href={vehicle_list_obj.DeepLink}
                                  target='_blank'
                                >
                                  <img
                                    src={vehicle_list_obj.image}
                                    height='40'
                                    alt=''
                                  />
                                </a>
                              </td>
                              <td>
                                {vehicle_list_obj.vrm != '' &&
                                  vehicle_list_obj.vrm != null
                                  ? vehicle_list_obj.vrm
                                  : ''}
                              </td>
                              <td>{vehicle_list_obj.mileage}</td>
                              <td>{vehicle_list_obj.make}</td>
                              <td>{vehicle_list_obj.model}</td>
                              {/* <td>
                                {Math.ceil(
                                  (new Date().getTime() -
                                    new Date(
                                      vehicle_list_obj.createdAt
                                    ).getTime()) /
                                  (24 * 60 * 60 * 1000)
                                )}
                              </td> */}
                              <td>
                                {new Intl.NumberFormat('en-GB', {
                                  style: 'currency',
                                  currency: 'GBP',
                                })
                                  .format(vehicle_list_obj.price)
                                  .replace(/\.00$/, '')}
                              </td>
                              <td>
                                {parseFloat(vehicle_list_obj.cap_live) > 0 ? (
                                  <button
                                    className={
                                      vehicle_list_obj.report_condition == 1
                                        ? 'btn pending_g '
                                        : 'btn white-btn-default '
                                    }
                                  >
                                    {' '}
                                    {new Intl.NumberFormat('en-GB', {
                                      style: 'currency',
                                      currency: 'GBP',
                                    })
                                      .format(
                                        parseFloat(vehicle_list_obj.cap_live) -
                                        parseFloat(
                                          vehicle_list_obj?.confirmed_yotraderbid !=
                                            null
                                            ? vehicle_list_obj?.confirmed_yotraderbid
                                            : vehicle_list_yotrader?.yotrader_value
                                        )
                                      )
                                      .replace(/\.00$/, '')}
                                  </button>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td>
                                {parseFloat(vehicle_list_obj.price) -
                                  (parseFloat(vehicle_list_obj.cap_live) -
                                    parseFloat(
                                      vehicle_list_obj?.confirmed_yotraderbid !=
                                        null
                                        ? vehicle_list_obj?.confirmed_yotraderbid
                                        : vehicle_list_yotrader?.yotrader_value
                                    )) >
                                  0 ? (
                                  new Intl.NumberFormat('en-GB', {
                                    style: 'currency',
                                    currency: 'GBP',
                                  })
                                    .format(
                                      parseFloat(vehicle_list_obj.price) -
                                      (parseFloat(vehicle_list_obj.cap_live) -
                                        parseFloat(
                                          vehicle_list_obj?.confirmed_yotraderbid !=
                                            null
                                            ? vehicle_list_obj?.confirmed_yotraderbid
                                            : vehicle_list_yotrader?.yotrader_value
                                        ))
                                    )
                                    .replace(/\.00$/, '')
                                ) : (
                                  <b style={{ display: 'none' }}>£0</b>
                                )}
                              </td>
                              <td>{vehicle_list_obj.year}</td>
                              <td>{vehicle_list_obj.body_style}</td>
                              <td>£0</td>
                              <td>
                                {vehicle_list_obj.distance_from_retail == 0
                                  ? ''
                                  : new Intl.NumberFormat('en-GB', {
                                    style: 'currency',
                                    currency: 'GBP',
                                  })
                                    .format(
                                      vehicle_list_obj.distance_from_retail
                                    )
                                    .replace(/\.00$/, '')}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      // <div className='sweet-loading'>
                      //   <MoonLoader
                      //     className='loader-center-c'
                      //     color={color}
                      //     loading={loading}
                      //     size={10}
                      //     aria-label='Loading Spinner'
                      //     data-testid='loader'
                      //   />
                      // </div>
                      <tbody className='tbody-height'>


                        <div className='loader-center-d'>
                          <img
                            src={require('../assets/images/loader.gif')}
                            height='60'
                          />
                        </div>
                      </tbody>
                    )}
                    {loadIndicator && <div className='loader-center-d'>
                      <img
                        src={require('../assets/images/loader.gif')}
                        height='60'
                      />
                    </div>}
                  </table>
                </div>

                <div className='pagination-wrapper custom-pagination-b'>
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pagiantion_data?.total_page}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size='lg'
        show={show}
        onHide={handleClose}
        className='expand-img-c'
      >
        <Modal.Header closeButton className='close-expand-btn'></Modal.Header>
        <Modal.Body>
          <img src={imagedata} alt='' />
        </Modal.Body>
      </Modal>
    </Master>
  );
}
