import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function Sidebar() {
  const [Buying, buyset] = useState(false);
  const [setclass, setclassmactive] = useState('');
  const [height_data, setsetheight] = useState('0px');
  const [setheightcoll_data, setsetheightcoll] = useState('');
  const buynow_togal = () => {
    buyset(setclass == true ? false : true);
    setclassmactive(setclass == 'mm-active' ? '' : 'mm-active');
    setsetheight('');
    setsetheightcoll(setheightcoll_data == 'mm-show' ? '' : 'mm-show');
  };
  const logout = () => {
    localStorage.removeItem('accessToken');
    window.location.href = '/admin/login';
  };
  return (
    <div id='sidebar-menu'>
    <div className='flex-end-sidebar'>
    <ul className='metismenu list-unstyled mt-4' id='side-menu'>

      
<li className=''>
  <NavLink
    to='/admin/dashboard'
    className={({ isActive }) =>
      isActive ? 'waves-effect active' : 'waves-effect inactive'
    }
  >
    <i
      className={({ isActive }) => (isActive ? ' active' : 'inactive')}
    >
       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" id="home"><path fill="none" stroke="#A3AED0" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6.65721519,18.7714023 L6.65721519,15.70467 C6.65719744,14.9246392 7.29311743,14.2908272 8.08101266,14.2855921 L10.9670886,14.2855921 C11.7587434,14.2855921 12.4005063,14.9209349 12.4005063,15.70467 L12.4005063,15.70467 L12.4005063,18.7809263 C12.4003226,19.4432001 12.9342557,19.984478 13.603038,20 L15.5270886,20 C17.4451246,20 19,18.4606794 19,16.5618312 L19,16.5618312 L19,7.8378351 C18.9897577,7.09082692 18.6354747,6.38934919 18.0379747,5.93303245 L11.4577215,0.685301154 C10.3049347,-0.228433718 8.66620456,-0.228433718 7.51341772,0.685301154 L0.962025316,5.94255646 C0.362258604,6.39702249 0.00738668938,7.09966612 0,7.84735911 L0,16.5618312 C0,18.4606794 1.55487539,20 3.47291139,20 L5.39696203,20 C6.08235439,20 6.63797468,19.4499381 6.63797468,18.7714023 L6.63797468,18.7714023" transform="translate(2.5 2)"></path></svg>
    </i>
    <span key='t-dashboards'>Dashboard</span>
  </NavLink>
</li>


<li className=''>
  <NavLink
    to='/admin/live-bids'
    className={({ isActive }) =>
      isActive ? 'waves-effect active' : 'waves-effect inactive'
    }
  >
    <i>
      {' '}
      <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="grid"><path fill="none" stroke="#A3AED0" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M12 14H6c-1.105 0-2-.895-2-2V6c0-1.105.895-2 2-2h6c1.105 0 2 .895 2 2v6C14 13.105 13.105 14 12 14zM26 14h-6c-1.105 0-2-.895-2-2V6c0-1.105.895-2 2-2h6c1.105 0 2 .895 2 2v6C28 13.105 27.105 14 26 14zM12 28H6c-1.105 0-2-.895-2-2v-6c0-1.105.895-2 2-2h6c1.105 0 2 .895 2 2v6C14 27.105 13.105 28 12 28zM26 28h-6c-1.105 0-2-.895-2-2v-6c0-1.105.895-2 2-2h6c1.105 0 2 .895 2 2v6C28 27.105 27.105 28 26 28z"></path></svg>
    </i>

    <span key='t-dashboards'>Live Auctions</span>
  </NavLink>
</li>


<li className=''>
  <NavLink
    to='/admin/expired-bids'
    className={({ isActive }) =>
      isActive ? 'waves-effect active' : 'waves-effect inactive'
    }
  >
   <i>
   <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="grid"><path fill="none" stroke="#A3AED0" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M12 14H6c-1.105 0-2-.895-2-2V6c0-1.105.895-2 2-2h6c1.105 0 2 .895 2 2v6C14 13.105 13.105 14 12 14zM26 14h-6c-1.105 0-2-.895-2-2V6c0-1.105.895-2 2-2h6c1.105 0 2 .895 2 2v6C28 13.105 27.105 14 26 14zM12 28H6c-1.105 0-2-.895-2-2v-6c0-1.105.895-2 2-2h6c1.105 0 2 .895 2 2v6C14 27.105 13.105 28 12 28zM26 28h-6c-1.105 0-2-.895-2-2v-6c0-1.105.895-2 2-2h6c1.105 0 2 .895 2 2v6C28 27.105 27.105 28 26 28z"></path></svg>
    </i>
    <span key='t-dashboards'>Ended Auctions</span>
  </NavLink>
</li>

<li className=''>
  <NavLink
    to='/admin/accepted-offers'
    className={({ isActive }) =>
      isActive ? 'waves-effect active' : 'waves-effect inactive'
    }
  >
     <i>
     <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" id="vehicle" fill='#A3AED0'><path d="M5,18.85797V20c0,0.55225,0.44775,1,1,1s1-0.44775,1-1v-1h10v1c0,0.55225,0.44775,1,1,1s1-0.44775,1-1v-1.14203
c1.72028-0.44727,3-1.99969,3-3.85797v-1c0-1.5155-0.85706-2.82086-2.10272-3.49939L19.75421,10H20c0.55225,0,1-0.44775,1-1
s-0.44775-1-1-1h-0.81732l-0.59967-2.09863C18.0957,4.19287,16.51416,3,14.7373,3H9.2627
c-1.77686,0-3.3584,1.19287-3.8457,2.90088L4.8172,8H4C3.44775,8,3,8.44775,3,9s0.44775,1,1,1h0.24573l-0.14301,0.50061
C2.85706,11.17914,2,12.48456,2,14v1C2,16.85828,3.27972,18.41071,5,18.85797z M7.33984,6.4502
C7.58398,5.59619,8.37451,5,9.2627,5h5.47461c0.88818,0,1.67871,0.59619,1.92285,1.45068L17.67432,10H6.32568L7.33984,6.4502z
M4,14c0-1.10303,0.89697-2,2-2h12c1.10303,0,2,0.89697,2,2v1c0,1.10303-0.89697,2-2,2H6c-1.10303,0-2-0.89697-2-2V14z"></path><circle cx="7" cy="15" r="1"></circle><circle cx="17" cy="15" r="1"></circle></svg>
    </i>
    <span key='t-dashboards'>Sold Vehicles</span>
  </NavLink>
</li>

<li className=''>
          <NavLink
            to='/admin/account'
            className={({ isActive }) =>
              isActive ? 'waves-effect active' : 'waves-effect inactive'
            }
          >
            <i>
              {' '}
              <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 32 32" viewBox="0 0 32 32" id="transaction-arrow"><path d="M3.15224 10.50309h22.07926l-4.43693 4.43693c-.95533.88878-.22278 2.60581 1.06001 2.55907.3835.00002.76707-.14638 1.05988-.43918l6.99603-6.99603c.4104-.43878.53495-1.06979.32497-1.63385C30.1434 8.25952 30.03307 8.0941 29.91047 7.944c.00001 0-6.99602-6.99603-6.99602-6.99603-1.41423-1.36727-3.49139.70043-2.11984 2.11994-.00005-.00005 4.43688 4.43689 4.43688 4.43689H3.15224C1.19597 7.53414 1.1703 10.4684 3.15224 10.50309zM28.85053 21.49685H6.77127l4.43693-4.43693c1.3686-1.411-.7014-3.49272-2.11994-2.11984.00004-.00005-6.99598 6.99598-6.99598 6.99598-.4174.44802-.56186 1.13714-.28833 1.69479.07677.15288.18415.29164.28833.4251 0 0 6.99603 6.99603 6.99603 6.99603 1.41431 1.3675 3.49133-.7007 2.11984-2.11994.00005.00005-4.43689-4.43689-4.43689-4.43689h22.07926C30.80667 24.46612 30.83249 21.53135 28.85053 21.49685z" fill='#A3AED0'></path></svg>
            </i>

            <span key='t-dashboards'>Transactions</span>
          </NavLink>
        </li>
<li className={setclass}>
  <NavLink
    to='/admin/buying'>
    <i>
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" id="vehicle"><path d="M5,18.85797V20c0,0.55225,0.44775,1,1,1s1-0.44775,1-1v-1h10v1c0,0.55225,0.44775,1,1,1s1-0.44775,1-1v-1.14203
c1.72028-0.44727,3-1.99969,3-3.85797v-1c0-1.5155-0.85706-2.82086-2.10272-3.49939L19.75421,10H20c0.55225,0,1-0.44775,1-1
s-0.44775-1-1-1h-0.81732l-0.59967-2.09863C18.0957,4.19287,16.51416,3,14.7373,3H9.2627
c-1.77686,0-3.3584,1.19287-3.8457,2.90088L4.8172,8H4C3.44775,8,3,8.44775,3,9s0.44775,1,1,1h0.24573l-0.14301,0.50061
C2.85706,11.17914,2,12.48456,2,14v1C2,16.85828,3.27972,18.41071,5,18.85797z M7.33984,6.4502
C7.58398,5.59619,8.37451,5,9.2627,5h5.47461c0.88818,0,1.67871,0.59619,1.92285,1.45068L17.67432,10H6.32568L7.33984,6.4502z
M4,14c0-1.10303,0.89697-2,2-2h12c1.10303,0,2,0.89697,2,2v1c0,1.10303-0.89697,2-2,2H6c-1.10303,0-2-0.89697-2-2V14z" fill='#A3AED0'></path><circle cx="7" cy="15" r="1" fill='#A3AED0'></circle><circle cx="17" cy="15" r="1" fill='#A3AED0'></circle></svg>
    </i>

    <span key='t-dashboards'>All Vehicles</span>
  </NavLink>
  <ul
    className={'sub-menu  mm-collapse ' + setheightcoll_data}
    aria-expanded='false'
    style={{ height: height_data }}
  >
    <li className='' onClick={() => buynow_togal()}>
      <NavLink to='/admin/archive' key='t-products'  >
 
        Archive
 
      </NavLink>
    </li>
  </ul>
</li>  

   <li className=''>
  <NavLink
    to='/admin/dealers'
    className={({ isActive }) =>
      isActive ? 'waves-effect active' : 'waves-effect inactive'
    }
  >
    <i className=''>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          cx='12'
          cy='10'
          r='3'
          stroke='#A3AED0 '
          strokeWidth='2'
          strokeLinecap='round'
        />
        <circle
          cx='12'
          cy='12'
          r='9'
          stroke='#A3AED0 '
          strokeWidth='2'
        />
        <path
          d='M17.7805 18.8264C17.9076 18.7566 17.9678 18.6055 17.914 18.4708C17.5284 17.5045 16.7856 16.6534 15.7814 16.0332C14.6966 15.3632 13.3674 15 12 15C10.6326 15 9.30341 15.3632 8.21858 16.0332C7.21444 16.6534 6.4716 17.5045 6.08598 18.4708C6.03223 18.6055 6.09236 18.7566 6.21948 18.8264C9.81971 20.803 14.1803 20.803 17.7805 18.8264Z'
          fill='#A3AED0'
        />
      </svg>
    </i>
    <span key='t-dashboards'>All Dealers</span>
  </NavLink>
</li> 

</ul>
    </div>
      <ul className='metismenu list-unstyled mt-4' id='side-menu'>

      
        

       <li className=''>
          <NavLink
            to='/admin/Manage_admin'
            className={({ isActive }) =>
              isActive ? 'waves-effect active' : 'waves-effect inactive'
            }
          >
            <i>
              {' '}
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  cx='12'
                  cy='10'
                  r='3'
                  stroke='#A3AED0 '
                  strokeWidth='2'
                  strokeLinecap='round'
                />
                <circle
                  cx='12'
                  cy='12'
                  r='9'
                  stroke='#A3AED0 '
                  strokeWidth='2'
                />
                <path
                  d='M17.7805 18.8264C17.9076 18.7566 17.9678 18.6055 17.914 18.4708C17.5284 17.5045 16.7856 16.6534 15.7814 16.0332C14.6966 15.3632 13.3674 15 12 15C10.6326 15 9.30341 15.3632 8.21858 16.0332C7.21444 16.6534 6.4716 17.5045 6.08598 18.4708C6.03223 18.6055 6.09236 18.7566 6.21948 18.8264C9.81971 20.803 14.1803 20.803 17.7805 18.8264Z'
                  fill='#A3AED0'
                />
              </svg>
            </i>

            <span key='t-dashboards'>Manage Admin</span>
          </NavLink>
      </li>  

        

     
    
      



      

        {/*  jsdjkfgjdfkghdfj */}
      

        {/* <li className=''>
          <NavLink
            to='/Accounts'
            className={({ isActive }) =>
              isActive ? 'waves-effect active' : 'waves-effect inactive'
            }
          >
            <i className=''>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  cx='12'
                  cy='10'
                  r='3'
                  stroke='#A3AED0 '
                  strokeWidth='2'
                  strokeLinecap='round'
                />
                <circle
                  cx='12'
                  cy='12'
                  r='9'
                  stroke='#A3AED0 '
                  strokeWidth='2'
                />
                <path
                  d='M17.7805 18.8264C17.9076 18.7566 17.9678 18.6055 17.914 18.4708C17.5284 17.5045 16.7856 16.6534 15.7814 16.0332C14.6966 15.3632 13.3674 15 12 15C10.6326 15 9.30341 15.3632 8.21858 16.0332C7.21444 16.6534 6.4716 17.5045 6.08598 18.4708C6.03223 18.6055 6.09236 18.7566 6.21948 18.8264C9.81971 20.803 14.1803 20.803 17.7805 18.8264Z'
                  fill='#A3AED0'
                />
              </svg>
            </i>
            <span key='t-dashboards'>Accounts</span>
          </NavLink>
        </li> */}


        <li className=''>
          <a onClick={()=>{
            localStorage.clear();
        window.location.reload(false);
        window.location.href = '/';
          }}>
            <i>
              {' '}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="logout"><g data-name="Layer 2"><g data-name="log-out"><rect width="24" height="24" opacity="0" transform="rotate(90 12 12)"></rect><path d="M7 6a1 1 0 0 0 0-2H5a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2H6V6zM20.82 11.42l-2.82-4a1 1 0 0 0-1.39-.24 1 1 0 0 0-.24 1.4L18.09 11H10a1 1 0 0 0 0 2h8l-1.8 2.4a1 1 0 0 0 .2 1.4 1 1 0 0 0 .6.2 1 1 0 0 0 .8-.4l3-4a1 1 0 0 0 .02-1.18z" fill='#A3AED0'></path></g></g></svg>
            </i>

            <span key='t-dashboards'>Logout</span>
          </a>
        </li>
      </ul>
     </div>
  );
}
