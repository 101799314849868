import { toast } from "react-toastify";
import API from "../../Service/Api";
import { COMPANY_LIST } from "../Constant";

//admin
export const company_listdata = () => async (dispatch) => {
    try {
      var company_list = await API.get('/Getcompanylist');
  
      if (company_list.data.status == 200) {
        dispatch({
          type: COMPANY_LIST,
          payload: company_list.data.data,
        });
      } else {
        toast.warning(company_list.data.data.message);
      }
    } catch (error) {
      toast.warning(error.message);
    }
  };