
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Dealers_list from './Component/Dealers/Dealers_list';
import Dashboard from './Component/Dashboard/Dashboard';
import Vehicles from './Component/Vehicles/Vehicles';
import Live_Bids from './Component/Live_Bids/Live_Bids';
import Accepted_trade_bids from './Component/Accepted_trade_bids/Accepted_trade_bids';
import Underwrites from './Component/Underwrites/Underwrites';
import Buying from './Component/Buying/Buying';
import Archive from './Component/Buying/Archive';
import Transports from './Component/Transport/Transports';
import Manage_admin from './Component/Manage_admin/Manage_admin';
import Login from './Component/Login/Login';
import ForgotPassword from './Component/ForgotPassword/ForgotPassword';
import { ToastContainer, toast } from 'react-toastify';
import Dealer_details from './Component/Dealers/Dealer_details';
import CompanyDetail from './Component/CompanyDetail/CompanyDetail';
import Account from './Component/Account/Account';
import Terms from './Component/Terms/Terms';
import Datatable from './Component/Datatable/Datatable';
import Expired_Bids from './Component/Expired_Bids/Expired_Bids';
import Accepted_Offers from './Component/Accepted_Offers/Accepted_Offers';
import BuyingDetails from './Component/Buying/BuyingDetails';
import DealerVehicles from './Component/Dealers/DealerVehicles';
import DealerLiveBides from './Component/Dealers/DealerLiveBides';
import DealerExpireBides from './Component/Dealers/DealerExpireBides';
import OfferAccepted from './Component/Dealers/OfferAccepted';
import Invoice from './Component/Dealers/Invoice';
import Purchases from './Component/Dealers/Purchases';
import VehicleDetails from './Component/VehicleDetails/VehicleDetails';
import Live_Bid_details from './Component/Live_Bids/Live_Bid_details';
import End_aution_details from './Component/Expired_Bids/End_aution_details';

function App() {
  var authentication = localStorage.getItem('accessToken');
  var user_detail = localStorage.getItem('user_detail');

  return (
    <>
      <Router>

        <Routes>
          <Route path='/terms' element={<Terms />} />
          {/* admin */}
          <Route path='admin/dealers' element={<Dealers_list />} />
          <Route path='/admin/dashboard' element={<Dashboard />} />
          <Route path='admin/vehicles' element={<Vehicles />} />
          <Route path='admin/live-bids' element={<Live_Bids />} />
          <Route path='admin/expired-bids' element={<Expired_Bids />} />
          <Route path='admin/accepted-offers' element={<Accepted_Offers />} />
          <Route path='admin/datatable' element={<Datatable />} />
          <Route path='admin/CompanyDetail' element={<CompanyDetail />} />
          <Route path='/terms' element={<Terms />} />

          
          <Route path='admin/vehicledetails' element={<VehicleDetails />} />
          <Route
            path='admin/accepted-trade-bids'
            element={<Accepted_trade_bids />}
          />
          <Route path='admin/underwrites' element={<Underwrites />} />
          <Route path='admin/buying' element={<Buying />} />
          <Route path='admin/archive' element={<Archive />} />
          <Route path='admin/transports' element={<Transports />} />
          <Route path='admin/manage_admin' element={<Manage_admin />} />
          <Route path='admin/dealer_detail/:id' element={<Dealer_details />} />
          <Route path='admin/account' element={<Account />} />

          <Route path='admin/live-bid-details/:id' element={<Live_Bid_details />} />
          <Route path='admin/end-aution-details/:id' element={<End_aution_details />} />
          

          <Route path='admin/vehicledetail/:id' element={  <BuyingDetails />} />
          <Route path='admin/dealer_vehicles/:id' element={<DealerVehicles />} />
          <Route path='admin/dealer_live_bids/:id' element={<DealerLiveBides />} />
          <Route path='admin/dealer_expire_bids/:id' element={<DealerExpireBides />} />
          <Route path='admin/offer_accepted/:id' element={<OfferAccepted />} />
          <Route path='admin/invoice/:id' element={<Invoice />} />
          <Route path='admin/purchases/:id' element={<Purchases />} />
          {!authentication && (
            <>
              <Route path='/' element={<Login />} />
              {/* <Route path='/' element={<UserLogin />} /> */}

              <Route path='/admin/forgot' element={<ForgotPassword />} />
           
            
            </>
          )}

          {
            !authentication ?
              <Route
                path="*"
                element={<Navigate to="/" />
                }
              /> :

              <Route
                path="*"
                element={<Navigate to="/admin/dashboard" replace />}
              />

          }
          {/* end admin */}







          {/* End User route */}
        </Routes>

      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
