import React from 'react'
import { NavLink } from 'react-router-dom'
import Master from '../Include/Master'

export default function BuyingDetails() {
  return (
    <Master>
         <div className='row'>
          <div className='col-12'>
            <div className='page-title-head d-sm-flex align-items-center justify-content-between'>
              <div className='page-title-head-left d-sm-flex align-items-center justify-content-between'>
                <NavLink to='/admin/dealers'>
                  {' '}
                  <img
                    src={require('../assets/images/Expand_left.svg').default}
                    alt=''
                    className='back_btn'
                  />
                </NavLink>
                <h4 className='mb-sm-0 font-size-34 blue-color fw-700'>
                KE19EFL - Mercedes-Benz A200
                </h4>   
                            
              </div>
             

              <div className='page-title-right'>
              <div className='medium-blue-text-list'>Joined: 26/08/2021</div> 
              </div>
            </div>
          </div>
        </div>
        
        <div className='row'>
        <div className='col-md-12'>
            <div className='card'>
                <div className='card-body'>
                    <div className='card-info'>
                        <div className='card-body-data'>
                            <div className='title_header'>
                                <h3 className='title_main'>Vehicle Details</h3>
                            </div>
                            {/*white card background shadow list  */}
                            <div className='xscroll'>
                                            <div className='row align-items-center'>
                                                <div className='col-sm-5 col-lg-4 col-md-4 col-xl-2 col col-12'>
                                                    <div className='list-img'>
                                                        <img src={require('../assets/images/iphone.png')} height='40' alt=''/>
                                                    </div> 
                                                </div>
                                                <div className='col-sm-5 col-lg-4 col-md-4 col-xl col col-12 mt-sm-4'>
                                                    <div className='car-details-list'>
                                                    <button class="btn btn-progress dashboard-btn-c">KE19EFL</button>
                                                    <span className='medium-blue-text-list'>Mercedes-Benz A200 Hatchback</span>
                                                    <span className='medium-blue-text-list'>1295cc</span>
                                                    <span className='medium-blue-text-list'>34,200 miles</span>
                                                    <span className='medium-blue-text-list'>April 2019</span>
                                                    </div>
                                                </div>
                                            
                                                <div className='col-sm col-lg col-md-6 col-xl col col-12 mt-sm-4'>
                                                    <div className='list-detail-info'>
                                                        <ul>
                                                            <li>Retail Price</li>
                                                            <li>£18,650</li>
                                                        </ul>
                                                        <ul>
                                                            <li>Selling Dealer</li>
                                                            <li>Sandal BMW Leeds</li>
                                                        </ul>
                                                        <ul>
                                                            <li>Contact Name</li>
                                                            <li>Alex Perry</li>
                                                        </ul>
                                                        <ul>
                                                            <li>Contact Email</li>
                                                            <li>sandalsales@sandal .com</li>
                                                        </ul>
                                                        <ul>
                                                            <li>Contact Phone</li>
                                                            <li>020 3880 6050</li>
                                                        </ul>
                                                        
                                                    </div> 
                                                </div>
                                                <div className='col-sm col-lg col-md-6 col-xl col col-12 mt-sm-4 mt-xs-4 mt-4'>
                                                    <div className='button-group-list  d-xl-flex flex-xl-column justify-content-sm-center'>
                                                        <button className='primary-btn-fill-blue '>Go To Dealer Profile</button>
                                                        <button className='secondary-btn-outline-blue'>View Condition Report</button>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='row'>
            <div className='col-md-12'>
            <div className='tabs-btn-right'>
              <ul>
                <li>
                  <NavLink to="#" className="blue-anchor-right">Live Bids</NavLink>
                </li>
                <li>
                  <NavLink to="#" className="white-anchor-right">Expired Bids</NavLink>
                </li>
                <li>
                  <NavLink to="#" className="white-anchor-right">Notes</NavLink>
                </li>              
              </ul>
            </div>
            </div>
        </div>
        <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <h4 className='title_text green-table-heading'>Live Bids 5 <span className='green-dot-show'></span></h4>
                  <span>
                    <div className="medium-blue-text-list">Bidding Closes in 2 Hours</div> 
                    </span>
                </div>

                <div className='table-responsive dealer-table-c'>
                  <table
                    id=''
                    className='table dt-responsive vehicles_table nowrap w-100'
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>CAP Live</th>
                        <th>Cap Monthly</th>
                        <th>Bid Value</th>
                        <th>Bidder</th>
                        <th>Contact Name</th>
                        <th>Contact Email</th>
                        <th>Contact Phone</th>
                      </tr>
                    </thead>

                    <tbody className='td_color sticky-header-c'>
                      <tr>
                        <td>01/01/2023</td>
                        <td>09:30</td>
                        <td>£16,000</td>
                        <td>£16,000</td>
                        <td>£16,600</td>
                        <td>JCT600 Leeds</td>
                        <td>Samuel James</td>
                        <td>samjames@jct600 .com</td>
                        <td>020 3880 2020</td>               
                      </tr>
                      <tr>
                        <td>01/01/2023</td>
                        <td>09:30</td>
                        <td>£16,000</td>
                        <td>£16,000</td>
                        <td>£16,600</td>
                        <td>JCT600 Leeds</td>
                        <td>Samuel James</td>
                        <td>samjames@jct600 .com</td>
                        <td>020 3880 2020</td>               
                      </tr>
                      <tr>
                        <td>01/01/2023</td>
                        <td>09:30</td>
                        <td>£16,000</td>
                        <td>£16,000</td>
                        <td>£16,600</td>
                        <td>JCT600 Leeds</td>
                        <td>Samuel James</td>
                        <td>samjames@jct600 .com</td>
                        <td>020 3880 2020</td>               
                      </tr>
                      <tr>
                        <td>01/01/2023</td>
                        <td>09:30</td>
                        <td>£16,000</td>
                        <td>£16,000</td>
                        <td>£16,600</td>
                        <td>JCT600 Leeds</td>
                        <td>Samuel James</td>
                        <td>samjames@jct600 .com</td>
                        <td>020 3880 2020</td>               
                      </tr>
                      <tr>
                        <td>01/01/2023</td>
                        <td>09:30</td>
                        <td>£16,000</td>
                        <td>£16,000</td>
                        <td>£16,600</td>
                        <td>JCT600 Leeds</td>
                        <td>Samuel James</td>
                        <td>samjames@jct600 .com</td>
                        <td>020 3880 2020</td>               
                      </tr>
                      <tr>
                        <td>01/01/2023</td>
                        <td>09:30</td>
                        <td>£16,000</td>
                        <td>£16,000</td>
                        <td>£16,600</td>
                        <td>JCT600 Leeds</td>
                        <td>Samuel James</td>
                        <td>samjames@jct600 .com</td>
                        <td>020 3880 2020</td>               
                      </tr>
                      <tr>
                        <td>01/01/2023</td>
                        <td>09:30</td>
                        <td>£16,000</td>
                        <td>£16,000</td>
                        <td>£16,600</td>
                        <td>JCT600 Leeds</td>
                        <td>Samuel James</td>
                        <td>samjames@jct600 .com</td>
                        <td>020 3880 2020</td>               
                      </tr>
                     
                     
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  )
}
