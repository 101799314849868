import React from 'react';

export default function ForgotPassword() {
  return (
    <div className='container-fluid p-0'>
      <div className='row g-0'>
        <div className='col-xl-6'>
          <div className='auth-full-page-content p-md-10 p-4'>
            <div className='w-100'>
              <div className='d-flex flex-column h-100'>
                <div className='my-auto'>
                  <div className='mt-4'>
                    <div className='titl_text mb-5'>
                      <h1 className='p-color font-weight-semibold'>
                        Forgot Password
                      </h1>
                      <p className='g-color fw-regular'>
                        Enter your email to change your password.
                      </p>
                    </div>
                    <form action=''>
                      <div className='mb-3'>
                        <label for='username' className='form-label'>
                          Email<span className='blue_text'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='username'
                          placeholder='Enter email'
                        />
                      </div>
                    </form>

                    <div className='mt-4 d-grid'>
                      <a
                        href='#'
                        className='btn btn-primary waves-effect waves-light'
                      >
                        Submit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end col --> */}

        <div className='col-xl-6'>
          <div className='auth-full-bg bg-black pt-lg-5 p-4'>
            <div className='bg-overlay'></div>
          </div>
        </div>
        {/* <!-- end col --> */}
      </div>
      {/* <!-- end row --> */}
    </div>
  );
}
