import React, { useEffect, useState } from 'react';
import Master from '../Include/Master';
import { useDispatch, useSelector } from 'react-redux';
import { getAcceptedoffersaction } from '../../Redux/Action/AcceptedOfferAction';
import moment from 'moment';
import Pagination from '../Include/Pagination';




export default function Accepted_Offers() {
  const [pagno,setPagno]=useState(1)
  const [retail, setretail_data] = useState([]);
  const [retail_filter, setRetail_filter] = useState([]);
  const [name, setName] = useState('');

const dispatch=useDispatch();
useEffect(()=>{
  dispatch(getAcceptedoffersaction({
    pagno:pagno,
    search:name
  }))
},[])

const acceptedoffer=useSelector((state)=>state.Acceptedofferreducer.acceptedoffer);
const pagiantiondata=(pagno)=>{
 
  setPagno(pagno)

  dispatch(getAcceptedoffersaction({
    pagno:pagno,
    search:name
  }))
 

}


const filter = (e) => {
  const keyword = e;

  setName(keyword);

  localStorage.setItem('search',keyword)

  dispatch(getAcceptedoffersaction({
    pagno:pagno,
    search:keyword,
    
  }))
};

const keyPress=(e)=>{
if (e.key === "Enter") {
  filter(name);
}
}



  return (
    <Master>
      {/* start page title */}
      <div className='row'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-end'>
              <div>
                <span className='small_text'>YoTrader </span>
                <h4 className='mb-sm-0 font-size-28'>
               Sold Vehicles <span className='blue_text'>{acceptedoffer?.Totalacceptedoffers}</span>
                </h4>
              </div>             
            </div>

            <div className='page-title-right'>
              {/* App Search */}
              <div className='app-search d-none d-lg-block '>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='position-relative w-100'>
                  <input
                      className='form-control'
                      placeholder='Search'
                      type='search'
                      value={name}

                        onChange={(e)=>{
                          setName(e.target.value)
                        }}
                      onKeyDown={keyPress}
                    />
                    <span className='bx bx-search'></span>
                  </div>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end page title  */}

      <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <h4 className='title_text'>Sold Vehicles</h4>
                  <span>
                    <div className="btn bg_import export-button-m">
                    <a>Export as CSV</a>
                    </div> 
                    </span>
                </div>

                <div className='table-responsive dealer-table-c table-scroll'>
                  <table
                    id=''
                    className='table dt-responsive vehicles_table nowrap w-100'
                  >
                    <thead>
                    <tr>
                        <th>Image</th>
                        <th>VRM</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Mileage</th>
                        <th>Selling Dealer</th>
                        <th>Buyer Dealer</th>

                        <th>Auction Ends</th>
                        <th>Selling Price</th>
                      
                        <th>Seller Fee paid</th>
                        <th>Buyer Fee paid</th>                       
                     
                      </tr>
                    </thead>

                    <tbody className='td_color sticky-header-c tbody-height-fix table-scroll scroll-bottom-none'>
                    
                     {
                      
                      
                      acceptedoffer?.Acceptedoffers?.length==0?<tr className='placholder-text-height'>
                      <td>
                      <p className='placeholdet-text-table'>No data found</p>
                      </td>
                     </tr>:



                      acceptedoffer?.Acceptedoffers?.map(function(acceptedoffer,i){
                        return(
                          <tr key={i} className="table-row-part">
                          <td>
                          <img
                            src={acceptedoffer.images[2]}
                            height='40'
                            alt=''
                          />
                        </td>
                        <td>{acceptedoffer.vrm}</td>
                        <td>{acceptedoffer.make}</td>
                        <td>{acceptedoffer.model}</td>

                        <td>{   new Intl.NumberFormat('en-GB').format(acceptedoffer.mileage).replace(/\.00$/, '') }</td>
                      
                        <td>{acceptedoffer.buyvehicles.sellerdetails.company_name} <img src={require('../assets/images/info.svg').default} className='table-img-info' alt=''/></td>
                        <td>{acceptedoffer.buyvehicles.buyperdetails.company_name}</td>
                       
                        <td>
                        <div className="flex-div">
                        { moment(acceptedoffer.expire_off).format('DD-MM-YYYY H:mm')}
                        </div>
                        </td>
                        <td>{   new Intl.NumberFormat('en-GB',{   style: 'currency',  currency: 'GBP',}).format(acceptedoffer?.buyvehicles?.saleprice).replace(/\.00$/, '') }</td>

                        <td>{acceptedoffer.buyvehicles.delivered_saler==0?"No":"Yes"}</td>
                        <td>{acceptedoffer.buyvehicles.delivered_buyer==0?"No":"Yes"}</td>
                  

                              
                      </tr>
                        )
                      })
                     }                    
                     
                   
                    </tbody>
                  </table>
                </div>
                <div className='pagination-wrapper custom-pagination-b'>
                    <Pagination  totalpage={acceptedoffer?.total_page}  totalexpirebids={acceptedoffer?.Totalacceptedoffers}  pagiantiondata={pagiantiondata} />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}
