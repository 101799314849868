import { toast } from "react-toastify";
import API from "../../Service/Api";
import { ARCHIVE_LIST } from "../Constant";


export const GetArchive_get =(data)=>async(dispatch)=>{

        try {
            var archive_data = await API.post(
              `/GetArchive`,data
            );
        
            console.log(archive_data);
            if (archive_data.data.status == 200) {
           
            
              dispatch({
                type: ARCHIVE_LIST,
                payload: archive_data.data.data,
              });
            } else {
              toast.warning(archive_data.data.message);
            }
          } catch (error) {
            toast.warning(error.message);
          }
  
}