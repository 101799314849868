import React from 'react';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

export default function Master(props) {
  return (
    <div id='layout-wrapper'>
      <header id='page-topbar'>
        <Header />
      </header>

      <div className='vertical-menu'>
        <div data-simplebar='' className='h-100'>
          <Sidebar />
        </div>
      </div>

      <div className='main-content'>
        <div className='page-content'>
          <div className='container-fluid'>{props.children}</div>
        </div>
      </div>
    </div>
  );
}
