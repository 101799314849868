import {
  APP_VERSION_LIST,
  DASHBOARD_DATA,
  PROJECT_DETAIL,
  PROJECT_LIST,
  USER_LIST,
  USER_LOGIN_REQUEST,
} from '../Constant';

var initialValues = {
  user_list_data: [],
  project_list_data: [],
  project_detail_data: '',
  app_version: [],
  dashboard_data: [],
};

const authreducer = (state = initialValues, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { userInfo: action.payload, error: action.error };
    case USER_LIST:
      return {
        ...state,
        user_list_data: action.payload,
      };
    case PROJECT_LIST:
      return {
        ...state,
        project_list_data: action.payload,
      };
    case PROJECT_DETAIL:
      return {
        ...state,
        project_detail_data: action.payload,
      };
    case APP_VERSION_LIST:
      return {
        ...state,
        app_version: action.payload,
      };
    case DASHBOARD_DATA:
      return {
        ...state,
        dashboard_data: action.payload,
      };
    default:
      return state;
  }
};
export default authreducer;
