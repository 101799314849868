import React, { useEffect } from 'react'
import Master from '../Include/Master'
import { NavLink, useParams } from 'react-router-dom'
import { Carousel, Nav } from 'react-bootstrap'
import CryptoJS from 'crypto-js';
import { useDispatch, useSelector } from 'react-redux';
import { liveVehiclesDetailsAuction } from '../../Redux/Action/LiveBidsAuction';
import moment from 'moment';
import Countdown from './Countdown';
export default function Live_Bid_details() {
    const {id}=useParams();
    const dispatch=useDispatch();
   const livebidsid= CryptoJS.AES.decrypt(id, 'your-secret-key').toString(CryptoJS.enc.Utf8);
   console.log(livebidsid)

   useEffect(()=>{
    dispatch(liveVehiclesDetailsAuction(livebidsid))
   },[livebidsid])


   
   const live_auction_details=useSelector((state)=>state.liveBidsreducer.live_auction_details);
   console.log(live_auction_details)

  return (
    <Master>
         <div className='row'>
          <div className='col-12'>
            <div className='page-title-head d-sm-flex align-items-center justify-content-between'>
              <div className='page-title-head-left d-sm-flex align-items-center justify-content-between'>
                <NavLink to={`/admin/live-bids`}>
                  {' '}
                  <img
                    src={require('../assets/images/Expand_left.svg').default}
                    alt=''
                    className='back_btn'
                  />
                </NavLink>
                <h4 className='mb-sm-0 font-size-34 blue-color fw-700'>
                {live_auction_details?.vrm} -   {live_auction_details?.newvaluationdetails?.make+ " "}{live_auction_details?.newvaluationdetails?.model}

                </h4>                               
              </div>             

              <div className='page-title-right'>
              <div className='medium-blue-text-list'>Joined:   {moment( live_auction_details?.createdAt).format('DD/MM/yyyy')}</div> 
              </div>
            </div>
          </div>
        </div>

        <div className='detail-card'>
        <h4 className="title_text">Vehicle Details</h4>
          <ul className='colum-row'>
            <li>
            <div className='img-colum-part slider-img-details'>
              {/* <img  src={endauctiondetails?.images[2]} alt='' /> */}
              <Carousel data-bs-theme="dark">
              {
                live_auction_details?.newvaluationdetails?.images?.map(function(imageobject,i){
                  return(
                    <Carousel.Item key={i}>
                    <img
                      className="d-block w-100"
                      src={imageobject}
                      alt="First slide"
                    />     
                  </Carousel.Item>
                  )
                })
              }
                  

    </Carousel>

             
              </div>
            </li>
            <li>
              <div className='vehicle-details-info'>
              <div className='vrm-yellow'>
                <span></span>
                <div className='vrm-number-show'>  {live_auction_details?.vrm}</div>
              </div>
              <div className='blue-text-normal-line'>{live_auction_details?.newvaluationdetails?.make+ " "}{live_auction_details?.newvaluationdetails?.model}</div>
              <div className='blue-text-normal-line'>{live_auction_details?.newvaluationdetails?.engine_size}cc</div>
              <div className='blue-text-normal-line'>{new Intl.NumberFormat('en-GB')
              .format(live_auction_details?.newvaluationdetails?.mileage)
              .replace(/\.00$/, '')} Miles</div>
              <div className='blue-text-normal-line'>{live_auction_details?.newvaluationdetails?.year??""}</div>
              <div className='blue-text-normal-line'>Retail Price: {new Intl.NumberFormat('en-GB', {
                                              style: 'currency',
                                              currency: 'GBP',
                                            })
                                              .format(live_auction_details?.vehicle_id?.price)
                                              .replace(/\.00$/, '')}</div>
              </div>
            </li>
            <li>
              <div className='vehicle-details-info'>
              <ul>
                <li className='blue-text-normal-line'>
                <div className='flex-colum-text'>
                  <p>Buy it Now</p>
                  <p>{new Intl.NumberFormat('en-GB', {
                                              style: 'currency',
                                              currency: 'GBP',
                                            })
                                              .format(live_auction_details?.newvaluationdetails?.price_now)
                                              .replace(/\.00$/, '')}</p>
                </div>
                </li>

                <li className='blue-text-normal-line'>
                <div className='flex-colum-text'>
                  <p>Reserve Price</p>
                  <p>{live_auction_details?.newvaluationdetails?.price_reserve==undefined?"----":   new Intl.NumberFormat('en-GB', {
                                              style: 'currency',
                                              currency: 'GBP',
                                            })
                                              .format(live_auction_details?.newvaluationdetails?.price_reserve)
                                              .replace(/\.00$/, '')}</p>
                </div>
                </li>

                <li className='blue-text-normal-line'>
                <div className='flex-colum-text'>
                  <p>Selling Dealer</p>
                  <p>{live_auction_details?.user_detail?.company_name}</p>
                </div>
                </li>

                <li className='blue-text-normal-line'>
                <div className='flex-colum-text'>
                  <p>Contact Name</p>
                  <p>{live_auction_details?.user_detail?.name}</p>
                </div>
                </li>

                <li className='blue-text-normal-line'>
                <div className='flex-colum-text'>
                  <p>Contact Email</p>
                  <p>{live_auction_details?.user_detail?.email}</p>
                </div>
                </li>

                <li className='blue-text-normal-line'>
                <div className='flex-colum-text'>
                  <p>Contact Phone</p>
                  <p>{live_auction_details?.user_detail?.phone_number}</p>
                </div>
                </li>
              </ul>       
        
              </div>
            </li>
            <li >
              <div className='button-colum-part'>
              <ul>
                <li>
                  <NavLink to={"/admin/dealer_detail/"+live_auction_details?.user_detail?._id} className="white-btn-primary-fill">Go To Dealer Profile</NavLink>
                </li>
                <li>
                  <NavLink to="" className="blue-btn-primary-fill">View Live Auction</NavLink>
                </li>
              </ul>
              </div>
            </li>
          </ul>    
        </div>  

        <div className='row mt-4'>
          <div className='col-md-12'>
            <div className='card'>
              <div className='card-body'>
              <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <h4 className='green-color-heading-table d-flex align-items-center'>Live {(live_auction_details?.yotraderbids?.length<=1)?" Bid ":" Bids "} <span>{live_auction_details?.yotraderbids?.length}</span> <div className='green-dot-circle'></div></h4>                 
                  <div className='right-part-icon'>
                  <div className='time-end-text'>
                    <p>Auction Ends</p>
                    <p><Countdown endDate={live_auction_details?.newvaluationdetails?.offer_expire}  /></p>
                  </div>
                  {/* <img src={require('../assets/images/time-progress.svg').default}  alt='' className='time-progress' /> */}
                  
                  <img src={require('../assets/images/time-progress-green.svg').default}  alt='' className='time-progress' />
                  </div>
              </div> 
              <div className='table-responsive'>
                  <table
                    id='myTable'
                    className='table  dt-responsive manadmin_table nowrap w-100'
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>CAP Live</th>
                        {/* <th>Cap Monthly</th>
                        <th>Bid Value</th> */}
                        <th>Bidder</th>
                        <th>Contact Name</th>
                        <th>Contact Email</th>
                        <th>Contact Phone</th>
                      </tr>
                    </thead>

                    <tbody className='td_color table-scroll'>

                    {
                      live_auction_details?.yotraderbids?.map(function(biders_object,i){
                        return(
                      
                      <tr key={i}>
                        <td>{moment( biders_object?.createdAt).format('DD/MM/yyyy')}</td>
                        <td>{moment( biders_object?.createdAt).format('HH:MM')}</td>
                      
                        <td>{new Intl.NumberFormat('en-GB', {
                                              style: 'currency',
                                              currency: 'GBP',
                                            })
                                              .format(biders_object?.yotrader_value)
                                              .replace(/\.00$/, '')}</td>
                        <td>{    biders_object?.bidsfrom==undefined?   biders_object?.dealersbids?.company_name:biders_object?.bidsfrom=="dealer"?biders_object?.dealersbids?.company_name:biders_object?.bidsfrom}</td>
                       
                       
                        <td>{    biders_object?.bidsfrom==undefined?   biders_object?.dealersbids?.name:biders_object?.bidsfrom=="dealer"?biders_object?.dealersbids?.name:biders_object?.bidsfrom=="G3"?"G3":"The Car Buying Group"}</td>
                       
                        <td>{    biders_object?.bidsfrom==undefined?   biders_object?.dealersbids?.name:biders_object?.bidsfrom=="dealer"?biders_object?.dealersbids?.name:biders_object?.bidsfrom=="G3"?"info@g3r.co.uk":"info@thecarbuyinggroup.co.uk"}</td>
                        <td>{    biders_object?.bidsfrom==undefined?   biders_object?.dealersbids?.phone_number:biders_object?.bidsfrom=="dealer"?biders_object?.dealersbids?.phone_number:biders_object?.bidsfrom=="G3"?"+(44) 01132014444":"0333 3201 614"}</td>
                       
                       
                      </tr>
                          
                      )
                      })
                    }
                    </tbody>
                  </table>
          </div> 
              </div>
            </div>
          </div>
        </div>
         
    </Master>
  )
}
