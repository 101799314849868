
export const distance_from_retail = [
    {
    id: 0,
    distance_from_retail: 0,
    distance_from_retail_data: 'Blank',
    checked: true,
  },
  {
    id: 0,
    distance_from_retail: '1-1000',
    distance_from_retail_data: '1-1,000',
    checked: true,
  },
  {
    id: 1,
    distance_from_retail: '1000-3000',
    distance_from_retail_data: '1,000-3,000',
    checked: true,
  },
  {
    id: 2,
    distance_from_retail: '3000-5000',
    distance_from_retail_data: '3,000-5,000',
    checked: true,
  },
  {
    id: 3,
    distance_from_retail: '5000-7000',
    distance_from_retail_data: '5,000-7,000',
    checked: true,
  },
  {
    id: 4,
    distance_from_retail: '7000-10000',
    distance_from_retail_data: '7,000-10,000',
    checked: true,
  },
  {
    id: 5,
    distance_from_retail: '10000-10000000000',
    distance_from_retail_data: '10,000+',
    checked: true,
  },
];

export const county_list = [
     { id: 0, checked: true },
  { id: 1, checked: true, county_select: 'AL St. Albans, Hertfordshire', county: 'AL' },
  { id: 2, checked: true, county_select: 'CM Chelmsford, Essex', county: 'CM' },
  { id: 3, checked: true, county_select: 'CO Colchester, Essex', county: 'CO' },
  { id: 4, checked: true, county_select: 'EN Enfield, Middlesex', county: 'EN' },
  { id: 5, checked: true, county_select: 'IG Ilford and Barking, Essex', county: 'IG' },
  { id: 6, checked: true, county_select: 'IP Ipswich, Suffolk', county: 'IP' },
  { id: 7, checked: true, county_select: 'MK Milton Keynes, Buckinghamshire', county: 'MK' },
  { id: 8, checked: true, county_select: 'NR Norwich, Norfolk', county: 'NR' },
  { id: 9, checked: true, county_select: 'PE Peterborough, Cambridgeshire', county: 'PE' },
  { id: 10, checked: true, county_select: 'RM Romford, Essex', county: 'RM' },
  { id: 11, checked: true, county_select: 'SG Stevenage, Hertfordshire', county: 'SG' },
  { id: 12, checked: true, county_select: 'S Southend-on-Sea, Essex', county: 'S' },
  { id: 13, checked: true, county_select: 'WD Watford, Hertfordshire', county: 'WD' },
  { id: 14, checked: true, county_select: 'CB Cambridge, Cambridgeshire', county: 'CB' },
  { id: 15, checked: true, county_select: 'LU Luton, Bedfordshire', county: 'LU' },
  { id: 16, checked: true, county_select: 'B Birmingham, West Midlands', county: 'B' },
  { id: 17, checked: true, county_select: 'CV Coventry, West Midlands', county: 'CV' },
  { id: 18, checked: true, county_select: 'DE Derby, Derbyshire', county: 'DE' },
  { id: 19, checked: true, county_select: 'DY Dudley, West Midlands', county: 'DY' },
  { id: 20, checked: true, county_select: 'LE Leicester, Leicestershire', county: 'LE' },
  { id: 21, checked: true, county_select: 'NG Nottingham, Nottinghamshire', county: 'NG' },
  { id: 22, checked: true, county_select: 'NN Northampton, Northamptonshire', county: 'NN' },
  { id: 23, checked: true, county_select: 'ST Stoke-on-Trent, Staffordshire', county: 'ST' },
  { id: 24, checked: true, county_select: 'WS Walsall, West Midlands', county: 'WS' },
  { id: 25, checked: true, county_select: 'WV Wolverhampton, West Midlands', county: 'WV' },
  { id: 26, checked: true, county_select: 'BD Bradford, West Yorkshire', county: 'BD' },
  { id: 27, checked: true, county_select: 'DH Durham, County Durham', county: 'DH' },
  { id: 28, checked: true, county_select: 'DL Darlington, North Yorkshire', county: 'DL' },
  { id: 29, checked: true, county_select: 'DN Doncaster, South Yorkshire', county: 'DN' },
  { id: 30, checked: true, county_select: 'HD Huddersfield, West Yorkshire', county: 'HD' },
  { id: 31, checked: true, county_select: 'HG Harrogate, North Yorkshire', county: 'HG' },
  { id: 32, checked: true, county_select: 'HU Hull, North Humberside', county: 'HU' },
  { id: 33, checked: true, county_select: 'HX Halifax, West Yorkshire', county: 'HX' },
  { id: 34, checked: true, county_select: 'LN Lincoln, Lincolnshire', county: 'LN' },
  { id: 35, checked: true, county_select: 'LS Leeds, West Yorkshire', county: 'LS' },
  { id: 36, checked: true, county_select: 'NE Newcastle, Tyne and Wear', county: 'NE' },
  { id: 37, checked: true, county_select: 'S Sheffield, South Yorkshire', county: 'S' },
  { id: 38, checked: true, county_select: 'SR Sunderland, Tyne and Wear', county: 'SR' },
  { id: 39, checked: true, county_select: 'TS Teesside, Cleveland', county: 'TS' },
  { id: 40, checked: true, county_select: 'WF Wakefield, West Yorkshire', county: 'WF' },
  { id: 41, checked: true, county_select: 'YO York, North Yorkshire', county: 'YO' },
  { id: 42, checked: true, county_select: 'BB Blackburn, Lancashire', county: 'BB' },
  { id: 43, checked: true, county_select: 'BL Blackpool, Lancashire', county: 'BL' },
  { id: 44, checked: true, county_select: 'CA Carlisle, Cumbria', county: 'CA' },
  { id: 45, checked: true, county_select: 'CW Crewe, Cheshire', county: 'CW' },
  { id: 46, checked: true, county_select: 'FY Blackpool, Lancashire', county: 'FY' },
  { id: 47, checked: true, county_select: 'L Liverpool, Merseyside', county: 'L' },
  { id: 48, checked: true, county_select: 'LA Lancaster, Lancashire', county: 'LA' },
  { id: 49, checked: true, county_select: 'M Manchester, Lancashire', county: 'M' },
  { id: 50, checked: true, county_select: 'OL Oldham, Lancashire', county: 'OL' },
  { id: 51, checked: true, county_select: 'PR Preston, Lancashire', county: 'PR' },
  { id: 52, checked: true, county_select: 'SK Stockport, Cheshire', county: 'SK' },
  { id: 53, checked: true, county_select: 'SY Shrewsbury, Shropshire', county: 'SY' },
  { id: 54, checked: true, county_select: 'TF Telford, Shropshire', county: 'TF' },
  { id: 55, checked: true, county_select: 'WA Warrington, Cheshire', county: 'WA' },
  { id: 56, checked: true, county_select: 'WN Wigan, Lancashire', county: 'WN' },
  { id: 57, checked: true, county_select: 'CH Chester, Cheshire', county: 'CH' },
  { id: 58, checked: true, county_select: 'E East London', county: 'E' },
  { id: 59, checked: true, county_select: 'EC City of London', county: 'EC' },
  { id: 60, checked: true, county_select: 'N North London', county: 'N' },
  { id: 61, checked: true, county_select: 'NW North West London"', county: 'NW' },
  { id: 62, checked: true, county_select: 'SE South East London', county: 'SE' },
  { id: 63, checked: true, county_select: 'SW South West London', county: 'SW' },
  { id: 64, checked: true, county_select: 'W West End, London', county: 'W' },
  { id: 65, checked: true, county_select: 'WC West Central London', county: 'WC' },
  { id: 66, checked: true, county_select: 'GU Guildford, Surrey', county: 'GU' },
  { id: 67, checked: true, county_select: 'HA Harrow, Middlesex', county: 'HA' },
  { id: 68, checked: true, county_select: 'HP Hemel Hempstead, Hertfordshire', county: 'HP' },
  { id: 69, checked: true, county_select: 'OX Oxford, Oxfordshire', county: 'OX' },
  { id: 70, checked: true, county_select: 'PO Portsmouth, Hampshire', county: 'PO' },
  { id: 71, checked: true, county_select: 'RG Reading, Berkshire', county: 'RG' },
  { id: 72, checked: true, county_select: 'SL Slough, Buckinghamshire', county: 'SL' },
  { id: 73, checked: true, county_select: 'SN Swindon, Wiltshire', county: 'SN' },
  { id: 74, checked: true, county_select: 'SO Southampton, Hampshire', county: 'SO' },
  { id: 75, checked: true, county_select: 'SP Salisbury, Wiltshire', county: 'SP' },
  { id: 76, checked: true, county_select: 'UB Southall and Uxbridge, Middlesex', county: 'UB' },
  { id: 77, checked: true, county_select: 'BN Brighton, East Sussex', county: 'BN' },
  { id: 78, checked: true, county_select: 'BR Bromley, Kent', county: 'BR' },
  { id: 79, checked: true, county_select: 'CR Croydon, Surrey', county: 'CR' },
  { id: 80, checked: true, county_select: 'CT Canterbury, Kent', county: 'CT' },
  { id: 81, checked: true, county_select: 'DA Dartford, Kent', county: 'DA' },
  { id: 82, checked: true, county_select: 'KT Kingston-upon-Thames, Surrey', county: 'KT' },
  { id: 83, checked: true, county_select: 'ME Medway, Kent', county: 'ME' },
  { id: 84, checked: true, county_select: 'RH Redhill, Surrey', county: 'RH' },
  { id: 85, checked: true, county_select: 'SM Sutton, Surrey', county: 'SM' },
  { id: 86, checked: true, county_select: 'TN Tonbridge, Kent', county: 'TN' },
  { id: 87, checked: true, county_select: 'TW Twickenham, Middlesex', county: 'TW' },
  { id: 88, checked: true, county_select: 'BA Bath, Avon', county: 'BA' },
  { id: 89, checked: true, county_select: 'BS Bristol, Avon', county: 'BS' },
  { id: 90, checked: true, county_select: 'GL Gloucester, Gloucestershire', county: 'GL' },
  { id: 91, checked: true, county_select: 'HR Hereford, Herefordshire', county: 'HR' },
  { id: 92, checked: true, county_select: 'WR Worcester, Worcestershire', county: 'WR' },
  { id: 93, checked: true, county_select: 'TR Truro, Cornwall', county: 'TR' },
  { id: 94, checked: true, county_select: 'TQ Torquay, Devon', county: 'TQ' },
  { id: 95, checked: true, county_select: 'TD Galashiels, Selkirkshire', county: 'TD' },
  { id: 96, checked: true, county_select: 'TA Taunton, Somerset', county: 'TA' },
  { id: 97, checked: true, county_select: 'SA Swansea, West Glamorgan', county: 'SA' },
  { id: 98, checked: true, county_select: 'PL Plymouth, Devon', county: 'PL' },
  { id: 99, checked: true, county_select: 'PH Perth, Perthshire', county: 'PH' },
  { id: 100, checked: true, county_select: 'PA Paisley, Renfrewshire', county: 'PA' },
  { id: 101, checked: true, county_select: 'NP Newport, Gwent', county: 'NP' },
  { id: 102, checked: true, county_select: 'ML Motherwell, Lanarkshire', county: 'ML' },
  { id: 103, checked: true, county_select: 'LL Llandudno, Clwyd', county: 'LL' },
  { id: 104, checked: true, county_select: 'LD Llandrindod Wells', county: 'LD' },
  { id: 105, checked: true, county_select: 'KY Kirkcaldy, Fife', county: 'KY' },
  { id: 106, checked: true, county_select: 'KA Kilmarnock and Ayr, Ayrshire', county: 'KA' },
  { id: 107, checked: true, county_select: 'IV Inverness', county: 'IV' },
  { id: 108, checked: true, county_select: 'G Glasgow, Lanarkshire', county: 'G' },
  { id: 109, checked: true, county_select: 'FK Falkirk, Stirlingshire', county: 'FK' },
  { id: 110, checked: true, county_select: 'EX Exeter, Devon', county: 'EX' },
  { id: 111, checked: true, county_select: 'EH Edinburgh, Midlothian', county: 'EH' },
  { id: 112, checked: true, county_select: 'DT Dorchester, Dorset', county: 'DT' },
  { id: 113, checked: true, county_select: 'DG Dumfries and Galloway', county: 'DG' },
  { id: 114, checked: true, county_select: 'DD Dundee, Angus', county: 'DD' },
  { id: 115, checked: true, county_select: 'CF Cardiff, South Glamorgan', county: 'CF' },
  { id: 116, checked: true, county_select: 'BT Belfast', county: 'BT' },
  { id: 117, checked: true, county_select: 'BH Bournemouth, Dorset', county: 'BH' },
  { id: 118, checked: true, county_select: 'AB Aberdeen', county: 'AB' },
  { id: 119, checked: true, county_select: 'IM Isle of Man', county: 'IM' },
];

export const retail_list = [
  {
    id: 0,
    retail: '0-10000',
    retail_data: '£0 - £10,000',
    checked: true,
  },
  {
    id: 1,
    retail: '10000-15000',
    retail_data: '£10,000 - £15,000',
    checked: true,
  },
  {
    id: 2,
    retail: '15000-20000',
    retail_data: '£15,000 - £20,000',
    checked: true,
  },
  {
    id: 3,
    retail: '20000-25000',
    retail_data: '£20,000 - £25,000',
    checked: true,
  },
  {
    id: 4,
    retail: '25000-30000',
    retail_data: '£25,000 - £30,000',
    checked: true,
  },
  {
    id: 5,
    retail: '30000-40000',
    retail_data: '£30,000 - £40,000',
    checked: true,
  },
  {
    id: 6,
    retail: '40000-50000',
    retail_data: '£40,000 - £50,000',
    checked: true,
  },
  {
    id: 7,
    retail: '50000-5000000000',
    retail_data: 'Over £50,000',
    checked: true,
  },
];

export const year_list = [
    { id: 0, checked: true, year_select: 'Deselect', year: 'Select All' },
  { id: 1, checked: true, year_select: '2022', year: '2022' },
  { id: 2, checked: true, year_select: '2021', year: '2021' },
  { id: 3, checked: true, year_select: '2020', year: '2020' },
  { id: 4, checked: true, year_select: '2019', year: '2019' },
  { id: 5, checked: true, year_select: '2018', year: '2018' },
  { id: 6, checked: true, year_select: '2017', year: '2017' },
  { id: 7, checked: true, year_select: '2016', year: '2016' },
  { id: 8, checked: true, year_select: '2015', year: '2015' },
  { id: 9, checked: true, year_select: '2014', year: '2014' },
  { id: 10, checked: true, year_select: '2013', year: '2013' },
  { id: 11, checked: true, year_select: '2012', year: '2012' },
  { id: 12, checked: true, year_select: '2011', year: '2011' },
  { id: 13, checked: true, year_select: '2010', year: '2010' },
  { id: 14, checked: true, year_select: '2009', year: '2009' },
  { id: 15, checked: true, year_select: '2008', year: '2008' },
  { id: 16, checked: true, year_select: '2007', year: '2007' },
  { id: 17, checked: true, year_select: '2006', year: '2006' },
  { id: 18, checked: true, year_select: '2005', year: '2005 and older' },
];

export const body_style = [
  { id: 0, checked: true, body_style_obj: 'Blank' },
  { id: 1, checked: true, body_style_obj: 'City-Car' },
  { id: 2, checked: true, body_style_obj: 'Convertible' },
  { id: 3, checked: true, body_style_obj: 'Coupe' },
  { id: 4, checked: true, body_style_obj: 'Cross Over' },
  { id: 4, checked: true, body_style_obj: 'Estate' },
  { id: 4, checked: true, body_style_obj: 'Hatchback' },
  { id: 4, checked: true, body_style_obj: 'Minibus' },
  { id: 4, checked: true, body_style_obj: 'MPV' },
  { id: 4, checked: true, body_style_obj: 'Pickup' },
  { id: 4, checked: true, body_style_obj: 'Saloon' },
  { id: 4, checked: true, body_style_obj: 'Sports' },
  { id: 4, checked: true, body_style_obj: 'SUV' },
];

export const mileage_list = [
  { id: 0, milage: '0-9999', milage_data: '0-9,999', checked: true },
  {
    id: 1,
    milage: '10000-19999',
    milage_data: '10,000-19,999',
    checked: true,
  },
  {
    id: 2,
    milage: '20000-39999',
    milage_data: '20,000-39,999',
    checked: true,
  },
  {
    id: 3,
    milage: '40000-59999',
    milage_data: '40,000-59,999',
    checked: true,
  },
  {
    id: 4,
    milage: '60000-79999',
    milage_data: '60,000-79,999',
    checked: true,
  },
  { id: 5, milage: '80000-800000000', milage_data: '80,000+', checked: true },
];

export const Stocking = [
     {checked: true },
  { stocking: '0', checked: true },
  { stocking: '1', checked: true },
  { stocking: '2', checked: true },
  { stocking: '3', checked: true },
  { stocking: '4', checked: true },
  { stocking: '5', checked: true },
  { stocking: '6', checked: true },
  { stocking: '7', checked: true },
  { stocking: '8', checked: true },
  { stocking: '9', checked: true },
];

export const stocking_list = [
    {checked:true},
  { id: 0, stocking: '0-10', stocking_data: '0-10', checked: true },
  { id: 0, stocking: '10-20', stocking_data: '10-20', checked: true },
  { id: 0, stocking: '20-30', stocking_data: '20-30', checked: true },
  { id: 0, stocking: '30-40', stocking_data: '30-40', checked: true },
  { id: 0, stocking: '40-60', stocking_data: '40-60', checked: true },
  { id: 0, stocking: '60-90', stocking_data: '60-90', checked: true },
  { id: 0, stocking: '90-1000000', stocking_data: '90+', checked: true },
];
