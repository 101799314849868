import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Accountlistdata } from '../../Redux/Action/AccountAction';
import Master from '../Include/Master';
import Moment from 'react-moment';
import { Get_dealer_list } from '../../Redux/Action/DealerAction';
export default function Account() {
  const dispatch = useDispatch();

  return (
    <Master>
      {/* <!-- start page title --> */}
      <div className='row'>
        <div className='col-12'>
          <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <div>
              <span className='small_text'>YoTrader </span>
              <h4 className='mb-sm-0 font-size-28'>Accounts</h4>
            </div>
            <div className='page-title-right'>
              {/* <!-- App Search--> */}
              {/* <form className='app-search d-none d-lg-block '>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='position-relative w-100'>
                    <input
                      className='form-control'
                      placeholder='Search'
                      type='search'
                    />
                    <span className='bx bx-search'></span>
                  </div>
                  <img
                    src={require('../assets/images/avatar.svg').default}
                    className='avata_serch'
                    alt=''
                  />
                </div>
              </form> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end page title --> */}

      <div className='form-header mb-5'>
      <div className='row'>
        <div className='col-md-3'>
        <div className="form-group">
          <input type="text" className="form-control input-style" placeholder="Search Here" />
        </div>
        </div>
        <div className='col-md-2'>
        <div className="form-group">
        <input type="date" className="form-control input-style" ></input>  
        </div>
        </div>               
        <div className='col-md-2'>
        <div className='total-data input-style'>Total: £10,549.00</div>
        </div>
        <div className='col-md-5 end-btn-side'>
          <button type='button' className='dark-blue-btn '>Export</button>
        </div>
      </div>
    </div> 

      <div className='row'>
        <div className='col-xl-12'>
          <div className='tbl_user_info'>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative'>
                  <div className='d-flex'>
                    <h4 className='title_text'>Transactions</h4>
                  </div>
                </div>
                <div className='table-responsive new_table_react buying_table nowrap w-100  no-footer  '>
                  <DataTable   />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}
