import {
  DEALER_VEHICLES_DATA,
  TRANPORT_VEHICLES_LIST,
  VEHICLES_LIST,
  UPDATE_COLUMN_VALUE,
  UPDATE_COLUMN_BID_VALUE,
  BUYING_DROP_LIST,
  BUYING_PAGINATION,
} from '../Constant';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../Service/Api';

export const vehicles_list = (data_filter) => async (dispatch) => {
  try {
    var vehicles_data = await API.post('/Vehicle_list', data_filter);

    if (vehicles_data.data.status == 200) {
      dispatch({
        type: VEHICLES_LIST,
        payload: vehicles_data.data.data,
      });
    } else {
      toast.warning(vehicles_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const importcsvfile = (import_file) => async (dispatch) => {
  try {
    var vehicles_data = await API.post('/import_vehicle', import_file);
    console.log(vehicles_data.data.data, 'excel');
    if (vehicles_data.data.status == 200) {
      // dispatch({
      //   type: VEHICLES_LIST,
      //   payload: vehicles_data.data.data,
      // });
      toast.success(vehicles_data.data.message);
    } else {
      toast.warning(vehicles_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const filtervehicle = () => async (dispatch) => {
  try {
    var dealer_detail_data = await API.get(`/filtervehicle`);

    if (dealer_detail_data.data.status == 200) {
      //toast.success(dealer_detail_data.data.message);

      dispatch({
        type: DEALER_VEHICLES_DATA,
        payload: dealer_detail_data.data.data,
      });
    } else {
      toast.warning(dealer_detail_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const upfatecsvfile = (import_file) => async (dispatch) => {
  try {
    var vehicles_data = await API.post('/Updatevehicle', import_file);
    console.log(vehicles_data.data.data, 'excel');
    if (vehicles_data.data.status == 200) {
      // dispatch({
      //   type: VEHICLES_LIST,
      //   payload: vehicles_data.data.data,
      // });
      return vehicles_data.data.status;
      toast.success(vehicles_data.data.message);
    } else {
      toast.warning(vehicles_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const updatebidvalue_data = (bid_value) => async (dispatch) => {
  try {
    var dealer_detail_data = await API.post(`/updatebidvalue`, bid_value);
    console.log(dealer_detail_data,dealer_detail_data.data, 'dealer_detail_data22');
    if (dealer_detail_data.data.status == 200) {
      //toast.success(dealer_detail_data.data.message);
      console.log(dealer_detail_data,dealer_detail_data.data, 'dealer_detail_data224');
      dispatch({
        type: UPDATE_COLUMN_BID_VALUE,
        payload: dealer_detail_data.data,
      });

      toast.success(dealer_detail_data.data.message);
    } else {
      toast.warning(dealer_detail_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

//bulkmail

export const Sendbulkmail = () => async (dispatch) => {
  try {
    var dealer_detail_data = await API.get(`/Sendbulkmail`);
    if (dealer_detail_data.data.status == 200) {
      //toast.success(dealer_detail_data.data.message);
      toast.success(dealer_detail_data.data.message);
    } else {
      toast.warning(dealer_detail_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const updatevehicleinfo = (vehicle_info) => async (dispatch) => {
  try {
    var dealer_detail_data = await API.post(`/updatevehicleinfo`, vehicle_info);

    if (dealer_detail_data.data.status == 200) {
      //toast.success(dealer_detail_data.data.message);
      //  toast.success(dealer_detail_data.data.message);

      dispatch({
        type: UPDATE_COLUMN_VALUE,
        payload: dealer_detail_data.data,
      });
    } else {
      toast.warning(dealer_detail_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const SendPurchesMail = (id) => async (dispatch) => {
  try {
    var dealer_detail_data = await API.post(`/SendPurchesMail`, id);
    console.log(dealer_detail_data, 'SendPurchesMail');
    if (dealer_detail_data.data.status == 200) {
      dispatch({
        type: UPDATE_COLUMN_VALUE,
        payload: dealer_detail_data.data,
      });
      //toast.success(dealer_detail_data.data.message);
      toast.success(dealer_detail_data.data.message);
    } else {
      toast.warning(dealer_detail_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const Getvehicellist = () => async (dispatch) => {
  try {
    var vehicles_data = await API.get('/Getvehicellist');
    console.log(vehicles_data, 'lllllllllllll');
    if (vehicles_data.data.status == 200) {
      dispatch({
        type: TRANPORT_VEHICLES_LIST,
        payload: vehicles_data.data.data,
      });
    } else {
      toast.warning(vehicles_data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const GetBuyingDropdownData = () => async (dispatch) => {
  try {
    var buying_drop = await API.get('/buyingAllData');
    console.log(buying_drop, 'GetBuyingDropdownData');
    if (buying_drop.data.status == 200) {
      dispatch({
        type: BUYING_DROP_LIST,
        payload: buying_drop.data.data,
      });
    } else {
      toast.warning(buying_drop.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const YotraderBidvalue_data = () => async (dispatch) => {
  try {
    var yotrader_bid_value = await API.get('/YotraderBidvalue');

    if (yotrader_bid_value.data.status == 200) {
      return yotrader_bid_value.data.data;
    } else {
      toast.warning(yotrader_bid_value.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const Get_Buying_Pagination =
  (pageno, filter, search,sort_data_value, exportData) => async (dispatch) => {
    try {
      var buying_page = await API.post(`/buying_pagination?search=${search}&&sort_data_value=${sort_data_value}`, {
        page_no: pageno,
        filter,
        sort_data_value,
        export: exportData,
      });

      console.log(
        buying_page,
        'kkjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj'
      );
      if (buying_page.data.status == 200) {
        dispatch({
          type: BUYING_PAGINATION,
          payload: buying_page.data.data,
        });
      } else {
        toast.warning(buying_page.data.data.message);
      }
    } catch (error) {
      toast.warning(error.message);
    }
  };

export const Get_Buying_Pagination4 = () => async (dispatch) => {
  try {
    var buying_page = await API.post(`/exportcsv`);

    console.log(
      buying_page,
      'kkjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj'
    );
    if (buying_page.data.status == 200) {
      dispatch({
        //  type: BUYING_PAGINATION,
        // payload: buying_page.data.data,
      });
    } else {
      toast.warning(buying_page.data.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const hpipdfbuyingsection=()=>async(dispatch)=>{
  try {
    var hpicheck = await API.get('/hpiauthtoken');

    if (hpicheck.data.status == 200) {
      return hpicheck.data.data;
    } else {
      toast.warning(hpicheck.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
}
